import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
interface AssistanceCardProps{
    iconPath:string,
    label:string,
    className:any
}
export const AssistanceCard:React.FC<AssistanceCardProps> = ({iconPath,label,className}) => {
  return (
    <>
      <div style={{display:"flex", justifyContent:"space-between",width:"90%", marginTop:"1rem"}}>
        <div style={{display:"inline-flex",gap:"1rem"}}>
           <Icon src={`/images/${iconPath}.png`} alt={label} />     
        <Text text={label} className={className} />
        </div>       
      </div>
    </>
  );
};
