import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Address } from "../address/Address";
import style from "./selectedAddress.module.scss";

interface SelectedAddressProps {
  addressSelected: any;
  renderSelectedAddressUi: any;
  title:string;
  addressIcon:string;
  addressBadge:string;
}

export const SelectedAddressComponent: React.FC<SelectedAddressProps> = ({
  addressSelected,
  renderSelectedAddressUi,
  title,
  addressIcon,
  addressBadge
}) => {
  return (
    <>
      <BackgroundWrapper className={style.background}>
        <Container className={style.wrapper}>
          <Text text={title} className={style.title} />      
          <Address
          leftSideIcon={addressIcon}
          rightSideBadgeText={addressBadge}
            line1={addressSelected?.label ||addressSelected?.name|| ""}
            line2={addressSelected?.addressLine1 || addressSelected?.address||""}
            line3={title!=="Selected Drop Location"?`${addressSelected?.addressLine2 || ""}, ${
              addressSelected?.city || ""
            }`:""}
            line4={title!=="Selected Drop Location"?`${addressSelected?.state || ""},${
              addressSelected?.pinCode || ""
            }`:""}
          />
          <div onClick={renderSelectedAddressUi} className={style.edit_icon}>
            <Text  text="Edit" />
          </div>
        </Container>
      </BackgroundWrapper>
    </>
  );
};
