import { useSelector } from "react-redux"

export const UseGetStoreData=()=>{
    const getUserData=useSelector((state:any)=>state?.user?.userData);
    const getTicketData=useSelector((state:any)=>state?.ticket?.ticketData);
    const getTicketUpdateData=useSelector((state:any)=>state?.tickUpdate?.ticketUpdateData);
    const getBenList=useSelector((state:any)=>state?.beneficiary?.benData);
    const getTicketStatus = useSelector((state: any) => state?.ticket?.ticketData?.ticketStatus?.toLowerCase());
    const getTicketMedicalStatus=useSelector((state:any)=>state?.ticket?.ticketData?.currentMedicalStatusResponse)
    const getAmbulanceDistanceBetween = useSelector((state: any) => state?.ambulanceReqInfo?.distanceBetween);

    return{
        getUserData,
        getTicketData,
        getTicketUpdateData,
        getBenList,
        getTicketStatus,
        getAmbulanceDistanceBetween,
        getTicketMedicalStatus
    }
}