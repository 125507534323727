import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AmbulanceReqState {
    pickupLocation: any | null;
    dropLocation: any | null;
    distanceBetween: number | null;
}

const initialState: AmbulanceReqState = {
    pickupLocation: null,
    dropLocation: null,
    distanceBetween: null,
};

const ambulanceReqSlice = createSlice({
    name: 'ambulanceReq',
    initialState,
    reducers: {
        setAmbulanceReqInfo(state, action: PayloadAction<AmbulanceReqState>) {
            state.pickupLocation = action.payload.pickupLocation;
            state.dropLocation = action.payload.dropLocation;
            state.distanceBetween = action.payload.distanceBetween;
        },
        resetAmbulanceReqInfo(state) {
            state.pickupLocation = null;
            state.dropLocation = null;
            state.distanceBetween = null;
        },
    },
});

export const { setAmbulanceReqInfo,resetAmbulanceReqInfo } = ambulanceReqSlice.actions;

export default ambulanceReqSlice.reducer;
