import { Operation } from "../../../pages/operation/Operation";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { TicketBasicDetWrapper } from "../ticketBasicDetWrapper/TicketBasicDetWrapper";
import style from "../assessmentResult/assessmentResult.module.scss";
export const DemoTicket = () => {
  return (
    <>
      <TicketBasicDetWrapper>
        <Container className={style.wrapper}>
          <Text className={style.heading} text="Operation" />
        </Container>
        {/* <div className={style.border}></div> */}
        <Operation />
      </TicketBasicDetWrapper>
    </>
  );
};
