import { useGetPreferredHospital } from "../../../hooks/useGetPreferredHospital/UseGetPreferredHospitl";

import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";

import { Text } from "../../atoms/text/Text";
import { Address } from "../../molecules/address/Address";
import style from "../pickupLocation/pickupLocation.module.scss";
interface PreferredHospitalProps {
  component: any;
  nearByHospital: any;
  handleAddressSelection: any;
  pickupLocation: any;
  selectedHospital: any;
}
const PreferredHospital: React.FC<PreferredHospitalProps> = ({
  nearByHospital,
  handleAddressSelection

}) => {
  const {preSelectHospital,getDistance}=useGetPreferredHospital({preferredHospital:nearByHospital});
  // const {handleSelectHospital}=useLogisticsData();
  const handleHospitalData=()=>{
    if(preSelectHospital.length){
      handleAddressSelection(preSelectHospital[0])
    }
  }
  console.log('preSelectedHospital:', preSelectHospital);
  return (
    <>
      <div >     
        <BackgroundWrapper className={style.background}>
          <div  className={style.wrapper}>
            <Text text={"Preferred Hospital"} className={style.title} />
            <Text className={`${style.question} ${style.space} ${style.spaceB}`} 
            text={preSelectHospital[0]?.name?`We can see your preferred hospital is ${preSelectHospital[0]?.name||"Hospital N/A"} and it is km:${getDistance||"N/A"} from the incident site, you would want us to drop you there or at a nearby hospital`:"Preferred Hospital Not Found"}/>
           {preSelectHospital?.length?<div onClick={handleHospitalData} style={{border:".5px solid gray" ,borderRadius:"1rem",padding:".5rem",cursor:"pointer"}}>

            <Address
              leftSideIcon={preSelectHospital[0]?.address?"/images/home.png":""}
              rightSideBadgeText={ preSelectHospital[0]?.address?"Saved":""}
              line1={preSelectHospital[0]?.address? "Hospital":"----"}
              line2={
                nearByHospital?.addressLine1 || preSelectHospital[0]?.address || "Hospital not found"
              }
              line3={`Kilometer:${getDistance||"N/A"}`}
              line4={""}
            />
            </div>:<></>}
          </div>
        </BackgroundWrapper>
      </div>
    </>
  );
};

export default PreferredHospital;
