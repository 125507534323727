import { useDispatch } from "react-redux";
import { ShowAlert } from "../../utils/showAlert";
import { agentLoginWrap } from "../../pages/actions/actions";
import { errorFormate } from "../../utils/helper";
import { useCustomNavigate } from "../useCustomNavigate";

export const useCustomLogin=()=>{    
    const {jumpTo}=useCustomNavigate()
    const dispatch=useDispatch();
    const onFinish = async (values: any) => {
      try {
        await agentLoginWrap(values,dispatch);
        ShowAlert("success", "Logged in Successfully");
        jumpTo("/");
      } catch (e: any) {
        return ShowAlert("warning", errorFormate({e,service:"login"}));
      }
    };
  
    const onFinishFailed = (errorInfo: any) => {
      //   console.log("check-2")
      // console.log("Failed:", errorInfo);
      ShowAlert("warning", ...errorInfo?.errorFields[0]?.errors);
    };

    return {onFinish,onFinishFailed}
}