import { Button, Image, Modal, Row, Typography } from "antd";
const { Text } = Typography;
interface EmergencyTicketProps {
  isModalVisible: boolean;
  handleModalClose: any;
  closeModalRequestFromAllAgents: any;
  newEmergencyData: any;
  navigate: any;
  updateTicket: any;
}
export const EmergencyTicketModal: React.FC<EmergencyTicketProps> = ({
  isModalVisible,
  handleModalClose,
  closeModalRequestFromAllAgents,
  newEmergencyData,
  navigate,
  updateTicket,
}) => {
  console.log("child-3");
  return (
    <>
      <Modal
        title="Emergency Alert"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
          <Button
            key="handle"
            type="primary"
            onClick={async () => {
              handleModalClose();
              await closeModalRequestFromAllAgents(newEmergencyData?.tid);
              navigate(`/handleEmergency/${newEmergencyData?.tid}`);
              await updateTicket(newEmergencyData?.tid, {});
              window.location.reload();
            }}
          >
            Handle Emergency
          </Button>,
        ]}
      >
        <Row justify="center">
          <Image
            width={100}
            src="https://em-content.zobj.net/source/animated-noto-color-emoji/356/police-car-light_1f6a8.gif"
            alt="Emergency"
          />
        </Row>
        <Row justify="center">
          <Text>{newEmergencyData?.body}</Text>
        </Row>
      </Modal>
    </>
  );
};
