import { useDispatch } from "react-redux";
import { generateSaSToken, sendWhatsappMessage, updateTicket } from "../../pages/actions/actions";
import { currentDateTime, removeFieldFn } from "../../utils/helper";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useEmergencyContact } from "../useEmergencyContact/UseEmergencyContact";
import { ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";

export const useInsuranceCard=({data}:any)=>{
  const dispatch=useDispatch();
    const {getTicketUpdateData,getTicketData}=UseGetStoreData()
    const {validContacts}=useEmergencyContact()
    let excludedField = ["_id","expiry"];  
    const filteredData=removeFieldFn(data,generateSaSToken,excludedField)
    // console.log("filteredData:", filteredData);
    const handleShareInsuranceDet = async() => {
      const getPatientName=getTicketData?.patient?.fullName||getTicketUpdateData?.patient?.fullName;  
      if(!data ||!getPatientName){
        ShowAlert("warning","Insurance Details Not Found or Select Patient")
      }else if(!validContacts.length){
        ShowAlert("warning","Patient or Emergency COntact Not found")
      }
      else{
        let excludedField = ["_id","expiry","docUrl"];  
        const filteredData=removeFieldFn(data,generateSaSToken,excludedField)
        const payload={
          template: "INSURANCE_INFO",
          data:{
            beneficiary:getPatientName||"",
            insuranceDetails:JSON.stringify(filteredData),
            to:[...validContacts]
          }
        }
        const insuranceSharedPayload = {
          notes: [...getTicketData?.notes, {
            date: currentDateTime(),
            message: "Insurance details shared to patient"
          }]
        };
    
        try{  
         await sendWhatsappMessage(payload);
         const res=await updateTicket(getTicketData._id,insuranceSharedPayload);
         if(res){
           dispatch(ticketUpdateStatusSuccess(res.data));
           dispatch(ticketDataSuccess(res.data));
           ShowAlert("success","Insurance details send to patient")
         }else{
           ShowAlert("warning","some issue while updating ticket")
         }
        }catch(e:any){
          return ShowAlert("warning",e.response?.data?.message || "some issue while sending Whatsapp Message")
        }
      }
  
    };
    return{
        handleShareInsuranceDet ,
        filteredData
    }
}