import moment from "moment-timezone";

export const navigationData = [
  {
    label: "Tickets",
    path: "/",
    src: "/images/ticket.png",
  },
  // {
  //   label: "Outbound Calls",
  //   path: "/outboundCall",
  //   src: "/images/call.png",
  // },
  // {
  //   label: "Reset Password",
  //   path: "/resetPswd",
  //   src: "/images/lock-reset.png",
  // },
  {
    label: "Logout",
    path: "logout",
    src: "/images/logout.png",
  },
];

export const TicketStatus = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Work In Progress",
    value: "Work in progress",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "TestDemo",
    value: "TestDemo",
  },
  {
    label: "Customer Test",
    value: "Customer Test",
  },
  {
    label: "Internal Test",
    value: "Internal Test",
  },
  {
    label: "GeneralEnquiry",
    value: "GeneralEnquiry",
  },

];

export const CompanyList = [
  {
    label: "Pococare",
    value: "Pococare",
  },
  {
    label: "Persistance",
    value: "Persistance",
  },
  {
    label: "Bioseed",
    value: "Bioseed",
  },
];
export const options = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const typesOfAssistanceReq = [
  {
    value: "wheelChair",
    label: "Wheelchair",
    icon: "/images/wheelchair.png",
  },
  {
    value: "stretcher",
    label: "Stretcher",
    icon: "/images/stretcher.png",
  },
  {
    value: "paramedic",
    label: "Paramedic",
    icon: "/images/paramedic.png",
  },
  {
    value: "additionalHelp",
    label: "Help",
    icon: "/images/additionalHelp.png",
  },
];

export const transFormAssistanceValue: any = {
  wheelChair: "wheelchair",
  stretcher: "stretcher",
  paramedic: "paramedic",
  additionalHelp: "additionalHelp"
}

export const questions: any = [
  { text: "Do you need Ambulance?", name: "isAmbulanceRequired" },
  {
    text: "Do you need doctor consultation?",
    name: "isDoctorConsultationRequired",
  },
  { text: "Is the patient conscious?", name: "isPatientConscious" },
];

export const AgentData = [
  {
    label: "agent1",
    value: "agent1",
  },
  {
    label: "agent2",
    value: "agent2",
  },
];
export const patientConditions: { label: string; value: string }[] = [
  {
    label: "Accident",
    value: "Accident",
  },
  {
    label: "Bleeding",
    value: "Bleeding",
  },
  {
    label: "Breathing Difficulty",
    value: "Breathing Difficulty",
  },
  {
    label: "Burn",
    value: "Burn",
  },
  {
    label: "Severe Pain",
    value: "Severe Pain",
  },
  {
    label: "Other",
    value: "Other",
  },
];



export const columns = [
  { title: "TICKET ID", dataIndex: "_id", key: "_id" },
  {
    title: "AGENT ID",
    key: "agent.fullName",
    render: (text: any, record: any) => record?.agent?.fullName,
  },
  {
    title: "DATE",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (text: any) => new Date(text).toLocaleString(),
  },
  { title: "STATUS", dataIndex: "ticketStatus", key: "ticketStatus" },
];
export const ticketDataTransform: any = {
  callerID: "Call from Phone",
  callerType: "Caller Type",
  sourceOfTicket: "Source of Call",
  companyName: "Client Of",
  campusId: "CampusId",
  poc: "POC",
  detectedLocation: "Address",
  subscriber: "Subscriber"
};
export const errorFormate = ({ e, service }: any) => {
  return e?.response?.data?.message || `Some Technical Issue in ${service}`;
};

export const payloadBuilderForObject = ({ fields, obj }: any) => {
  return fields.reduce((tempObj: any, key: any) => {
    if (key in obj) {
      tempObj[key] = obj[key];
    }
    return tempObj;
  }, {});
};

export const objectToArray = ({ fields, obj }: any) => {
  // console.log("obj:", fields, obj);
  return fields.reduce((temArr: any, key: any) => {
    if (obj[key] !== undefined) {
      // Ensure the field exists in the object
      if (key === "detectedLocation") {
        let nestedField: any = obj["detectedLocation"];
        let label = ticketDataTransform[key];
        let addressLine = `${nestedField["address"] || ""},${nestedField["city"] || ""
          },${nestedField["state"] || ""},${nestedField["pinCode"] || ""},${nestedField["country"] || ""
          }`;
        temArr.push({ label, value: addressLine });
      } else if (key === "poc") {
        let label = ticketDataTransform[key];
        let nestedField = obj["poc"];
        temArr.push({
          label,
          value: nestedField["mobile"],
          name: nestedField["fullName"],
        });
      } else if (key === "subscriber") {
        // let label = ticketDataTransform[key];
        let key1 = "SubscriberName";
        let key2 = "SubscriberMobile";
        let nestedField = obj["subscriber"];
        temArr.push({
          label: key2,
          value: nestedField["mobile"],

        });
        temArr.push({
          label: key1,
          value: nestedField["fullName"],
        });
      } else {
        let label = ticketDataTransform[key]; // Use key as fallback
        temArr.push({ label, value: obj[key] });
      }
    } else {
      if (key === "poc") {
        let label = ticketDataTransform[key];
        temArr.push({ label, value: obj[key] || "Poc N/A", name: "Poc N/A" });
      } else if (key === "subscriber") {
        let label = ticketDataTransform[key];
        temArr.push({ label, value: obj[key] || "N/A", name: "N/A" });
      }
      else {
        let label = ticketDataTransform[key];
        temArr.push({ label, value: obj[key] || "N/A" });
      }
    }
    return temArr; // Return the accumulator
  }, []);
};
///emergencyCheck///
export const EmCheckOptions = [
  { value: "yes", label: "Yes" },
  { value: "maybe", label: "Maybe" },
  { value: "no", label: "No" },
];
export const EmMessages: any = {
  yes: "Please don't panic, we are here to help. We can arrange a doctor's consultation and book an ambulance. But to do that, we would need some details. Ok?",
  no: "Relieved and happy to know that it is not a medical emergency, is there anything I could assist you with ?",
  maybe:
    "We will assume it's an emergency, capture necessary details, and arrange for a doctor's consultation. Post that, you can decide whether you need an ambulance or not. ok?",
};
export function createWhatsappMessagePayload({
  templateName,
  data,
}: {
  templateName: string;
  data: any;
}) {
  return {
    template: templateName,
    data: {
      ...data,
    },
  };
}
export const insuranceFieldTransformer: any = {
  policyId: "Policyid",
  docUrl: "Document",
  nameOfInsurer: "Nameofinsurer",
  expiry: "Expiry",
  tpaName: "TPA Name",
  typeOfPolicy: "Type of Policy",
  callerId: "Callerid",
  duration: "Duration",
  startTime: "StartTime",
  endTime: "EndTime",
  timeToAnswer: "TimeToAnswer",
  status: "Status",
  hangupBy: "HangupBy",
  agentId: "Agentid",
  dialStatus: "DialStatus"



}
export const benDataTransformer = (benData: any) => {
  return benData
    .map((ben: any) => ({
      value: ben.subscriberId + "," + ben._id,
      label: ben?.personalInfo?.fullName,
    }))
    .filter((el: any) => el.value !== undefined || el.label !== undefined);
};
export function generateUniqueID() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  const randomChars = Math.random().toString(16).substring(2, 8);
  const uniqueID = timestamp + randomChars;
  return uniqueID;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function transformLowerCase(string: string) {
  // eslint-disable-next-line
  return string?.replace(/\p{Z}/gu, "")
    .toLowerCase();

};

export const removeFieldFn = (data: any, generateSaSToken: any, excludedField: any) => {

  const filteredData: any = data.map((benData: any) => {
    generateSaSToken()
    return Object.fromEntries(
      Object.entries(benData).filter(
        ([key, value]) => !excludedField.includes(key)
      )
    );
  })
  return filteredData
}
export const currentDateTime = () => {
  return moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
};

export const agentNotesUpdateFun=(isTicketDataPresent:any,message:any)=>{ 
let messageArr=[]
  for(let i=0; i<message.length; i++){
    messageArr.push({ date: currentDateTime(),message:message[i]})
  }
  const TicketUpdateTracking = {
    notes: [...isTicketDataPresent?.notes,...messageArr ]
  };
  return TicketUpdateTracking;
}

export const EmStartNotification =
  "We will immediately dispatch an ambulance and set up a doctor’s consultation. Please stay available on Whatsapp for an online video consultation with the doctor. Thank you for reaching out to the Pococare Heartbeat team.";

export const cancelledTicketText =
  "I am sorry, but Pococare services are for medical emergencies only. We provide ambulance and virtual doctor consultation for such cases. If you do not require any of the above service I will close this ticket";


