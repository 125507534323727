import { useState } from "react";
import { useDispatch } from "react-redux";
import { ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";
import { message } from "antd";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { updateTicket } from "../../pages/actions/actions";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { ShowAlert } from "../../utils/showAlert";

export const usePocForm=(handleEditPoc?:any)=>{
    
    const dispatch = useDispatch();
    const {getTicketData,getTicketUpdateData}=UseGetStoreData();
    const [pocName, setPocName] = useState("");
    const [pocNumber, setPocNumber] = useState("");
    const [isModalOpen,setIsModalOpen]=useState(true);
  
    const handlePocInputChange = (e: any) => {
      const { name, value } = e.target;
      if (name === "fullName") {
        setPocName(value);
        if(handleEditPoc){
          let payload = {
            poc: { fullName: value,
              mobile:pocNumber
             },
          };
          dispatch(ticketUpdateStatusSuccess(payload));
        }
      } else if (name === "mobile") {
        const digitsOnly = value.replace(/\D/g, "");
        const firstTenDigits = digitsOnly.substring(0, 10);
        setPocNumber(firstTenDigits);
        if(handleEditPoc){
          let payload = {
            poc: {  fullName: pocName,mobile: firstTenDigits },
          };
          dispatch(ticketUpdateStatusSuccess(payload));
        }
      }
    };

    console.log('getTicketUpdateData:', getTicketUpdateData);
    const onFinish = () => {
      message.success("Form submitted successfully!");
    };
  
    const onFinishFailed = () => {
      message.error("Please check the form for errors.");
    };
  
    const validateMessages = {
      //eslint-disable-next-line
      required: "${label} is required!",
      types: {
              //eslint-disable-next-line
        number: "${label} is not a valid number!",
      },
      pattern: {
              //eslint-disable-next-line
        mismatch: "${label} is not a valid phone number!",
      },
    };
    const handUpdateTicket=async()=>{
      if(!pocName ||!pocNumber) return ShowAlert("warning","Please Poc Name and Mobile")
      const payload={
        poc:{
          fullName:pocName,
          mobile:pocNumber
        }
      }
      const res=await updateTicket(getTicketData._id,payload);
      if(res){
        dispatch(ticketDataSuccess(res.data))
      }
      ShowAlert("success","Poc Details Updated Successfully")
      handleModalClose()
    }
    const handleModalClose=()=>{
      setIsModalOpen(false);
      handleEditPoc();
  }

    return{
        onFinish,
        onFinishFailed,
        validateMessages,
        handlePocInputChange,
        pocName,pocNumber,
        handUpdateTicket,
        handleModalClose,
        isModalOpen
    }
}