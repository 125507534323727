import { options, questions } from "../../../utils/helper";
import { MedicalCondList } from "../../molecules/medicalCondList/MedicalCondList";
import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import style from "./medicalCondition.module.scss";
import { Text } from "../../atoms/text/Text";
import { useMedicalCondition } from "../../../hooks/useMedicalCondtion/UseMedicalCondition";

export const MedicalCondition = () => {
  const { responses, handleOptionChange } = useMedicalCondition();
  return (
    <>
      <BackgroundWrapper className={style.wrapper}>
        <Text text="Medical Condition" className={style.title} />
        {questions.map((q: any) => (
          <MedicalCondList
            className={style.question}
            key={q.name}
            question={q.text}
            options={options}
            name={q.name}
            value={responses[q.name]}
            onChange={handleOptionChange}
            disable={
              q.name === "isDoctorConsultationRequired"
                ? responses.isAmbulanceRequired === "Yes"
                : false
            }
          />
        ))}
      </BackgroundWrapper>
    </>
  );
};
