import { Container } from "../../atoms/container/Container";
import { TopCard } from "../../molecules/topCard/TopCard";
import { TicketBasicDetails } from "../ticketBasicDetails/TicketBasicDetails";
import style from "./ticketdetails.module.scss";
import { UseTicketBasicDetails } from "../../../hooks/useTicketBasicDetails/UseTicketBasicDetails";
import { UseGetStoreData } from "../../../hooks/getStoreData/UseGetStoreData";
import { POCEditForm } from "../pocUpdateForm/PocEditForm";
interface TicketBasicDetWrapperProps {
  children: any;
}
export const TicketBasicDetWrapper: React.FC<TicketBasicDetWrapperProps> = ({
  children,
}) => {
  const {
    handleClick,
    transformedDataLeftSide,
    transformedDataRightSide,
    handleEditPoc,
    showPocUpdateModal,
  }: any = UseTicketBasicDetails();
  const { getTicketData } = UseGetStoreData();

  return (
    <>
      <div>
        <TopCard
          title={`Ticket Number: ${getTicketData?._id}`}
          icon="/images/Group 34288.png"
          onClick={handleClick}
        />
      </div>
      <Container className={style.ticketDet__container}>
        <TicketBasicDetails
          handleEditPoc={handleEditPoc}
          title={"Call Details"}
          leftSideListData={transformedDataLeftSide}
          rightSideListData={transformedDataRightSide}
        />
      </Container>
      {showPocUpdateModal && <><POCEditForm handleEditPoc={handleEditPoc}/></>}
      {children}
    </>
  );
};
