import { useState, useMemo } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useLocation } from "react-router-dom";
import { transFormAssistanceValue } from "../../utils/helper";

export const useAssessmentRes = () => {
  const location:any=useLocation();
  // console.log('location:', );
  const { getTicketData } = UseGetStoreData();
  const [isOpenAssessmentResult, setIsOpenAssessmentResult] = useState(location.pathname.startsWith("/cancelled-ticket")?true:false);

  const handleAssessmentResult = () => {
    setIsOpenAssessmentResult(!isOpenAssessmentResult);
  };

  const transformedData = useMemo(() => {
    if (!getTicketData) return null;

    const { poc, patient, ticketRaisedBy, currentMedicalStatusResponse, typeOfAssistanceRequired } = getTicketData;

    const ticketData = [
      { label: "Point Of Contact", value: poc?.fullName || "N/A" },
      { label: "Patient’s Contact No", value: patient?.mobile || "N/A" },
      { label: "Ben Contact No", value: ticketRaisedBy?.mobile || "N/A" },
    ];

    const ticketData2 = [
        { label: "Medical Emergency ", value:currentMedicalStatusResponse?.isItEmergency || false },
        { label: "Patient’s Name", value: patient?.fullName||"N/A"},
        { label: "Beneficiary Name", value: ticketRaisedBy?.fullName||"N/A" },
      ];
    const medicalData = {
      "Doctor consultation": currentMedicalStatusResponse?.isDoctorConsultationRequired || false,
      "Ambulance": currentMedicalStatusResponse?.isAmbulanceRequired || false,
     " Patient conscious": currentMedicalStatusResponse?.isPatientConscious || false,
    };
    const currentCondition = currentMedicalStatusResponse?.currentCondition||"N/A";
    const others=currentMedicalStatusResponse?.otherDescription

    const selectedAssistance = Object?.entries(typeOfAssistanceRequired||{})
      .filter(([_, value]:any) => value)
      .map(([key]) => transFormAssistanceValue[key]);

    return { ticketData,others, medicalData, currentCondition, selectedAssistance,ticketData2 };
  }, [getTicketData]);

  return {
    isOpenAssessmentResult,
    handleAssessmentResult,
    transformedData,
  };
};
