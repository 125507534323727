import { DatePicker,  TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import styled from 'styled-components';
import moment from 'moment';

interface DatePickerComponentProps {
  handleGetDate: any;
  height:any
  placeholderFontSize?:any
  ref?:any
  
}

interface StyledRangePickerProps {
  placeholderFontSize?: string;
  height?: string;
}
const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)<StyledRangePickerProps>`
  .ant-picker-input > input::placeholder {
    font-size: ${(props) => props.placeholderFontSize || '18px'};
      color:red !important
      border:1px solid red !important;
  }

  .ant-picker-input > input {
    height: ${(props) => props.height || '45px'};
  }
 @media screen and (max-width: 768px) {
    .ant-picker-input > input::placeholder {
      font-size: ${(props) => props.placeholderFontSize || '14px'};
    }   
    .ant-picker-input > input {
      height: 35px !important;
    }
  }
   @media screen and (max-width: 550px) {
    .ant-picker-input > input::placeholder {
      font-size: 12px !important;
    
    }   
    .ant-picker-input > input {
      height: 25px !important;
    }
  }
  
 
`;
export const DatePickerComponent:React.FC<DatePickerComponentProps> = ({handleGetDate,height}) => {

  // const btnRef=useRef<any>(null)//for clearing filter manually
        const onRangeChange = (
        dates: null | (Dayjs | null)[],
        dateStrings: string[]
      ) => {
        if (dates) {
          const payload = {
            from:moment(dateStrings[0]).format("DD-MMM-YYYY"),
            to: moment(dateStrings[1]).format("DD-MMM-YYYY"),
          }
          handleGetDate(payload);
        } else {
          // console.log('Clear');
        }
      };
      // console.log("child-date");
      const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Last 7 Days', value: [dayjs().add(+7, 'd'), dayjs()] },
        { label: 'Last 14 Days', value: [dayjs().add(+14, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(+30, 'd'), dayjs()] },
        { label: 'Last 90 Days', value: [dayjs().add(+90, 'd'), dayjs()] },
      ];
    return <div  >
    <StyledRangePicker onChange={onRangeChange} >
      <RangePicker  presets={rangePresets}  />
      
    </StyledRangePicker>
    {/* <button onClick={handleClear}>dfdf</button> */}
  </div>
    ;
};