import { Form, Input, Row, Col } from "antd";
import React from "react";
interface POCFormProps {
  onFinish: any;
  onFinishFailed: any;
  validateMessages: any;
  pocName: any;
  pocNumber: any;
  handlePocInputChange: any;
}
const POCForm: React.FC<POCFormProps> = ({
  onFinish,
  onFinishFailed,
  validateMessages,
  pocName,
  pocNumber,
  handlePocInputChange,
}) => {

  return (
    <Form
      name="pocForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      <Row gutter={16} style={{ marginTop: "1rem" }}>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label="POC Name"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="POC Name"
              name="fullName"
              value={pocName}
              onChange={handlePocInputChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="mobile"
            label="POC Mobile"
            rules={[
              { required: true },
              {
                pattern: /^[0-9]{10}$/,
                message: "POC Mobile must be a valid 10-digit number",
              },
              
            ]}
          >
            <Input
              maxLength={10}
              placeholder="POC Mobile"
              name="mobile"
              value={pocNumber}            
              type="tel"
              onChange={handlePocInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
   
  );
};

export default POCForm;
