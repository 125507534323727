import { Container } from "../../components/atoms/container/Container";
import { Text } from "../../components/atoms/text/Text";
import { AssessmentResult } from "../../components/organisms/assessmentResult/AssessmentResult";
import { TicketBasicDetWrapper } from "../../components/organisms/ticketBasicDetWrapper/TicketBasicDetWrapper";

export const TicketCancel = () => {
  return (
    <>
      <TicketBasicDetWrapper>
        <Container>
          <AssessmentResult />
        </Container>
        <div style={{fontSize:"1rem", width:"90%", fontWeight:"600", padding:"1rem",color:"white",backgroundColor:"red", borderRadius:"1rem",display:"flex",justifyContent:"center",margin:"auto",marginTop:"1rem"}}>
          <Text text="Ticket Cancelled" />
        </div>
      </TicketBasicDetWrapper>
    </>
  );
};
