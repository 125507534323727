import { findAmbulanceProviders, findAmbulanceRatecard, sendPaymentViaLink, findDoctorRatecard } from "../../pages/actions/actions";
// import { useSseTrigger } from "../UseSseTrigger/UseSseTrigger";
import { useEffect, useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useModal } from "../../components/organisms/modalProvider/ModalProvider";
import { ShowAlert } from "../../utils/showAlert";
import { updateTicket } from "../../pages/actions/actions";
import { currentDateTime } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

interface Doctor {
    _id: string;
    price: number;
    doctorType: string
}

export const usePaymentLink = ({ component }: any) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [amount, setAmount] = useState<number | null>(null);
    const [number, setNumber] = useState<any>(null)
    const [email, setEmail] = useState(null)
    const { getTicketData, getAmbulanceDistanceBetween } = UseGetStoreData();
    const [selectedDetails, setSelectedDetails] = useState<any>([]);
    // const { paymentBtnStatus_Ambulance,paymentBtnStatus_Doctor } = usePaymentStatus()
    const [isLoading, setIsLoading] = useState(false);
    const { hideModal } = useModal();
    const [ambulanceProvidersList, setAmbulanceProvidersList] = useState([]);
    const [ambulanceProviderId, setAmbulanceProviderId] = useState(null);
    const [ambulanceProviderRateCards, setAmbulanceProviderRateCards] = useState([]);
    const [ambulanceType, setAmbulanceType] = useState<null | string>(null)
    const [typeOfDoctorList, settypeOfDoctorList] = useState<Doctor | any>([])
    const [doctorId, setdoctorId] = useState<string>("")
    const [doctorPayment, setDoctorPayment] = useState(0)
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        const fetchAmbulanceProvidersList = async () => {
            const ambulanceProviders = await findAmbulanceProviders();
            // console.log('ambulanceProviders:', ambulanceProviders);
            setAmbulanceProvidersList(ambulanceProviders);
        }

        const fetchDoctorList = async () => {
            const doctorList = await findDoctorRatecard()

            settypeOfDoctorList(doctorList.data)
            setdoctorId(doctorList.data[0]?.doctorType || "No Doctor available")
            setDoctorPayment(doctorList.data[0]?.price || 0)
        }
        fetchDoctorList()
        fetchAmbulanceProvidersList();
    }, [])

    useEffect(() => {
        // Fetching ambulance rate card information and setting the amount with ambulance charges.
        const fetchRateCards = async () => {
            try {
                if (ambulanceProviderId) {
                    // Fetching ambulance rate card information 
                    const response = await findAmbulanceRatecard({
                        ambulanceProviderId: ambulanceProviderId,
                    });
                    // console.log('findAmbulanceRatecard response:', response);

                    // Setting the ambulanceProviderRateCard
                    setAmbulanceProviderRateCards(response.data);

                    if (response?.data?.length <= 0) {
                        throw new Error("No Ambulance Rate Card found");
                    }
                }
                else {
                    setAmount(null);
                    setAmbulanceType(null);
                }
            }
            catch (error: any) {
                throw new Error("Error in in finding rate card: " + error.message);
            }
        }
        fetchRateCards();
    }, [ambulanceProviderId])

    useEffect(() => {
        const calculateAmount = () => {
            try {
                if (ambulanceProviderRateCards && ambulanceType) {
                    const ambulanceRateCard: any = ambulanceProviderRateCards.find(
                        (rateCard: any) => rateCard.ambulanceType === ambulanceType.split('-')[0] && rateCard.vehicleType === ambulanceType.split('-')[1]
                    )

                    // Distance between pick and drop location
                    const distanceBetween = Math.round(parseFloat(getAmbulanceDistanceBetween));
                    // console.log('distanceBetween:', distanceBetween);

                    // Finding the rate card that matches the distance range.
                    const rateCard = ambulanceRateCard.rateCards.find((rateCard: any) => {
                        const { rateType, distanceRange } = rateCard;

                        if (rateType === 'flat') {
                            const [lowerRange, upperRange] = distanceRange.split('-').map(parseFloat);
                            return distanceBetween >= lowerRange && distanceBetween <= upperRange;
                        }

                        if (rateType === 'per km') {
                            if (distanceRange.includes('Above')) {
                                const upperRange = parseFloat(distanceRange.split(' ')[1]);
                                return distanceBetween > upperRange;
                            } else {
                                const [lowerRange, upperRange] = distanceRange.split('-').map(parseFloat);
                                return distanceBetween >= lowerRange && distanceBetween <= upperRange;
                            }
                        }

                        return false;
                    });

                    // console.log(rateCard)

                    if (!rateCard) {
                        alert('Rate card not found for selected ambulance provider or ambulance type.');
                    }

                    if (rateCard.rateType === 'flat') {
                        setAmount(rateCard.prices);
                    }
                    else if (rateCard.rateType === 'per km') {
                        setAmount(rateCard.prices * distanceBetween);
                    }
                }
                else {
                    setAmount(null);
                }
            }
            catch (error: any) {
                throw new Error("Error in in finding rate card: " + error.message);
            }
        }

        calculateAmount();
    }, [ambulanceType, ambulanceProviderRateCards, getAmbulanceDistanceBetween])

    function buildPayload() {
        let benList = [];
        if (getTicketData?.patient) {
            benList.push({
                id: "patient",
                name: getTicketData?.patient?.fullName,
                mobile: getTicketData?.patient?.mobile,
                email: getTicketData?.patient?.email
            });
            if (getTicketData?.patient?.emergencyContacts) {
                getTicketData?.patient?.emergencyContacts.forEach((item: any, index: number) => {
                    benList.push({
                        id: `em-${index + 1}`,
                        name: item.fullName,
                        mobile: item.mobile
                    })
                })
            };
        } if (getTicketData?.poc) {
            benList.push({
                id: "poc",
                name: getTicketData?.poc?.fullName,
                mobile: getTicketData?.poc?.mobile
            })
        };
        return benList;
    };
    const benList = buildPayload();
    const handleCatchAmount = (e: any) => {
        let value = e.target.value;
        let formateOnlyDigits = value.replace(/\D/g, '');
        // console.log('formateOnlyDigits:', formateOnlyDigits);
        if (formateOnlyDigits && formateOnlyDigits <= 0) {
            ShowAlert("warning", "Please enter valid amount");
        }
        setAmount(formateOnlyDigits);
    };
    const handleCollectMobileNumber = (e: any) => {
        let value = e.target.value;
        let formateOnlyDigits = value.replace(/\D/g, '');
        // console.log('formateOnlyDigits:', formateOnlyDigits);
        if (formateOnlyDigits && formateOnlyDigits <= 0) {
            ShowAlert("warning", "Please enter valid amount");
        }
        setNumber(formateOnlyDigits);
    };
    const handleCollectEmail = (e: any) => {
        const value = e.target.value;
        setEmail(value);
        if (validateEmail(value)) {
            setError('');
        } else {
            setError('Invalid email format');
        };
    };
    const validateEmail = (email: any) => {
        // Simple regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    ///
    const buildPayloadForPaymentLink = async () => {
        setIsLoading(true);
        // console.log('getTicketData:', getTicketData);
        const min = 1000000; // Starting range (e.g., 1 million)
        const max = 20000000;
        let basePayload: any = {
            amount: amount ? +amount : doctorPayment,
            payment_status: "created",
            currency: "INR",
            notes: {
                order_by: component
            },
            order_id: Math.floor(Math.random() * (max - min + 1)) + min.toString(),
            description: component,
            callback_url: "https://pococare.com",//in backend i changed these url based on the env//
            callback_method: "get",
            receipt: component,
            ticket_id: getTicketData?._id?.toString(),
            beneficiary: {
            },
            otherDetails: {
                subscriberId: getTicketData?.subscriber?.id
            },
        }
        if (selectedDetails.length) {
            selectedDetails.forEach((item: any, index: number) => {
                if (item.email) {
                    basePayload.beneficiary = {
                        name: item.name,
                        mobile: item.mobile,
                        email: item.email
                    };
                } else {
                    basePayload.beneficiary = {
                        name: item.name,
                        mobile: item.mobile
                    };
                }
            })
        } else {
            if (email) {
                basePayload.beneficiary = {
                    name: getTicketData?.patient?.fullName,
                    mobile: number,
                    email: email
                };
            } else {
                basePayload.beneficiary = {
                    name: getTicketData?.patient?.fullName,
                    mobile: number
                };
            }

            // Adding few additional details if payment is for ambulance
            if (component === 'Ambulance') {
                basePayload.otherDetails = {
                    ...basePayload.otherDetails,
                    ambulanceProviderId: ambulanceProviderId,
                    ambulanceType: ambulanceType,
                    pickupLocation: getTicketData?.patientLocation?.address,
                    dropLocation: getTicketData?.hospitalInfo?.address,
                    distanceBetween: getAmbulanceDistanceBetween
                }
            }

            // Adding few additional details if payment is for doctor-consultation
            if (component === 'doctor-consultation') {
                basePayload.otherDetails = {
                    ...basePayload.otherDetails,
                    doctorId: doctorId,
                }
            }
        }
        try {

            await sendPaymentViaLink(basePayload);
            const payload:any = {

                notes: [...getTicketData?.notes, {
                    date: currentDateTime(),
                    message: `PaymentLink Send for ${component}`
                }]
            };
            if (component === 'Ambulance') {
                payload["ambulancePayment"] = { status: "created", amount: basePayload.amount }
            }
            console.log('payload:---for paymentSend', payload);
            await updateTicket(getTicketData._id, payload, dispatch);
            await queryClient.invalidateQueries(
                {
                    queryKey: ['getOrdersByTicketId', getTicketData._id],
                    exact: true,
                    refetchType: 'all',
                },
            );
            hideModal();
            setEmail(null);
            setNumber(null);
            setAmount(null);
            setAmbulanceType(null);
            setAmbulanceProviderId(null);
            setAmbulanceProviderRateCards([])

            setIsLoading(false);
            setSelectedDetails([]);
        } catch (e: any) {
            setEmail(null);
            setNumber(null);
            setAmount(null);
            setAmbulanceType(null);
            setAmbulanceProviderId(null);
            setAmbulanceProviderRateCards([])

            setSelectedDetails([]);
            console.log('error:', e);
            setIsLoading(false);
            // console.log("e?.response?.data?.message[0]", e?.response?.data?.message[0])
            // console.log("e?.message", e?.message)
            return ShowAlert("warning", e?.response?.data?.message[0] || e?.message || "some error in sending payment link");
        };
    };
    const handleSendPaymentLink = async () => {
        if (!doctorPayment)  {
            return ShowAlert("warning", "Please enter valid amount");
        }
         else if (!Boolean(Number(amount)) ) {
            if(ambulanceProviderId){
                return ShowAlert("warning", "Please enter valid amount");
            }
        }
        else if (!selectedDetails.length && !number && !email) {
            return ShowAlert("warning", "Please enter valid email or mobile or select beneficiary");
        } else if (selectedDetails.length && (number || email)) {
            setEmail(null)
            setNumber(null)
            return ShowAlert("warning", "either (email , mobile) or select beneficiary");
        } else if (isLoading) {
            return ShowAlert("warning", "Please wait Progress..");
        }
       await buildPayloadForPaymentLink();
    };
    const handleSelectChange = (value: any) => {
        const selectedItems = benList.filter((item) => value.includes(item.id));
        console.log('selectedItems:', selectedItems);
        if (selectedItems.length > 0) {
            setSelectedDetails(selectedItems);
        } else {
            setSelectedDetails([])
        }
    };

    const handleProviderChange = (value: any) => {
        if (value) {
            setAmbulanceProviderId(value.toString());
        }
        else {
            setAmbulanceProviderId(null);
        }
    }

    const handleAmbulanceTypeChange = (value: any) => {
        if (value) {
            setAmbulanceType(value.toString());
        }
        else {
            setAmbulanceType(null);
        }
    }

    // handling amount editing
    const handleAmountEditing = () => {
        if ((amount && ambulanceProviderId && ambulanceType)) {
            setIsEditable(true);
        }
        else {
            alert("Please Select Ambulance Provider And Ambulance Type.");
        }
    }

    const handleAmountBlur = () => {
        setIsEditable(false);
    }
    const handleAmountEditingForDoctor = () => {
        setIsEditable(true)


    }
    const handleCatchAmountForDoctor = (e: any) => {

        let value = e.target.value;
        let formateOnlyDigits = value.replace(/\D/g, '');
        // console.log('formateOnlyDigits:', formateOnlyDigits);
        if (formateOnlyDigits && formateOnlyDigits <= 0) {
            ShowAlert("warning", "Please enter valid amount");
        }
        setAmount(formateOnlyDigits);


        setDoctorPayment(value)


    }


    useEffect(() => {
        //when ever closing the payment modal it will clear the all the filled values////
        setSelectedDetails([]);
        setEmail(null);
        setNumber(null);
        setAmount(null);

        setAmbulanceProviderId(null)
        setAmbulanceType(null)
        if (typeOfDoctorList && typeOfDoctorList.length > 0) {
            const selectedDoctor = typeOfDoctorList.find((doctor: Doctor) => doctor.doctorType === doctorId);
            if (selectedDoctor) {
                setDoctorPayment(selectedDoctor.price);
            }
        }

    }, [hideModal, typeOfDoctorList, doctorId])
    return {
        amount, error, number, handleSendPaymentLink, email, handleCollectMobileNumber, handleCatchAmount, handleCollectEmail, handleSelectChange, benList, isLoading, selectedDetails,
        ambulanceProvidersList, handleProviderChange, handleAmbulanceTypeChange, ambulanceType, ambulanceProviderId, ambulanceProviderRateCards, handleAmountBlur, isEditable, handleAmountEditing, typeOfDoctorList, doctorId, doctorPayment, handleAmountEditingForDoctor, handleCatchAmountForDoctor

    }
}