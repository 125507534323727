import { useState } from "react";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import style from "./navbar.module.scss";
import { FaBars } from "react-icons/fa";
import { ListItems } from "../../molecules/listItem/ListItem";
import { navigationData } from "../../../utils/helper";
import { GrClose } from "react-icons/gr";
import { UseGetStoreData } from "../../../hooks/getStoreData/UseGetStoreData";
import { useLocation, useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate=useNavigate();
  const params = useLocation();
  const isLoginPath = params.pathname === "/login";
  // const isTicket = params.pathname === "/";
  const { getUserData } = UseGetStoreData();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <Container className={style.wrapper}>
        <div className={style.nav_left}>
          <div onClick={()=>navigate("/")} className={style.logo}>
            <Icon src="/images/pocoLogo.png" alt="PocoLogo" />
          </div>
        </div>
       {!isLoginPath&& <div className={style.nav_right}>
          <div className={style.user_profile}>
            <Icon src="/images/user_icon.png" alt="PocoLogo" />
          </div>
          {getUserData && (
            <div>
              <Text text={getUserData?.name} className={style.agent_name} />
              <Text text="Pococare Heartbeat" className={style.role} />
            </div>
          )}
        </div>}
       {!isLoginPath&& <div className={style.hamburger_icon} onClick={toggleMenu}>
          {showMenu ? <GrClose /> : <FaBars />}
        </div>}
      </Container>
      {showMenu && (
        <div className={style.menu}>
          <ListItems closeModal={toggleMenu} className={style.list} data={navigationData} />
        </div>
      )}
    </>
  );
};
