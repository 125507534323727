import { skipToken, useQuery } from "@tanstack/react-query";
import {  useEffect,  useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useNavigate } from "react-router-dom";
import { ShowAlert } from "../../utils/showAlert";
import { errorFormate } from "../../utils/helper";
import { ordersByTicketId, sendAgainPaymentLink } from "../../pages/actions/actions";
// import { useSseTrigger } from "../UseSseTrigger/UseSseTrigger";

export const usePaymentStatus = () => {
  
    const navigate = useNavigate();
    const [paymentBtnStatus_Ambulance, setPaymentBtnStatus_Ambulance] = useState<any>({
        isCreated: false,
        isFailed: false,
        isPaid: false,
        isExpired: false,
        isCanceled: false,
        data: null,
    });
    const [paymentBtnStatus_Doctor, setPaymentBtnStatus_Doctor] = useState<any>({
        isCreated: false,
        isFailed: false,
        isPaid: false,
        isExpired: false,
        isCanceled: false,
        data: null
    });
    const { getTicketData } = UseGetStoreData();
    const fetchOrders = async (ticketId: string) => {
        try {
            const res = await ordersByTicketId(ticketId)
            return res;
        } catch (e: any) {
            if (e.response.statusCode === 404) {
                return
            } else {
                ShowAlert("warning", errorFormate({ e, service: "getPaymentOrders" }));
            }
        };
    }
    const jumpWithNotification = () => {
        ShowAlert("warning", "Some Technical Issue In retrieving Data");
        navigate("/")
    }
    const shouldFetch = getTicketData?._id ? true : jumpWithNotification();
    const { data: getOrderList } = useQuery({
        queryKey: ["getOrdersByTicketId", getTicketData?._id],
        queryFn: shouldFetch ? () => fetchOrders(getTicketData?._id) : skipToken,
        
    });
    const checkOrderStatus = (payment: any) => {
        const hasDisableBtn = payment.payment_status !== "paid" && payment.payment_status !== "expired" && payment.payment_status !== "cancelled" && payment.payment_status !== "partially_paid" && payment.payment_status !== "failed";
        const hasPaid = payment.payment_status === "paid"
        const hasExpired = payment.payment_status === "expired"
        const hasCancelled = payment.payment_status === "cancelled"
        const hasFailed = payment.payment_status === "failed"
        return { hasDisableBtn, hasPaid, hasExpired, hasCancelled, payment, hasFailed }
    }
    const sendPaymentLink = async (component: string) => {
        console.log('component:', component === getOrderList[0].notes.order_by);
        const findComponent = getOrderList.find((order: any) => order.notes.order_by === component);
        try {
            await sendAgainPaymentLink(findComponent.provider_link_id)
        } catch (e: any) {
            ShowAlert("warning", e?.response?.data?.message || "Some Technical Issue in Sending PaymentLink")
            console.log('e:', e);
        }
    }
    // console.log('getOrderList:--outside', getOrderList);
    useEffect(() => {
    // alert("check")
    // console.log('getOrderList:', getOrderList);
    if(!getOrderList?.length){
        setPaymentBtnStatus_Ambulance({
            isCreated: false,
            isFailed: false,
            isPaid: false,
            isExpired: false,
            isCanceled: false,
            data: null,
        })
        setPaymentBtnStatus_Doctor({

            isCreated: false,
            isFailed: false,
            isPaid: false,
            isExpired: false,
            isCanceled: false,
            data: null,
        })
    }
        getOrderList?.forEach((order: any) => {
            if (order?.notes?.order_by.toLowerCase() === "doctor-consultation") {
                const res = checkOrderStatus(order);
                setPaymentBtnStatus_Doctor((prev: any) => ({ ...prev, isCreated: res.hasDisableBtn, isPaid: res.hasPaid, isExpired: res.hasExpired, isCanceled: res.hasCancelled, data: res.payment, isFailed: res.hasFailed }))
            } else if (order?.notes?.order_by.toLowerCase() === "ambulance") {
                const res = checkOrderStatus(order);
                // console.log('payment:', res);
                setPaymentBtnStatus_Ambulance((prev: any) => ({ ...prev, isCreated: res.hasDisableBtn, isPaid: res.hasPaid, isExpired: res.hasExpired, isCanceled: res.hasCancelled, data: res.payment, isFailed: res.hasFailed }))
            }
        })
        //eslint-disable-next-line
    }, [getOrderList])

   
    return {
        paymentBtnStatus_Ambulance,
        paymentBtnStatus_Doctor,
        sendPaymentLink
    };
}