// CustomSelect.tsx
import React from "react";
import { Select } from "antd";

interface CustomSelectProps {
  mode?: "multiple" | "tags";
  allowClear?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  defaultValue?: string[];
  onChange: (value: string[]) => void;
  options: any;
}

export const CustomMultiSelect: React.FC<CustomSelectProps> = ({
  mode,
  allowClear,
  style,
  placeholder,
  defaultValue,
  onChange,
  options,
}) => {
  return (
    <Select
      mode={mode}
      allowClear={allowClear}
      style={style}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  );
};
