import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UseGetStoreData } from "../hooks/getStoreData/UseGetStoreData";


export const PrivateRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const { getUserData } = UseGetStoreData();
  useEffect(() => {
    // if (!getUserData?.userId) {
    //   navigate("/login");
    // }
  }, [getUserData, navigate]);

  // if (!getUserData?.userId) {
  //   return null;
  // }

  return children;
};
