import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import { Flex } from "../../molecules/flex/Flex";
import style from "./responseCard.module.scss";

interface ResponseCardProps {
  message: any;
  icon: string;
}

export const ResponseCheck: React.FC<ResponseCardProps> = ({
  message,
  icon,
}) => {
  return (
    <div>
      <Flex className={style.flex}  >
        <Container className={style.icon_wrapper}>
          <Icon className={style.icon} src={icon} alt="PocoLogo" />
        </Container>
        <Container className={style.prompt}>
          <Text text={message} className={style.message} />
        </Container>
      </Flex>
    </div>
  );
};
