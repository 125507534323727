import { useDispatch } from "react-redux";
import { ticketUpdateById } from "../../http";
import { getNearbyHospitals, sendWhatsappMessage } from "../../pages/actions/actions";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { ticketUpdateClear, ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";
import { EmStartNotification, createWhatsappMessagePayload, currentDateTime } from "../../utils/helper";
import { ShowAlert } from "../../utils/showAlert";
import { useNavigate } from "react-router-dom";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useEffect, useState } from "react";
import { useEmergencyContact } from "../useEmergencyContact/UseEmergencyContact";
import { useTabHandling } from "../useTabHandling/UseTabHandling";

export const useLogisticsData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState(false);
  const { getTicketData } = UseGetStoreData();
  const { handleTabSwitch, showOperationTab } = useTabHandling();
  // console.log('getBenList://///', getBenList);
  // const {     
  // } = UseFetchBenList({
  //   ticketData:getTicketData,
  // });
  // console.log('getTicketData:', getTicketData);
  const [selectedAddress, setSelectedAddress] = useState(null); // Set initial selected address
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false)
  const [renderHospitalUi, setRenderHospitalUi] = useState(false); // this i need to store in the redux persistance storage//
  const [isHospitalSelected, setIsHospitalSelected] = useState(false);
  const [patientAddress, setPatientAddress] = useState<any>(null);
  const [patientSelectedHospital, setPatientSelectedHospital] = useState<any>(null);
  const [value, setValue] = useState(5); //actually now i handled these in parent component but i   need to handle in child without props
  const [hospitalLists, setHospitalList] = useState<any>([]);//
  const { validContacts } = useEmergencyContact()
  const handleIncreaseRadius = async (value: any) => {
    setValue(value);
    await nearByHospitalPayload({ selectedAddress, radius: value * 1000 })
  };
  const marks = {
    5: "5 Kms",
    10: "10 Kms",
    20: "20 Kms",
    30: "30 Kms",
    40: "40 Kms",
    50: "50 Kms",
    60: "60 Kms",
    70: "70 Kms",
    80: "80 Kms",
    90: "90 Kms",
    100: "100 Kms",
  };
  console.log('getTicketData:', getTicketData)
 
  const handleAddressSelection = (selectedAddress: any) => {
    // console.log("address:", address);
    //if address don't have lat lon need to get geoCode using g_api
    //if they have then no issue;      
    console.log('address:', selectedAddress);
    // alert( selectedAddress);
    if (selectedAddress?.geoLocation?.coordinates?.length || (selectedAddress?.location?.latitude && selectedAddress?.location?.longitude) || (selectedAddress?.location[0]?.latitude && selectedAddress?.location[0]?.longitude)) {
      setSelectedAddress(selectedAddress);
      handlePreferredHospitalUi();
      handleNearByHospitalUi();
      buildPatientPayload(selectedAddress);
    } else {
      ShowAlert("warning", 'Please select address with geoLocation')
    }

  };

  const buildPatientPayload = async (selectedAddress: any) => {
    const addressText = transformAddressText(selectedAddress);
    //  console.log('selectedAddress:', selectedAddress);
    const payload = {
      patientLocation: {
        address: addressText,
        landmark: selectedAddress.landmark || "",
        isLiftAvailable: selectedAddress.isLiftAvailable || false,
        isReachableByAmbulance: selectedAddress.isReachableByAmbulance || false,
        label: selectedAddress.label || "",
        geoLocation: {
          type: "Point",
          coordinates: [
            getLatitude(selectedAddress),
            getLongitude(selectedAddress)

          ],
        },
      },
    };

    dispatch(ticketUpdateStatusSuccess(payload))

    setPatientAddress(payload);
    let radius = 5000
    await nearByHospitalPayload({ selectedAddress, radius })

  }
  const nearByHospitalPayload = async ({ selectedAddress, radius }: any) => {
    const locationPayload = {
      latitude: Number(getLatitude(selectedAddress)),
      longitude: Number(getLongitude(selectedAddress)),
      radius: radius,
    };
    await getHospital(locationPayload);
  }
  const getLatitude = (address: any) => {
    if (address?.location?.latitude) return address.location.latitude;
    if (Array.isArray(address?.location) && address?.location[0]?.latitude) return address.location[0].latitude;
    if (address?.geoLocation?.coordinates) return address.geoLocation.coordinates[0];
    return null;
  };

  const getLongitude = (address: any) => {
    if (address?.location?.longitude) return address.location.longitude;
    if (Array.isArray(address?.location) && address?.location[0]?.longitude) return address.location[0].longitude;
    if (address?.geoLocation?.coordinates) return address.geoLocation.coordinates[1];
    return null;
  };
  const getHospital = async (addressPayload: {
    latitude: any;
    longitude: any;
    radius: number;
  }) => {
    // console.log('addressPayload:', addressPayload)
    setHospitalList([]);
    ///need to call hospital api//
    try {
      const response = await getNearbyHospitals(addressPayload);
      // const response = await getAllHospital();
      // console.log('response:', response)
      if (response) {

        setHospitalList(response.data);
      }
    } catch (e: any) {
      console.log(' e:', e)
      ShowAlert("warning", e.response.data.message || "some issue while getting hospital list")
    }
  };

  const transformAddressText = (selectedAddress: any) => {
    let addressText = `${selectedAddress?.addressLine2 || ""}, ${selectedAddress.addressLine1 || selectedAddress?.address || ""
      }, ${selectedAddress.city}, ${selectedAddress.state}, ${selectedAddress.pinCode
      }`;
    return addressText;

  }


  const handleSelectHospital = (address: any) => {
    // console.log('address:', address);
    // alert("check")
    setSelectedHospital(address);
    setIsHospitalSelected(!isHospitalSelected)
    handleNearByHospitalUi();
    selectedHospitalPayload({ selectedHospital: address })
  }
  const getHospitalContact = (contact: any) => {
    return contact?.map((el: any) => {
      return (
        el.contact
      )
    });
  }
  const selectedHospitalPayload = ({ selectedHospital }: any) => {
    const hospitalContact = getHospitalContact(selectedHospital?.contacts);
    const payload = {
      hospitalInfo: {
        hospitalName: selectedHospital.name,
        address: selectedHospital.address,
        contactNumber: hospitalContact?.join(","),
        hospitalContacts: selectedHospital.contacts,
        geoLocation: {
          type: "Point",
          coordinates: [selectedHospital?.location?.coordinates[1], selectedHospital?.location?.coordinates[0]],
        },
      },
    }
    setPatientSelectedHospital(payload);
  }
  const handleEditSelectedAddress = () => {
    setIsAddressSelected(!isAddressSelected);
    setSelectedHospital(null);
    setSelectedAddress(null);
    handlePreferredHospitalUi();
  };

  const handlePreferredHospitalUi = () => {
    setValue(5);
    setIsAddressSelected(!isAddressSelected);
    isHospitalSelected && setIsHospitalSelected(!isHospitalSelected)
    renderHospitalUi && setRenderHospitalUi(!renderHospitalUi)
  }
  const handleNearByHospitalUi = () => {
    setRenderHospitalUi(!renderHospitalUi)
    // setRenderSelectedHospitalUi(!renderSelectedHospitalUi);
  };
  const handleEditHospital = () => {
    setIsHospitalSelected(!isHospitalSelected);
    handleNearByHospitalUi();

  }


  // console.log("selectedAddress:", selectedAddress);
  const handleStartEmergency = async () => {
    let notifyEmPayload = {
      beneficiary: getTicketData?.patient?.fullName,
      to: [...validContacts]
    };
    const notifyEmTemplate = createWhatsappMessagePayload({ templateName: "NOTIFY_EC_ON_EMERGENCY", data: notifyEmPayload });
    // console.log('notifyEmTemplate:', notifyEmTemplate)
    const TicketUpdateTracking = {
      notes: [...getTicketData?.notes, {
        date: currentDateTime(),
        message: "Emergency Started"
      }]
    };
    await updateTicket({ ticketId: getTicketData._id, ticketPayload: { ...patientAddress, ...patientSelectedHospital, ...TicketUpdateTracking }, templatePayload: notifyEmTemplate });
    ShowAlert("success", EmStartNotification);
    return navigate("/doctor-consult-ambulance")
  }
  const updateTicket = async ({ ticketId, ticketPayload, templatePayload }: any) => {
    try {
      if (ticketId) {
        const response = await ticketUpdateById(ticketId, ticketPayload);
        if (response) {
          sendWhatsAppMessageToEm(templatePayload);
          dispatch(ticketDataSuccess(response.data));
          dispatch(ticketUpdateClear());
          // setShowModal(true)
          // sendWhatsAppMessageToEm(sendWhatsappMessagePayload);
        }
      }
    } catch (e) {
      return alert("some Technical Issue");
    }
  }

  const sendWhatsAppMessageToEm = async (payload: any) => {
    try {
      const response = await sendWhatsappMessage(payload);
      if (response) {
        // setIsLoading(true)
      }
    } catch (e: any) {
      alert(e?.response?.data?.message || "Some Technical Issue In sending Wa Msg")
    }
  };
  useEffect(()=>{
    if (getTicketData?.currentMedicalStatusResponse?.isAmbulanceRequired === "No") {
      return navigate("/doctor-consult-ambulance")
    }
  },[getTicketData,navigate])


  return { isAddressSelected, selectedAddress, setSelectedAddress, handleAddressSelection, handleEditSelectedAddress, isHospitalSelected, selectedHospital, handleEditHospital, renderHospitalUi, value, handleIncreaseRadius, marks, patientAddress, hospitalLists, handleSelectHospital, handleStartEmergency, handleTabSwitch, showOperationTab, getTicketData }
}