import { Slider } from "antd";
import "./customSlider.css"
interface CustomSliderProps {
  min: number;
  max: number;
  marks: any;
  value: any;       
  handleChange: any;
}
export const CustomSlider: React.FC<CustomSliderProps> = ({
  min,
  max,
  marks,
  value,
  handleChange,
}) => {
  return (
    <>
    <div className="slider-container">
      <Slider
        min={min}
        max={max}
        step={null}
        marks={marks}
        value={value}
        onChange={handleChange}
      />
    </div>
    </>
  );
};
