import style from "./button.module.scss"
interface ButtonProps{
    color?:string
    text:string
    onClick?:()=>void
    className?:string
    [key:string]:any;
    disable?:boolean;
    
}

export const Button:React.FC<ButtonProps>=({text,className,onClick,disable})=>{

    return <button disabled={disable} onClick={onClick} className={`button ${style.is_disabled} ${className}`}>{text}</button>
}