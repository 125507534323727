import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Address } from "../address/Address";
import style from "./ambulanceCard.module.scss";

interface AmbulanceCardProps {
  location: string;
  label: string;
  address: string;
}

export const AmbulanceCard: React.FC<AmbulanceCardProps> = ({
  label,
  address,
  location,
}) => {
  return (
    <>
      <Container className={style.horizon_wrapper}>
        <Container className={style.address_card1}>
          <Text text={location} className={style.title} />
        </Container>
        <Container className={style.address_card2}>
          <Address
            line1={label}
            line2={address}
            line3=""
            line4=""
            leftSideIcon={
              location === "Drop Location :"
                ? "/images/hospital.png"
                : "/images/home.png"
            }
            rightSideBadgeText=""
          />
          {/* {location === "Drop Location :" && (
            <div style={{ display: "inline-flex", width: "auto" }}>
             
            </div>
          )} */}
        </Container>
      </Container>
    </>
  );
};
