import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Input } from "../../atoms/input/Input";
import { Text } from "../../atoms/text/Text";
import { PatientCondSelect } from "../../molecules/patientCondSelect/PatientCondSelect";
import style from "./patientcondition.module.scss";
import { usePatientCondition } from "../../../hooks/usePatientCondition/UsePatientCondition";

export const PatientCondition = () => {
  const {otherDes,getPatientCondition,handleCatchOtherDescription}=usePatientCondition();
  return (
    <>
      <BackgroundWrapper className={style.wrapper}>
        <Container className={style.container}>
          <Container>
            <Text
              text="Current Condition of the Patient"
              className={style.title}
            />
          </Container>
          <Container className={style.conditionWrap}>
            <Container>
              <Text
                text="Select Patient’s Condition"
                className={style.question}
              />
              <PatientCondSelect getPatientCondition={getPatientCondition}/>
            </Container>
            <Container>
              <Text text="Others" className={style.question} />
              <Input
                className={style.input}
                searchValue={handleCatchOtherDescription}
                value={otherDes}
                placeholder="Enter Others"
              />
            </Container>
          </Container>
        </Container>
      </BackgroundWrapper>
    </>
  );
};
