import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  isLoading: Boolean;
  ticketData: any;
  isError: Boolean;
} = {
  isLoading: false,
  ticketData: null,
  isError: false,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    ticketStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    ticketDataSuccess: (state, action: PayloadAction<any>) => {
      console.log('action:', action);
      state.isLoading = false;
      state.isError = false;
      state.ticketData = action.payload;
    },
    ticketDataFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.ticketData = null
    },
    
  },
});

export const { ticketStart, ticketDataSuccess, ticketDataFailure } =
ticketSlice.actions;

export default ticketSlice.reducer;
