import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import style from "./horizontalList.module.scss"


interface HorizontalListProps {
  question: string;
  ans: string;
}

export const HorizontalList: React.FC<HorizontalListProps> = ({
  question,
  ans,
}) => {
  return (
    <>
      <Container>
        <div className={style.wrapper}>
          <Text className={style.question} text={question} />
          <Text className={style.ans} text={ans} />
        </div>
      </Container>
    </>
  );
};
