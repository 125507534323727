import { useNavigate } from "react-router-dom";
import { objectToArray, payloadBuilderForObject } from "../../utils/helper";

import { UseGetStoreData } from "../getStoreData/UseGetStoreData";  
import { useState } from "react";

export const    UseTicketBasicDetails = () => {
  const { getTicketData,getTicketUpdateData } = UseGetStoreData();

  const navigate = useNavigate();
  const [showPocUpdateModal,setShowPocUpdateModal]=useState(false);
  const fieldsLeft = [
      "callerID",
      "callerType",
      "sourceOfTicket",
      "companyName",
      "campusId",
      "subscriber"
    ];
    const fieldsRight = ["poc", "detectedLocation"];
      const handleClick = () => {
          return navigate("/");
      };
      const getFilteredDataLeftSide = payloadBuilderForObject({
        fields: fieldsLeft,
        obj: getTicketData?getTicketData:{},
      });
      // console.log('getFilteredDataLeftSide:', getFilteredDataLeftSide);
      const transformedDataLeftSide = objectToArray({
        fields: fieldsLeft,
        obj: getFilteredDataLeftSide,
      });
      // console.log('transformedDataLeftSide:', transformedDataLeftSide);
      const getFilteredDataRightSide = payloadBuilderForObject({
        fields: fieldsRight,
        obj: getTicketData?getTicketData:{},
      });
      let transformedDataRightSide = objectToArray({
        fields: fieldsRight,
        obj: getFilteredDataRightSide,
      });
    
      // console.log('isTicketDataPresent:', isTicketDataPresent);
   const handleEditPoc=()=>{
    // alert("clicked")
    setShowPocUpdateModal(!showPocUpdateModal)
   }
  //  getTicketUpdateData
  //getAlreadyFilledTicket
  const checkPatientData=getTicketData?.patient?.emergencyContacts?getTicketData?.patient?.emergencyContacts:getTicketUpdateData?.patient?.emergencyContacts
  // console.log('checkPatientData:##############', checkPatientData);
 
  if(checkPatientData){
     const getEmContact = checkPatientData.reduce((tempArr: any[], ticketData: any, index: number) => {
      tempArr.push({ label: `em${index + 1}`, value: ticketData.mobile });
      return tempArr;
  }, []);
   transformedDataRightSide=[...getEmContact,...transformedDataRightSide];
  };
  return{
    handleClick,
    transformedDataLeftSide,
    transformedDataRightSide,
    handleEditPoc,
    showPocUpdateModal,
  } 
};
