import { insuranceFieldTransformer } from "../../../utils/helper";
import { Container } from "../../atoms/container/Container";
import style from "./dynamic.module.scss";
import React from "react";
interface DynamicCardProps {
  data: any;
  excludeFields: string[];
  className?: any;
}
export const DynamicCard: React.FC<DynamicCardProps> = ({
  data,
  excludeFields,
  className,
}) => {
  let SaSToken = localStorage.getItem("sasToken");
  return (
    <>
      <Container className={`${style.wrapper} ${className}`}>
        <Container className={style.card_wrapper}>
          <Container className={style.key}>
            {Object.keys(data).map((key) => (
              <p key={key}>{insuranceFieldTransformer[key]}</p>
            ))}
          </Container>
          <Container className={style.value}>
            {Object.entries(data).map(([key, value]: any, index) => (
              <React.Fragment key={key}>
                {key === "docUrl" ? (
                  <a href={value+"?"+SaSToken} target="_blank" rel="noopener noreferrer">
                    : Open
                  </a>
                ) : (
                  <p >: {value}</p>
                )}
              </React.Fragment>
            ))}
          </Container>
        </Container>
      </Container>
    </>
  );
};
