import { Button as AntBtn, Badge, Card } from "antd";
interface PaymentBtnProps {
    component: string,
    paymentBtnStatus: any,
    style: any,
    createPaymentLink: () => void,
    sendPaymentLink: (props: string) => void,
    btnDisable:boolean
}
export const PaymentButton: React.FC<PaymentBtnProps> = ({ component, paymentBtnStatus, style, createPaymentLink, sendPaymentLink,btnDisable }) => {
    return <>
        {(!paymentBtnStatus.isPaid) && <AntBtn  style={{ background: paymentBtnStatus.isFailed ? "red" : "", color: paymentBtnStatus.isFailed ? "white" : "" }} className={style} disabled={paymentBtnStatus.isCreated || paymentBtnStatus.isPaid || paymentBtnStatus.isFailed||btnDisable} onClick={createPaymentLink}>{(paymentBtnStatus.isCreated && "Link Send To Customer") || (paymentBtnStatus.isCanceled && "Link Cancelled") || (paymentBtnStatus.isFailed && "Payment Failed") || (`Send PaymentLink For ${component}`)}</AntBtn>}
        {(paymentBtnStatus.isCreated && !paymentBtnStatus.isPaid) && <AntBtn disabled={btnDisable} className={style.link_btn} onClick={() => sendPaymentLink(component)}>Resend PaymentLink</AntBtn>}
        {paymentBtnStatus.isPaid && <Badge.Ribbon style={{ backgroundColor: "green" }} text={"Paid"} color="green">
            <Card title={paymentBtnStatus?.data?.notes?.order_by} style={{ fontWeight: "500" }} size="small">
                {`${paymentBtnStatus?.data?.beneficiary?.name} paid ₹${paymentBtnStatus?.data?.amount}`}
            </Card>
        </Badge.Ribbon>}
        {((!paymentBtnStatus.isPaid && paymentBtnStatus.isCreated) || (paymentBtnStatus.isFailed)) && <AntBtn disabled={btnDisable} className={style} onClick={createPaymentLink}>Send New PaymentLink</AntBtn>}

    </>

}