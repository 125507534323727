import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getTicketData } from "../pages/actions/actions";
import { useDispatch } from "react-redux";
import { ticketDataSuccess } from "../redux/ticketSlice/ticketSlice";
import { ticketUpdateClear } from "../redux/ticketUpdateSlice/ticketUpdateSlice";
import { benDataSuccess } from "../redux/beneficiarySlice/beneficiarySlice";
import { UseGetStoreData } from "./getStoreData/UseGetStoreData";

export const usePagination= () => {
  const initialPageNumber = sessionStorage.getItem("currentPageNumber") 
  ? parseInt(sessionStorage.getItem("currentPageNumber") as string, 10)
  : 1;

const initialPageSize = sessionStorage.getItem("currentPageSize")
  ? parseInt(sessionStorage.getItem("currentPageSize") as string, 10)
  : 10;
  console.log('initialPageNumber:', initialPageNumber,initialPageSize);
   //eslint-disable-next-line
   const [to,setTo]=useState("");
   const [from,setFrom]=useState("");
   const [status,setStatus]=useState("");
    //eslint-disable-next-line
    const [hasPage, setHasPage] = useState(true);
    const [pageNumber, setPageNumber] = useState(initialPageNumber);
    const [agent, setAgent] = useState("");
    const [pageSize, setPageSize] = useState(initialPageSize);
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {getUserData}=UseGetStoreData();
    const fetchTickets=async({queryKey}:any)=>{
       //eslint-disable-next-line
      const [_key, { to, from, status, pageSize, pageNumber, agent }] = queryKey;
      // console.log(to,from,status,agent);
    let url = "";
    if (to !== "" && to) url += `to=${to}&`;
    if (from !== "" && from) url += `from=${from}&`;
    if (status !== "" && status) url += `ticketStatus=${status}&`;
    if (pageSize) url += `pageSize=${pageSize}&`;
    if (pageNumber) url += `pageNumber=${pageNumber}&`;
    if (agent) url += `agent=${agent}&`;
    if (url.endsWith("&")) url = url.slice(0, -1);
    try{      
      const data=await getTicketData(url,navigate);
      return data;
    }catch(e){
      console.log(e);
    }
  
    }
   
    const handlePageSizeChange = (newPageSize: number) => {
      // console.log('page:', newPageSize);
      setPageSize(newPageSize);
      setPageNumber(1);
      sessionStorage.setItem("currentPageSize", newPageSize.toString());
      sessionStorage.setItem("currentPageNumber", "1"); // Reset
    };
    const handlePageChange=(page:any)=>{
      // console.log('page:', page);
      setPageNumber(page)
      sessionStorage.setItem("currentPageNumber", page.toString());
    }
    const handleCollectFilterData = (filters: any) => {
    setTo(filters.dateTo);
    setFrom(filters.dateFrom);
    setStatus(filters.ticketStatus);
    setAgent(filters.agentName);
    };
   
  
    const { data, isLoading, isError } = useQuery({      
      queryKey:["tickets", { to, from, status, pageSize, pageNumber, agent }],
      queryFn:fetchTickets,
      placeholderData: keepPreviousData,
      select(data:any) {
        sessionStorage.setItem("currentPageNumber", pageNumber.toString());
      sessionStorage.setItem("currentPageSize", pageSize.toString());
      return data
      },
    }      
    );

    useEffect(()=>{
      dispatch(ticketDataSuccess(null));
      dispatch(ticketUpdateClear());
      dispatch(benDataSuccess([]));
       //eslint-disable-next-line
    },[])

    return {isLoading,isError,handlePageChange,handleCollectFilterData, handlePageSizeChange, pageNumber, pageSize, hasPage,data,getUserData}
}