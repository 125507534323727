import { Button } from "../../atoms/button/Button";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { AmbulanceCard } from "../../molecules/ambulanceCard/AmbulanceCard";
import style from "./ambulanceBooking.module.scss";
import { CustomSteps } from "../../molecules/ambulanceSteps/CustomSteps";
import { useAmbulanceBooking } from "../../../hooks/useAmbulanceBooking/UseAmbulanceBooking";
import { usePaymentStatus } from "../../../hooks/usePaymentStatus/usePaymentStatus";
import { PaymentButton } from "../paymentButton/PaymentButton";
import { AmbulanceDistance } from "../../molecules/ambulanceDistance/ambulanceDistance";
interface AmbulanceBookingProps {
  pickupLocation: any;
  dropLocation: any;
  label: string;
}
export const AmbulanceBooking: React.FC<AmbulanceBookingProps> = ({
  pickupLocation,
  dropLocation,
  label
}) => {
  const {
    currentStep,
    handleSendAmbulanceReq,
    ambulanceMessageSend,
    handleStepChanges,
    getTicketStatus,
    sendAMbulancePaymentLink,
    ambulanceDistanceBetween
  } = useAmbulanceBooking();
  const { paymentBtnStatus_Ambulance, sendPaymentLink } = usePaymentStatus();
  return (
    <>
      <Container className={style.wrapper}>
        <Container className={style.spacing}>
          <Text text="Ambulance Booking" className={style.title} />
          <PaymentButton btnDisable={getTicketStatus === "completed" ? true : false} component="Ambulance" paymentBtnStatus={paymentBtnStatus_Ambulance} style={style.link_btn} createPaymentLink={sendAMbulancePaymentLink} sendPaymentLink={() => sendPaymentLink("Ambulance")} />
          <Text text="Selected Pickup Point" className={style.sub_title} />
          <AmbulanceCard
            label={label || "N/F"}
            address={pickupLocation}
            location="Pick-up Location:"
          />
          <Text text="Selected Drop Point" className={style.sub_title} />
          <AmbulanceCard
            label={dropLocation?.hospitalName || "N/F"}
            address={dropLocation?.address || "N/F"}
            location="Drop Location :"
          />
          <AmbulanceDistance
            label="Distance Between:"
            distance={ambulanceDistanceBetween}
          />
        </Container>
        {!ambulanceMessageSend && (
          <Button
            disable={getTicketStatus === "completed" ? true : false}
            className={
              getTicketStatus === "completed" ? style.is_disabled : style.button
            }
            text="Send Ambulance Message"
            onClick={handleSendAmbulanceReq}
          />
        )}
        <Text text="Ambulance Tracking" className={style.title} />
        <div style={{ width: "100%" }}>
          <CustomSteps
            currentStep={currentStep}
            setCurrentStep={handleStepChanges}
            totalSteps={4}
          />
        </div>
      </Container>

    </>
  );
};
