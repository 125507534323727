import { useDispatch } from "react-redux";
import { getTicket } from "../../pages/actions/actions";
import { errorFormate } from "../../utils/helper";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useNavigate } from "react-router-dom";
import { skipToken, useQuery } from "@tanstack/react-query";

export const useGetTicketData=({id}:any)=>{
    const { getTicketData: isTicketDataPresent } = UseGetStoreData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getTicketTicketId = async (ticketId: string) => {
        try {
          return await getTicket({ ticketId, navigate, dispatch });
        } catch (e) {
          ShowAlert("warning", errorFormate({ e, service: "getTicket" }));
        }
      };
      const shouldFetch = id
        ? !isTicketDataPresent || isTicketDataPresent._id !== +id
        : navigate("/");
      const { data, isLoading, isError } = useQuery({
        queryKey: ["ticket", id],
        queryFn: shouldFetch ? () => getTicketTicketId(id || "1") : skipToken,
      });
      console.log('shouldFetch:FromF', shouldFetch,data)
      return{shouldFetch,data,isLoading,isError}
}