import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  isLoading: Boolean;
  ticketUpdateData: any;
  isError: Boolean;
} = {
  isLoading: false,
  ticketUpdateData: {
    ticketId:null,
    currentMedicalStatusResponse: {
        isItEmergency: "Yes",
        isAmbulanceRequired: "Yes",
        isDoctorConsultationRequired: "Yes",
        isPatientConscious: "Yes",
        currentCondition: [""],
        otherDescription: "",
      },
  },
  isError: false,
};

const ticketUpdateSlice = createSlice({
  name: "ticketUpdate",
  initialState,
  reducers: {
    ticketUpdateStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    ticketUpdateCurrentMedicalStatusSuccess: (state, action: PayloadAction<any>) => {
      // console.log('action:', action);
      state.isLoading = false;
      state.isError = false;
      let payload=action.payload
      
      state.ticketUpdateData.currentMedicalStatusResponse = {...state.ticketUpdateData.currentMedicalStatusResponse,...payload};
    },
    ticketIdStatusSuccess: (state, action: PayloadAction<any>) => {
      // console.log('action:', action);
      state.isLoading = false;
      state.isError = false;       
      state.ticketUpdateData.ticketId =action.payload;
    },

    ticketUpdateDefaultValue:(state,action: PayloadAction<any>)=>{
      state.isLoading = false;
      state.isError = false;
      state.ticketUpdateData={       
        ticketId:action.payload,
        currentMedicalStatusResponse: {
            isItEmergency: "Yes",
            isAmbulanceRequired: "Yes",
            isDoctorConsultationRequired: "Yes",
            isPatientConscious: "Yes",
            currentCondition: [""],
            otherDescription: "",
          }
    }
  },

    ticketUpdateStatusSuccess: (state, action: PayloadAction<any>) => {
      // console.log('action:', action);
      state.isLoading = false;
      state.isError = false;
      let payload=action.payload
      
      state.ticketUpdateData = {...state.ticketUpdateData,...payload};
    },
    ticketUpdateFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.ticketUpdateData = null
    },
    ticketUpdateClear: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.ticketUpdateData = null
    },
    
  },
});

export const { ticketUpdateStart,ticketIdStatusSuccess,ticketUpdateDefaultValue, ticketUpdateCurrentMedicalStatusSuccess,ticketUpdateStatusSuccess, ticketUpdateClear,ticketUpdateFailure } =
ticketUpdateSlice.actions;

export default ticketUpdateSlice.reducer;
