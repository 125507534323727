import { ToastContainer } from "react-toastify";
import { Navbar } from "./components/organisms/navbar/Navbar";
import { MainRoutes } from "./mainRoutes/MainRoutes";
import "react-toastify/dist/ReactToastify.css";
import logger from './logger';
import { ModalProvider } from "./components/organisms/modalProvider/ModalProvider";
import { InternatStatus } from "./components/molecules/internetStatus/InternatStatus";

// Override global console methods
if (process.env.REACT_APP_NODE_ENV === 'production') {
  // alert("sdds")
  console.log = logger.log;
  console.error = logger.error;
  console.warn = logger.warn;
}

function App() {

  return (
    <InternatStatus>
      <ModalProvider>
        <div >
          <ToastContainer autoClose={3000} />

          <Navbar />
          <MainRoutes />

        </div>
      </ModalProvider>
    </InternatStatus>
  );
}

export default App;