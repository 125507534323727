import { useState } from "react";
import { useLocation } from "react-router-dom";

export const useTicketBasicWrapper = () => {
    const location:any=useLocation();
    const [isOpenCallDetails, setIsOpenCallDetails] = useState(location.pathname.startsWith("/handleEmergency")?true:false);
    const handleOpenTab=()=>{
      setIsOpenCallDetails(!isOpenCallDetails)
    }
  return {    
  handleOpenTab,
  isOpenCallDetails
};
  
}
