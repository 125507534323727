import { Alert } from 'antd';
 
interface CustomALertProps{
    message:string;
    className?:any;
    type?:string;
}

const CustomAlert:React.FC<CustomALertProps> = ({message,className,type}) => {
  return (
    <Alert
      message={message}
      type="info"
      showIcon    
      className={className}  
     
    />
  );
};

export default CustomAlert;
