import {
  addressAutoComplete,
  addressFromPlacid,
  adminUserLogout,
  agentLogin,
  ambulanceProvidersQueryApi,
  ambulanceRatecardsQueryApi,
  calculateDistance,
  callManually,
  distanceMatrix,
  generateSasToken,
  getAllBeneficiaryBySubId,
  getAllCallLogsByTicketId,
  getAllHospitalById,
  getAllOffices,
  getAllTicket,
  getBenByCompanyName,
  getBenByMobile,
  getDoctorsRoster,
  getLatLon,
  getNearByHospital,
  getOrdersByTicketId,
  getPocoDoctors,
  getPocoTeamData,
  getProfilePerk,
  getSingleBenData,
  getTicketById,
  handleEmergencyPopupClose,
  resendPaymentLink,
  roadDistanceAndTime,
  saveCallDetailsLogs,
  sendMeetingLink,
  sendPaymentLink,
  sendWhatsAppMessage,
  ticketUpdateById,
  doctorRatecardsQueryApi
} from "../../http";
import {
  benDataFailure,
  benDataStart,
  benDataSuccess,
  benDataUpdate,
} from "../../redux/beneficiarySlice/beneficiarySlice";
import {
  ticketDataFailure,
  ticketDataSuccess,
  ticketStart,
} from "../../redux/ticketSlice/ticketSlice";
import {
  loginFailure,
  loginStart,
  loginSuccess,
} from "../../redux/userSlice/userSlice";
import { errorFormate } from "../../utils/helper";
import { ShowAlert } from "../../utils/showAlert";

//here all page apis are their////

///LOGIN__Page_Api----////

export const agentLoginWrap = async (payload: any, dispatch: any) => {
  try {
    dispatch(loginStart());
    const res = await agentLogin(payload);
    dispatch(loginSuccess(res.data));
    return res.data;
  } catch (e: any) {
    dispatch(loginFailure());
    // return alert(e?.response?.data?.message || "Some Technical Issue");
    throw e;
    // return e.response?.data;
  }
};

///Ticket__Page_Api----////
// (i)get ticket
export const getTicketData = async (payload: string, navigate: any) => {
  try {
    const response = await getAllTicket(payload);
    // console.log('response:', response)
    return response?.data;
  } catch (e: any) {
    console.error("error from get Ticket Data", e.message);
    if (e?.message === "Request failed with status code 401") {
      alert(
        e?.response?.data?.message || "Some Technical Issue in Getting Tickets"
      );
      // localStorage.setItem('isLogin', "false");
      // return navigate("/login");
    } else {
      throw e;
    }
  }
};

//getTicketBy_ticketId////
// ticketHandlingPage_APi//
export const getTicket = async ({
  ticketId,
  navigate,
  dispatch,
}: {
  ticketId: string;
  navigate: any;
  dispatch: any;
}) => {
  try {
    dispatch(ticketStart());
    const response = await getTicketById(ticketId);
    // console.log('response:', response);
    dispatch(ticketDataSuccess(response?.data));
    return response;
  } catch (e: any) {
    dispatch(ticketDataFailure());
    if (e?.message === "Request failed with status code 401") {
      // alert(
      //   e?.response?.data?.message || "Some Technical Issue in Getting Tickets"
      // );
      // // localStorage.setItem("isLogin", "false");
      // return navigate("/login");
    } else {
      throw e;
    }
  }
};


//updateTicket///

export const updateTicket = async (id: string, ticketPayload: any, dispatch?: any) => {
  try {
    if (id) {
      const response = await ticketUpdateById(id, ticketPayload);
      if (dispatch) {
        // console.log('response:', response.data)
        dispatch(ticketDataSuccess(response?.data))
      }

      if (response) {
        return response
        // setShowModal(true)
        // sendWhatsAppMessageToEm(sendWhatsappMessagePayload);
      }
    }
  } catch (e) {
    return alert("some Technical Issue");
  }
}


///getAllBen_//
// ticketHandlingPage_APi//
export const getBeneficiaryData = async (subId: string, dispatch: any, action?: any) => {
  try {
    dispatch(benDataStart());
    const { data } = await getAllBeneficiaryBySubId(subId);

    if (data.data.length) {
      // console.log("benData", data.data);
      action ? dispatch(benDataUpdate(data.data)) : dispatch(benDataSuccess(data.data));
      return data.data;
    }
  } catch (e: any) {
    dispatch(benDataFailure());
    throw e;
    // return alert(e?.response?.data?.message||"Some Technical Issue")
    // return e.response?.data;
  }
};

///getSingleBen&&getAllBen_//
// ticketHandlingPage_APi//
export const getSingleBenDetails = async (benId: string, dispatch: any) => {
  try {
    const { data } = await getSingleBenData(benId);
    if (data?.data?.subscriberId) {

      return await getBeneficiaryData(data?.data?.subscriberId, dispatch);
    } else {
      ShowAlert("warning", "Beneficiary Not Found");
    }
  } catch (e: any) {
    // console.error("error from getting single ben data");
    // return alert(e.response.data.message);
    throw e;
  }
};

//getBen_by_Mobile//
// ticketHandlingPage_APi//
export const getBeneficiaryDataByMobile = async (mobile: string, dispatch: any) => {
  try {
    const { data } = await getBenByMobile(mobile);
    // console.log("data:", data);
    if (data.data.length) {
      // let action;
      // await getBeneficiaryData(data.data.subscriberId, dispatch,action="updateTicket");
      return data.data;
    } else {
      return [];
    }
  } catch (e: any) {
    // return alert(e?.response?.data?.message || "Some Technical Issue");
    throw e;
    // return e.response?.data;
  }
};
//get_All_Partner_Company
export const getAllCompany = async () => {
  try {
    const res = await getAllOffices();
    return res.data.data;
  } catch (e) {
    throw e;
  }
};

//getBen_Company&Ben_Name
// ticketHandlingPage_APi//
export const getBenByCompanyAndBenName = async (payload: any) => {
  try {
    const res = await getBenByCompanyName(payload);
    return res.data;
  } catch (e) {
    throw e;
  }
}

//logistic__page
//g_map suggestion//
export const autoCompletionResult = async (payload: any) => {
  try {
    const res = await addressAutoComplete(payload);
    return res.data;
  } catch (e) {
    alert("Error geocoding address:");
    throw e;
  }
}
//logistic__page
//g_map getFull_address//
export const getFullAddress = async (placeId: string) => {
  try {
    const res = await addressFromPlacid(placeId);
    return res.data;
  } catch (e) {
    ShowAlert("warning", errorFormate({ e, service: "Error from getting address" }));
  }
}

//get city location information from address
export const getCityFromAddress = async (addressText: string) => {
  let payload = {
    addressText
  };

  try {
    const res = await getLatLon(payload);
    console.log(res);

    const addressComponents = res.data.results[0].address_components;
    const cityComponent = addressComponents.find((component: { types: string[] }) =>
      component.types.includes("locality")
    );

    return cityComponent ? cityComponent.long_name : " ";
  
  } catch (e) {
    ShowAlert("warning", errorFormate({ e, service: "Error from getting city" }));
  }
};


//get_GeoCode

export const getGeoCode = async (addressText: string) => {
  let payload = {
    addressText
  }
  try {
    const res = await getLatLon(payload);
    const data = res.data;
    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      throw new Error('No results found');
    }

  } catch (e) {
    ShowAlert("warning", errorFormate({ e, service: "Error from getting address" }));
  }
}

//find_closest_address
export async function findClosestAddress(targetLat: any, targetLon: any, address: any) {
  try {
    let payload = {
      targetLat: targetLat,
      targetLng: targetLon,
      address: address
    }
    // console.log('payload:', payload)
    const res = await distanceMatrix(payload);
    return res.data.data.message ? false : res.data.data;
    // return res?.data?.data||[];
  } catch (e) {
    // alert("Error finding Closest Address");
    // throw e;
  }

}

//nearby_hospital//
export const getNearbyHospitals = async (payload: { longitude: number, latitude: number, radius: number }) => {
  try {
    const { data } = await getNearByHospital(payload);
    return data

  } catch (e: any) {
    // console.error("error from getting single ben data");
    // return alert(e?.response?.data?.message||"Some Technical Issue");
    throw e;
  }
};

//calculate distance between two addresses
export const calculateDistanceBetween = async (payload: any) => {
  try {
    const {patientLat: originLat, patientLon: originLng, hospitalLat:destLat, hospitalLon:destLng } = payload
    const response = await calculateDistance({
      originLat,
      originLng,
      destLat,
      destLng,
    });
    console.log("calculate distance",response);
    return response?.data?.distance;
  }
  catch (error:any) {
    throw new Error("Error in calculating between between patient location and hospital location",error);
  }
}



//sendWa_msg///

export const sendWhatsappMessage = async (payload: any) => {
  try {
    const response = await sendWhatsAppMessage(payload);
    return response;

  } catch (error: any) {
    throw error;

  }

}


//share_patient_Profile
//doctor_consult_page
export const sendMedicalPerk = async (benId: string) => {
  try {
    const response = await getProfilePerk(benId);
    return response.data.data;
  } catch (e: any) {
    throw e;
  }
};


//sas_Token_for Docs//
export const generateSaSToken = async () => {
  // Function to generate and store SAS token
  const generateAndStoreToken = async () => {
    try {
      let sasToken = await generateSasToken(); // Assuming generateSasToken is defined elsewhere
      // console.log('sasToken:', sasToken);
      localStorage.setItem("sasToken", sasToken.data.data);
    } catch (error) {
      console.error("SAS token generation error:", error);
    }
  };
  // Call the function immediately and then every 10 seconds
  generateAndStoreToken(); // Initial call
  const intervalId = setInterval(generateAndStoreToken, 25 * 60 * 1000); // Refresh every 10 seconds
  // Return the interval ID so it can be cleared if needed
  return intervalId;
}


//getCall_logs_By_TicketId

export const getCallLogsByTicketId = async (ticketId: any) => {
  try {
    const response = await getAllCallLogsByTicketId(ticketId);
    // console.log('response@@@@@@@@@@@@@@@@@@@@@:', response);
    return response.data.data;
  } catch (e: any) {
    // console.log("e",e)
    throw e;
  }
};


//get_poco_internal

export const getInternalPocoTeamDetails = async () => {
  try {
    const response = await getPocoTeamData();
    return response?.data?.configurations;
  } catch (e) {
    throw e;
  }
}

//logout_api.


export const adminUserLogoutAction = async () => {
  try {
    const res = await adminUserLogout();
    return res.data;
  } catch (e: any) {
    throw e;
  }
};


//handle_EMergency

export const handleEmergency = (id: string) => {
  try {
    handleEmergencyPopupClose(id);
  } catch (e) {
    throw e;
  }
};

export const gethDoctorsRosterFun = async () => {
  try {
    let now = new Date();
    let dayIndex = now.getDay();
    let daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    let currentDay = daysOfWeek[dayIndex];
    let response = await getDoctorsRoster(currentDay)
    return response.data
  } catch (error: any) {
    console.log(error)
    if (error.response?.status !== 404) {
      alert(error.response?.data?.message || "Issue while getting doctors data")
    }
  }
}

export const getPocoDoctorsFun = async () => {
  try {
    let pocodoctors = await getPocoDoctors()
    return pocodoctors.data
  } catch (error: any) {
    alert(error.response?.data?.message || "Issue while getting pocodoctors")
  }
}

export const sendMeetingLinkFun = async (payload: any) => {
  try {
    let response = await sendMeetingLink(payload)
    return response.data
  } catch (error: any) {
    alert(error.response?.data?.message || "some issue while sending meeting link")
  }
}

//ozontell///
export const connectCall = async (payload: any, ticketId: string) => {
  try {
    const response = await callManually(payload)
    // console.log('response:', response);
    await saveCallLogs(ticketId, response.data.data.ucid, payload.customerNumber)
    return response.data
  } catch (e: any) {
    ShowAlert("warning", e?.response?.data?.message || "some issue while sending meeting link");
  }
}

const saveCallLogs = async (ticketId: string, ucid: string, callerId: string) => {
  try {
    const formattedDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const payload = { ticketId, ucid, callerId, formattedDateTime };
    // console.log('payload:', payload);
    await saveCallDetailsLogs(payload);
  } catch (e: any) {
    throw e;
  }
};

export const getHospitalById = async (hospitalId: string) => {
  try {
    const res = await getAllHospitalById(hospitalId)
    console.log('res:!!!!!!!!!!!!!!!!!!', res)
    return res?.data?.data;
  } catch (e) {
    throw e;
  }

}

//get nearBy  first 3 hospital Road Distance//
export const getHospitalRoadDistanceAndTime = async (payload: any) => {
  try {
    const res = await roadDistanceAndTime(payload);
    return res.data;
  } catch (e) {
    throw e;
  }
};

//send PaymentLink

export const sendPaymentViaLink = async (payload: any) => {
  try {
    const res = await sendPaymentLink(payload);
    return res.data;
  } catch (e) {
    throw e;
  }
};

//reSendPaymentLink//


export const sendAgainPaymentLink = async (paymentLinkId: string) => {
  try {
    const res = await resendPaymentLink(paymentLinkId);
    return res.data;
  } catch (e) {
    throw e;
  }
}


//getOrders in DataBase

export const ordersByTicketId = async (ticketId: string) => {
  try {
    const res = await getOrdersByTicketId(ticketId);
    return res.data;
  } catch (e) {
    throw e;
  }

}

/// API method to get all the ambulance providers

export const findAmbulanceProviders = async () => {
  try {
    const payload = {
      "isDeleted": false
    }
    const res = await ambulanceProvidersQueryApi(payload);
    return res.data?.data;
  } catch (e) {
    throw e;
  }
};



/// API method to query the ambulance rate cards 
export const findAmbulanceRatecard = async (payload:Object) => { 
  try { 
    payload = { ...payload, "isDeleted": false };
    const res = await ambulanceRatecardsQueryApi(payload);
    return res.data;
  }
  catch (e) {
    throw e;
  }
}


// Api method to query the ambulance rate cards

export const findDoctorRatecard = async () => { 
  try { 
  
    const res = await doctorRatecardsQueryApi();
    return res.data;
  }
  catch (e) {
    throw e;
  }
}

