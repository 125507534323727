import { useState } from "react";

export const useBenSearchNumber=({handleSearchByMobile}:any)=>{
    const [mobileNo, setMobileNo] = useState("");
    const handleCatchValue=(value:string)=>{
      const updatedValue = value.replace(/\D/g, "").slice(0, 10);
      setMobileNo(updatedValue);
    }
    const handleClick = () => {    
      handleSearchByMobile(mobileNo);
    };

    return{
        handleCatchValue,handleClick,mobileNo
    }
}