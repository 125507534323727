import React from "react";
import { Select } from "antd";
import styles from "./doctorSelect.module.scss";

const { Option } = Select;

interface doctorRosterInterface {
  doctorId: string,
  doctorName: string,
  mobileNo: string,
  endTime: string,
  startTime: string,
  languages?: [],
  pocoDoctor?: boolean
}

interface DoctorSelectProps {
  doctors: doctorRosterInterface[];
  onChange: (doctorId: string) => void;
  onClick: () => void;
}

const DoctorSelect: React.FC<DoctorSelectProps> = ({ doctors, onChange, onClick }) => {
  return (
    <Select
      placeholder="Select Doctor"
      className={styles.select_props}
      onChange={onChange}
      onClick={onClick}
    >
       <Option>Select Doctor</Option>
      {doctors.map((doctor, index) => (
        <>
        <Option key={index} value={doctor.doctorId}>
          <div className={styles.option}>
            <span className={styles.name}>{doctor.doctorName}</span>
            <span className={styles.specialty}>{doctor.pocoDoctor ? "PocoDoctor" : "Emergency Specialist"}</span>
          </div>
        </Option>
       
        </>
      ))}
    </Select>
  );
};

export default DoctorSelect;
