import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { getBeneficiaryData, getSingleBenDetails } from "../../pages/actions/actions";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { transformLowerCase } from "../../utils/helper";

const useTicketNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = useCallback(async (ticketData:any) => {
    if (ticketData?.subscriber?.id) {
      return getBeneficiaryData(ticketData?.subscriber?.id, dispatch);
    } else if (ticketData?.ticketRaisedBy?.benId) {
      return getSingleBenDetails(ticketData?.ticketRaisedBy?.benId, dispatch);
    } else if (ticketData?.patient?.id) {
      return getSingleBenDetails(ticketData?.patient?.id, dispatch);
    } else {
      return [];
    }
  }, [dispatch]);

  const handleRedirect = useCallback(async (record:any) => {
    dispatch(ticketDataSuccess(record));
    await fetchData(record);
    let ticketStatusTransformed = transformLowerCase(record?.ticketStatus);
    if (ticketStatusTransformed === "workinprogress") {
      if ((record?.patientLocation && record.hospitalInfo) ||record?.currentMedicalStatusResponse?.isAmbulanceRequired==="No") {
        navigate("/doctor-consult-ambulance");
      } else {
        navigate("/logistics");
      }
    } else if (ticketStatusTransformed === "completed") {
      if (record?.patientLocation && record.hospitalInfo) {
        navigate("/doctor-consult-ambulance");
      } else {
        navigate("/logistics");
      }
    } else if (ticketStatusTransformed === "cancelled") {
      navigate(`/cancelled-ticket/${record._id}`);
    }else if(ticketStatusTransformed ==="testdemo"||ticketStatusTransformed ==="generalenquiry"||ticketStatusTransformed==="customertest" ||ticketStatusTransformed==="internaltest"){
      navigate(`/demo-general-enquiry/${record._id}`);
    }
     else {
      navigate(`/handleEmergency/${record._id}`);
    }
  }, [dispatch, fetchData, navigate]);

  return handleRedirect;
};

export default useTicketNavigation;
