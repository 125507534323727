import { Container } from "../../atoms/container/Container";
import { RadioButtonGroup } from "../../atoms/radioButtonGroup/RadioButtonGroup";
import { Text } from "../../atoms/text/Text";
import style from "./medical.module.scss";
interface DynamicRadioGroupProps {
  question: string;
  options: Array<{ value: string; label: string }>;
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  className?: any;
  disable: boolean;
}
export const MedicalCondList: React.FC<DynamicRadioGroupProps> = ({
  question,
  options,
  name,
  value,
  onChange,
  className,
  disable,
}) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, event.target.value);
  };

  return (
    <>
      <Container className={style.wrapper}>
        <Container>
          <Text className={className} text={question} />
        </Container>
        <Container>
          <RadioButtonGroup
             column={3}
             gap={1}
            type="radio"
            options={options}
            name={name}
            selectedValue={value}
            onChange={handleOptionChange}
            disable={disable}
          />
        </Container>
      </Container>
    </>
  );
};
