import { Container } from "../../components/atoms/container/Container";
import { columns } from "../../utils/helper";
import style from "./tickets.module.css";
import PaginationControls from "../../components/atoms/pagination/Pagination";
import { usePagination } from "../../hooks/usePagination";
import TIcketFilter from "../../components/organisms/filter/TIcketFilter";
import CustomTableWithNavigation from "../../components/tabel/CustomTabel";
import { EmergencyTicketModal } from "../../components/organisms/emergencyTicketModal/EmergencyTicketModal";
import { useHandleSSE } from "../../hooks/usehandleSSE/UseHandleSSE";
export const Tickets = () => {  
  //eslint-disable-next-line
  const {handlePageChange,handleCollectFilterData, handlePageSizeChange, pageNumber, pageSize, data,getUserData}=usePagination(); 
const {
  isModalVisible,
  handleModalClose,
  closeModalRequestFromAllAgents,
  newEmergencyData,
  navigate,
  updateTicket,
} = useHandleSSE();
  return (
    <div>
      {/* <div className={style.head_Card} >
      <TopCard title={getUserData.name} icon="/images/user_icon.png"/>     
    </div> */}
      <Container className={style.wrapper}>
        <Container className={style.container}>
          <TIcketFilter filterDate={handleCollectFilterData} />
        </Container>
      </Container>
      <Container className={style.table}>
        <CustomTableWithNavigation
          columnData={columns}
          rowData={data?.data}
        />
      </Container>
      <PaginationControls
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalDataCount={data?.count}
        handlePageSizeChange={handlePageSizeChange}
        handleDataChange={handlePageChange}
      />
      <EmergencyTicketModal
        isModalVisible={isModalVisible}
        handleModalClose={handleModalClose}
        closeModalRequestFromAllAgents={closeModalRequestFromAllAgents}
        newEmergencyData={newEmergencyData}
        navigate={navigate}
        updateTicket={updateTicket}
      />
    </div>
  );
};
