import { useState } from "react";

export const useTabHandling = () => {
  const defaultTab=sessionStorage.getItem("activeTab")||"logistics"
  const [showOperationTab, setShowOperationTab] = useState(defaultTab);
  const handleTabSwitch = (value:string) => {
    setShowOperationTab(value);
    sessionStorage.setItem("activeTab",value)
  };

  return {
    handleTabSwitch,
    showOperationTab,
  };
};
