import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper"
import { Container } from "../../atoms/container/Container"
import { CustomSlider } from "../../atoms/slider/CustomSlider"
import { Text } from "../../atoms/text/Text"
import style from "./increaseRadius.module.scss"
interface IncreaseRadiusProps{
    value:any;
    handleIncreaseRadius:any;
    marks:any;

}
export const IncreaseRadius:React.FC<IncreaseRadiusProps>=({value,handleIncreaseRadius,marks})=>{
 
    return<>
    <BackgroundWrapper className={style.background}>
        <Container>            
        <Text className={style.title} text="Increase Search Radius"/>
        </Container>
        <Container className={style.slider}>     
            <CustomSlider min={5} max={100} value={value} marks={marks} handleChange={handleIncreaseRadius}/>       
        </Container>

    </BackgroundWrapper>
    </>

}