import { patientConditions } from "../../../utils/helper";
import { Container } from "../../atoms/container/Container";
import { CustomMultiSelect } from "../../atoms/multiSelect/CustomMultiSelect";

interface PatientCondSelectProps{
  getPatientCondition:any
}
export const PatientCondSelect:React.FC<PatientCondSelectProps> = ({getPatientCondition}) => {
    const handleGetPatientCond=(value:any)=>{
      getPatientCondition(value);
    };
  return(
  <>
    <Container>
        
      <CustomMultiSelect mode={"multiple"} allowClear={true} placeholder="Select Patient Condition" onChange={handleGetPatientCond} options={patientConditions} style={{width:"100%",height:"40px"}} />
    </Container>
  </>);
};
