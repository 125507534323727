import { typesOfAssistanceReq } from "../../../utils/helper";
import { RadioButtonGroup } from "../../atoms/radioButtonGroup/RadioButtonGroup";
import { Container } from "../../atoms/container/Container";
import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Text } from "../../atoms/text/Text";
import style from "./selectassistance.module.scss";

import { useSelectAssistance } from "../../../hooks/useSelectAssistance/UseSelectAssistance";

export const SelectAssistance = () => {
  const { selectedValues, handleOptionChange } = useSelectAssistance();
  //  console.log('selectedValues:', selectedValues);
  return (
    <>
      <BackgroundWrapper>
        <Container className={style.wrapper}>
          <Container>
            <Text text="Select Assistance" className={style.title} />
          </Container>
          <Container>
            <Text
              text="Type of assistance required ?"
              className={style.question}
            />
          </Container>

          <Container>
            <RadioButtonGroup
              column={4}
              gap={1}
              className={style.radio_button}
              type="checkbox"
              options={typesOfAssistanceReq}
              name={"radio"}
              selectedValue={selectedValues}
              onChange={handleOptionChange}
            />
          </Container>
        </Container>
      </BackgroundWrapper>
    </>
  );
};
