import { Component, ErrorInfo } from "react";
import "./errorboundary.scss"
import { Spin } from "antd";
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}
export class ErrorBoundary extends Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error caught by ErrorBoundary: ", error);
    console.error("Error component stack trace: ", errorInfo.componentStack);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }
  render() {
    console.log('hasError:', this.state.hasError)
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="error-boundary__graphic">
                    
            <img src="/images/error.jpg" alt="Error Graphic" />
          </div>
          <Spin  /> 
          <h1>Oops! Something went wrong.</h1>
          <p>
            We're sorry, but something went wrong. Please try refreshing the
            page or come back later.
          </p>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}
