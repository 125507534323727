import { Modal } from "antd";

interface CustomModalProps {
  isModalOpen: Boolean;
  isCloseModal: any;
  children: any;
  modalTitle:string
}

export const CustomModal:React.FC<CustomModalProps>= ({ isModalOpen, isCloseModal, children,modalTitle }: any) => {
  return (
    <>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onCancel={isCloseModal}
        footer={null}
      >
        {children}
      </Modal>
    </>
  );
};
