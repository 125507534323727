import { UseGetStoreData } from "../getStoreData/UseGetStoreData";

export const useEmergencyContact = () => {
  const { getTicketData } = UseGetStoreData();
  const getEmergencyContact = (emList: any) => {
   
    const MessageToList = emList?.map((el: any) => {
      return el.mobile || el.whatsappNumber;
    });
    return MessageToList?.length ? MessageToList : [];
  };
  const filterValidNumber = (waNum: any) => {
    const number= waNum.filter(
      (validNum: any) =>
        validNum !== undefined && validNum !== null && validNum !== ""
    );
    const res=new Set([...number]);
    let uniqNum=[...res]
    return uniqNum;
  };
  let emergencyContact = getEmergencyContact(
    getTicketData?.patient?.emergencyContacts
  );
  const getTicketRaisedByNum = getTicketData?.ticketRaisedBy?.mobile;
  const getPatientNum = getTicketData?.patient?.mobile;
  const pocNumber =getTicketData?.poc?.mobile
  //pocNum//
  //companyNum//for premise--flow;  
  emergencyContact.push(getTicketRaisedByNum, getPatientNum,pocNumber);
  const validContacts = filterValidNumber(emergencyContact);
  return {validContacts};
};
