import { useQuery } from "@tanstack/react-query";
import {
  generateSaSToken,
  getBeneficiaryData,
  getSingleBenDetails,
} from "../../pages/actions/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const UseFetchBenList = ({ ticketData }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    if (ticketData?.subscriber?.id) {
      // console.log("step1")
      return getBeneficiaryData(ticketData?.subscriber?.id, dispatch);
    } else if (ticketData?.ticketRaisedBy?.benId) {
      // console.log("step1")
      return getSingleBenDetails(ticketData?.ticketRaisedBy?.benId, dispatch);
    } else if (ticketData?.patient?.id) {
      return getSingleBenDetails(ticketData?.patient?.id, dispatch);
    } else {
      return [];
    }
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "benList",
      ticketData?.subscriber?.id || ticketData?.ticketRaisedBy?.benId,
    ],
    queryFn: () => fetchData(),
    enabled:
      !!ticketData?.subscriber?.id || !!ticketData?.ticketRaisedBy?.benId,
  });

  useEffect(() => {
    if (!ticketData || !ticketData?.ticketRaisedBy || !ticketData?.subscriber) {
      //  return navigate("/")
    }
    generateSaSToken();
  }, [navigate, ticketData]);

  return { data, isLoading, isError };
};
