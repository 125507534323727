import React from "react";
interface RadioBtnProps {
  id: string;
  name: string;
  isSelected: any;
  onChange: any;
  className: any;
}

export const RadioButton: React.FC<RadioBtnProps> = ({
  id,
  name,
  isSelected,
  onChange,
  className = "",
}) => {
  return (
    <label htmlFor={id}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={isSelected}
        onChange={onChange}
        className={`radio-button ${className}`}
      />
    </label>
  );
};

