// @ts-ignore
import { Offline, Online } from 'react-detect-offline';

export const InternatStatus=({children}:any)=>{
    return<>
     <Online>
        {children}
      </Online>
      
      <Offline>
        <div style={{ textAlign: 'center', backgroundColor: '#ffcc00', color: '#333', padding: '20px' }}>
          <h2>No Internet Connection</h2>
          <p>Please check your network and try again.</p>          
        </div>
      </Offline>
    </>
}