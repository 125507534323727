import React from "react";
import style from "./customSteps.module.scss";
import { UseGetStoreData } from "../../../hooks/getStoreData/UseGetStoreData";
import { ShowAlert } from "../../../utils/showAlert";
import useTrackAgentNotes from "../../../hooks/useTrackAgentNotes/useTrackAgentNotes";

interface CustomStepsProps {
  totalSteps: number;
  currentStep: any[];
  setCurrentStep: any;
}
const value: any = {
  "1": "Ambulance Dispatched",
  "2": "Pickup Location",
  "3": "Pickup Patient",
  "4": "Patient Dropped",
};

const errorMsg: any = {
  "1": "Ambulance dispatched",
  "2": "Ambulance arrived pickup location",
  "3": "Patient picked",
  "4": "Patient dropped",
};
export const CustomSteps: React.FC<CustomStepsProps> = ({
  totalSteps,
  currentStep,
  setCurrentStep,
}) => {
  const {getTrackStatus}=useTrackAgentNotes()
  const { getTicketData } = UseGetStoreData();
  const handleChangeSteps = (step: number) => {
    if (!getTrackStatus()[step - 1]) {
      if (
        getTicketData.ticketStatus !== "Completed" &&
        getTicketData.ticketStatus !== "cancelled"
      ) {
        setCurrentStep(step);
      } else {
        ShowAlert("warning", "Ticket Already Completed");
      }
    } else {
      ShowAlert("warning", `Already ${errorMsg[step]}`);
    }
  };

  return (
    <div className={style.steps}>
      {Array.from({ length: totalSteps }, (_, index) => {
        const stepNumber = index + 1;
        const completed = currentStep[stepNumber - 1] === 1; // Check if step is completed
        return (
          <div
            key={stepNumber}
            onClick={() => handleChangeSteps(stepNumber)}
            className={`${style.step} ${completed ? style.completed : ""}`}
          >
            <div className={style.indicator}></div>
            <div className={completed ? style.selected_label : style.label}>
            {value[stepNumber]}
            </div>
          </div>
        );
      })}
      <div className={style.line}></div>
    </div>
  );
};
