import { useDispatch } from "react-redux"
import { useCustomNavigate } from "../../../hooks/useCustomNavigate"
import { adminUserLogoutAction } from "../../../pages/actions/actions"
import { Container } from "../../atoms/container/Container"
import { Text } from "../../atoms/text/Text"
import { Icon } from "../../icon/Icon"
import style from "./listitems.module.scss"
import { ticketDataSuccess } from "../../../redux/ticketSlice/ticketSlice"
import { ticketUpdateClear } from "../../../redux/ticketUpdateSlice/ticketUpdateSlice"
import { benDataSuccess } from "../../../redux/beneficiarySlice/beneficiarySlice"
import {  logout } from "../../../redux/userSlice/userSlice"
interface ListMoleculeProps{
    label:string
    src:string,
    className:any
    path?:any
    closeModal?:any
}
interface ListItemsProp{
    data:any,
    className:any,
    closeModal?:any
}
 const ListMolecule:React.FC<ListMoleculeProps>=({label,src,className,path,closeModal})=>{
    const {jumpTo}=useCustomNavigate()
    const disPatch=useDispatch();
    const handleJump=async()=>{
        if(path!=="logout"){
  
            jumpTo(path)          
        }else{
            await adminUserLogoutAction()
            jumpTo("/login")
            disPatch(ticketDataSuccess([]))
            disPatch(ticketUpdateClear());
            disPatch(benDataSuccess([]));
            disPatch(logout())
         
        }
        if(closeModal){

            closeModal()
        }
    }
    return(
    <>
    <Container onClick={handleJump}  className={`List ${style.list_item} ${className}`}>
        <Icon src={src} alt={label} />
        <Text text={label} className={style.agent_name} />
    </Container>
    </>
    )
}

export const ListItems:React.FC<ListItemsProp>=({data,className,closeModal})=>{
    return <>
        {data&&data.map((item:any,index:number)=>{
            return <ListMolecule key={index+1}closeModal={closeModal} path={item.path} label={item.label} src={item.src} className={className} />
        })}
    </>
}




