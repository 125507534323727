import { Container } from "../../atoms/container/Container";
import { Input } from "../../atoms/input/Input";
import CustomSelect from "../../atoms/select/CustomSelect";
import style from "./benSearchByCompany.module.scss";
import { Button } from "../../atoms/button/Button";
import { useBenSearchCompany } from "../../../hooks/useBenSearchCompany/UseBenSearchCompany";

interface BenSearchByCompanyProps {
  handleSearchByCompany: any;
}
export const BenSearchByCompany: React.FC<BenSearchByCompanyProps> = ({
  handleSearchByCompany,
}) => {
  const {
    benName,
    handleCatchBenName,
    companyId,
    handleSelectCompanyName,
    handleSearchBenByCompany,
    getCompanyList,
  } = useBenSearchCompany({ handleSearchByCompany });
  return (
    <>
      <Container className={style.wrapper}>
        <Container className={style.container}>
          <Input
            placeholder="Enter Ben Name"
            className={style.input}
            value={benName}
            searchValue={handleCatchBenName}
          />
        </Container>
        {getCompanyList?.length && (
          <Container className={style.container}>
            <CustomSelect
              value={companyId}
              placeHolder="Select Company"
              width={"100%"}
              height={"100%"}
              className={style.select}
              options={getCompanyList}
              onChange={handleSelectCompanyName}
            />
          </Container>
        )}
        <Container className={style.container}>
          <Button
            onClick={handleSearchBenByCompany}
            text="Search"
            className={style.button}
          />
        </Container>
      </Container>
    </>
  );
};
