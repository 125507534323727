import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Button } from "../../atoms/button/Button";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { TextArea } from "../../atoms/textArea/TextArea";
import style from "./agentNotes.module.scss";
import { useAgentNotes } from "../../../hooks/useAgentNotes/UseAgentNotes";

export const AgentNotes = () => {
  const { agentNotes, setAgentNotes, storeAgentNotes, oldAgentNotes } =
    useAgentNotes();
  return (
    <>
      <BackgroundWrapper className={style.background}>
        <Container className={style.space}>
          <Container>
            <Text text="Agent Notes" className={style.heading} />
          </Container>
          <Container>
            <TextArea
              className={style.input}
              value={agentNotes}
              setValues={setAgentNotes}
              placeHolder="Write here..."
            />
          </Container>
          <Container className={style.btn_wrapper}>
            <Button
              onClick={storeAgentNotes}
              text="Save"
              className={style.button}
            />
          </Container>
          <Container className={style.space_vert}>
            <Text text="Previous Note:" className={style.heading} />
            {oldAgentNotes?.length ? (
              oldAgentNotes?.map((agent: any) => (
                <Text
                  text={`${agent.message},${agent.date}`}
                  className={style.agentNotes}
                />
              ))
            ) : (
              <p className={style.no_data}>No Data Found</p>
            )}
          </Container>
        </Container>
      </BackgroundWrapper>
    </>
  );
};
