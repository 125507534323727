import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import style from "./selectPoc.module.scss";
import { BeneficiaryGrp } from "../benficiaryGrp/BeneficiaryGrp";
import { Button } from "../../atoms/button/Button";
import POCForm from "../getPocData/POCForm";
import { useSelectPoc } from "../../../hooks/useSelectPoc/UseSelectPoc";
import { usePocForm } from "../../../hooks/usePocForm/UsePocForm";

export const SelectPoc = () => {
  const {emContacts,handleSelectPoc,selectedPoc,openPocForm,handlePocForm}=useSelectPoc();
  const {onFinish,onFinishFailed,validateMessages,pocName,pocNumber,handlePocInputChange}=usePocForm(handlePocForm);
  // console.log('pocNumber---selectPoc:', pocNumber)
  return (
    <>
      <BackgroundWrapper>
        <Container className={style.wrapper}>
          <Container>
            <Text text="Point Of Contact" className={style.title} />
          </Container>
          {emContacts.length ? (
            <>
              <BeneficiaryGrp
                column={3}
                gap={1}
                className={""}
                options={emContacts}
                selectedValue={selectedPoc}
                onChange={handleSelectPoc}
              />
            </>
          ) : (
            <></>
          )}
        </Container>
        <Container>
            <Button className={style.btn} text={openPocForm?"Close":"Add Poc"} onClick={handlePocForm}/>
        </Container>
        {
            openPocForm&&<POCForm onFinish={onFinish} onFinishFailed={onFinishFailed} validateMessages={validateMessages} pocName={pocName} pocNumber={pocNumber} handlePocInputChange={handlePocInputChange}/>
        }
      </BackgroundWrapper>
    </>
  );
};
