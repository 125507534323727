import style from "../../../pages/ticketHandling/ticketHandling.module.scss";
import { Container } from "../../atoms/container/Container";
import { SelectOption } from "../selectOption/SelectOption";

interface EmergencyCheckProps {
  ticketData: any;
  children: any;
}

export const EmergencyCheckWrapper: React.FC<EmergencyCheckProps> = ({
  ticketData,
  children,
}) => {
  console.log("ticketData:", ticketData);
  if (
    ticketData?.currentMedicalStatusResponse?.isItEmergency.toLowerCase() !==
    "no"
  ) {
    return children;
  } else {
    return (
      <div>
        <Container className={style.emHandle}>
          <SelectOption />
        </Container>
      </div>
    );
  }
};
