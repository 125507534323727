import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { benDataTransformer, capitalizeFirstLetter, errorFormate, payloadBuilderForObject } from "../../utils/helper";
import { changeDateFormate } from "../../utils/calculateAge";
import { ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";
import { getBenByCompanyAndBenName, getBeneficiaryData, getBeneficiaryDataByMobile } from "../../pages/actions/actions";
import { ShowAlert } from "../../utils/showAlert";

export const useBeneficiaryData = () => {
  const dispatch=useDispatch()
  const benData = useSelector((state: any) => state?.beneficiary?.benData);
  const [selectedSearchOption, setSelectedSearchOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionSearchRes, setSelectedOptionSearchRes] = useState("");
  const[searchByMobileRes,setSearchByMobileRes]=useState([])
  const [searchBenRes,setSearchBenRes]=useState<any[]>([]);
   const [benOptions,setBenOptions]=useState<any>([]);
//default benListChange
  const handleOptionChange = (event: any) => {
    // console.log('event:', event.target.value);
    setSelectedOption(event.target.value);
    setSelectedOptionSearchRes("");
    const benIdSplit = event.target.value.split(",");
    findSelectedPatient({benId:benIdSplit[1],benList:benData})
   };
   //search by number or company changes//
  const handleSearchResChange = async(event: any) => {
    setSelectedOption("");
    setSelectedOptionSearchRes(event.target.value);
    const subscriberId = event.target.value.split(",");
    // console.log('subscriberId:', subscriberId);
    let action="updateTicket";
    await getBeneficiaryData(subscriberId[0], dispatch,action);
    findSelectedPatient({benId:subscriberId[1],benList:searchBenRes});    
  };
  const findSelectedPatient=({benId,benList}:any)=>{
    // console.log('benSubId:', benId);
    const selectedBen = benList.find((ben: any) => ben._id === benId);
    // console.log('selectedBen:', selectedBen)
    buildPatientPayload(selectedBen);
  };
  const transformEmergencyContact = (emContact: any) => {
    return emContact.reduce((emArr: any, data: any) => {
      emArr.push({
        fullName: data.fullName,
        mobile: data?.mobile?.number?.toString(),
        ...(data?.whatsappMobile?.number && {
          whatsappNumber: data.whatsappMobile.number.toString(),
        }),
      });
      return emArr;
    }, []);
  };

  const buildPatientPayload = (selectedBen: any) => {
    const fields = [
      "fullName",
      "gender",
      "mobile",
      "whatsappMobile",
      "email",
      "dateOfBirth",
      "languages",
    ];
    const getPatientPartialPayload = payloadBuilderForObject({
      fields,
      obj: selectedBen.personalInfo,
    });
    let patientPayload = {
      ...getPatientPartialPayload,
      mobile: getPatientPartialPayload?.mobile?.number?.toString() ,
      whatsappMobile:
        getPatientPartialPayload?.whatsappMobile?.number?.toString(),
      address: selectedBen?.address || [],
      id: selectedBen._id,
      ...(getPatientPartialPayload?.gender
        ? { sex: capitalizeFirstLetter(getPatientPartialPayload?.gender)}
        : {}),
      emergencyContacts: transformEmergencyContact(
        selectedBen.emergencyContacts || []
      ),
      ...(getPatientPartialPayload?.dateOfBirth
        ? { age: changeDateFormate(getPatientPartialPayload?.dateOfBirth) }
        : { age: "N/A" }),
    };
    delete patientPayload.gender;
    delete patientPayload.dateOfBirth;
    dispatch(ticketUpdateStatusSuccess({patient:patientPayload}));
  };
  const handleSearchOptionChange = (event: any) => {
    setSelectedSearchOption(event.target.value);
    handleClearBenData();    
  };

   
  // const benOptions=benDataTransformer(benData);

  const benFindOptions = [
    {
      value: "By Mobile Number",
      label: "By Mobile Number",
    },
    {
      value: "ByCompanyName",
      label: "By Company Name & Beneficiary Name",
    },
  ];
  const handleSearchByMobile=async(mobileNumber:string)=>{
    try {
        const searchPhoneNumber = await getBeneficiaryDataByMobile(mobileNumber,dispatch);
        // console.log('searchPhoneNumber:', searchPhoneNumber);
        setSearchBenRes(searchPhoneNumber);
        let res=benDataTransformer(searchPhoneNumber);
        setSearchByMobileRes(res);
        setSelectedOption("");
      } catch (error: any) {
        ShowAlert("warning",errorFormate({e:error,service:"get ben By Mobile"}),
         
        );
        setSearchBenRes([]);
        setSearchByMobileRes([]);
      }
  }

  const handleSearchByCompany=async(benName:string,companyId:string)=>{
    const payload = {
        benName,
        
      };
      try {
        const getBenData = await getBenByCompanyAndBenName(payload);
        if(getBenData.length){
            // console.log('getBenData:', getBenData);
        setSearchBenRes(getBenData);  
        let res=benDataTransformer(getBenData);
        setSearchByMobileRes(res);        
        }
      } catch (e) {
        ShowAlert("warning",errorFormate({e:e,service:"get ben By Company"}));
        setSearchBenRes([]);
        setSearchByMobileRes([])
      }
  }

  const handleClearBenData=()=>{
    setSearchBenRes([]);
    setSearchByMobileRes([]);
  };

  useEffect(()=>{
    const benOptions=benDataTransformer(benData);
    setBenOptions(benOptions);

  },[benData])
  // console.log('benOptions:', benData);
  
  return {
    benOptions,
    benFindOptions,
    selectedOption,
    handleOptionChange,
    handleSearchOptionChange,
    selectedSearchOption,
    handleSearchByMobile,
    searchBenRes,
    handleSearchResChange,
    selectedOptionSearchRes,
    searchByMobileRes,
    handleSearchByCompany,
    handleClearBenData
  };
};
