import { useState } from "react";
import { useDispatch } from "react-redux";
import { ticketUpdateCurrentMedicalStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";

export const useMedicalCondition=()=>{
    const dispatch = useDispatch();
    const [responses, setResponses] = useState<any>({
      isAmbulanceRequired: "Yes",
      isDoctorConsultationRequired: "Yes",
      isPatientConscious: "Yes",
    });
    const handleOptionChange = (name: string, value: string) => {
      setResponses((prevResponses: any) => {
        const updatedResponses = {
          ...prevResponses,
          [name]: value,
        };
  
        if (name === "isAmbulanceRequired" && value === "Yes") {
          updatedResponses["isDoctorConsultationRequired"] = "Yes";
        }
  
        dispatch(ticketUpdateCurrentMedicalStatusSuccess(updatedResponses))
        return updatedResponses;
      });
    }
    return {
        responses,handleOptionChange
    }

}