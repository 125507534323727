// logger.js
const originalLog = console.log;
const originalError = console.error;
const originalWarn = console.warn;

const logger = {
  log: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      originalLog(...args);
    }
  },
  error: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      originalError(...args);
    }
  },
  warn: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      originalWarn(...args);
    }
  },
};

export default logger;

  