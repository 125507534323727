import { Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { OutboundCallHandling } from "../pages/outboundCallHandling/OutboundCallHandling";
import { ResetPassword } from "../pages/resetPassword/ResetPassword";
import { Tickets } from "../pages/tickets/Tickets";
import { PrivateRoute } from "./PrivateRoute";
import { Logistics } from "../pages/logistics/Logistics";
import { TicketHandlingPart2 } from "../pages/ticketHandlingPart2/TicketHandlingPart2";
import { Layout } from "../components/molecules/layout/Layout";
import { TicketCancel } from "../pages/ticketCancel/TicketCancel";
import TicketHandlingPart1 from "../pages/ticketHandling/TicketHandlingPart1";
import { DemoTicket } from "../components/organisms/demoTicket/DemoTicket";

export const routes = [
  {
    path: "/",
    component: (
      // <PrivateRoute>
        <Layout>
          <Tickets />
        </Layout>
      // </PrivateRoute>
    ),
  },
  {
    path: "/outboundCall",
    component: (
      <PrivateRoute>
        <Layout>
          <OutboundCallHandling />
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/handleEmergency/:id",
    component: (
      <PrivateRoute>
        <Layout>
          <TicketHandlingPart1 />
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/cancelled-ticket/:id",
    component: (
      <PrivateRoute>
        <Layout>
          <TicketCancel />
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/demo-general-enquiry/:id",
    component: (
      <PrivateRoute>
        <Layout>
          <DemoTicket />
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/resetPswd",
    component: (
      <PrivateRoute>
        <ResetPassword />
      </PrivateRoute>
    ),
  },
  {
    path: "/logistics",
    component: (
      <PrivateRoute>
        <Layout>
          <Logistics />
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/doctor-consult-ambulance",
    component: (
      <PrivateRoute>
        <Layout>
          <TicketHandlingPart2 />
        </Layout>
      </PrivateRoute>
    ),
  },

  {
    path: "/login",
    component: (
      <Layout>
        <Login />
      </Layout>
    ),
  },
  {
    path: "*",
    component: (
      <Layout>
       <>Page Not Found</>
      </Layout>
    ),
  },
];

export const MainRoutes = () => {
  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route key={route.path} path={route.path} element={route.component} />
        );
      })}
    </Routes>
  );
};
