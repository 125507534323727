
import style from "./textArea.module.scss"
interface TextAreaProps{
    placeHolder:string
    value:string;
    setValues:any;
    className:any;
}

export const TextArea:React.FC<TextAreaProps>=({placeHolder,value,setValues,className})=>{
    const handleChange=(e:any)=>{
        let value=e.target.value.trim()
        if(value.length>0){
            setValues(e.target.value)
        }else{
            setValues("");        
        }
    }
    return<>
   <textarea
        placeholder={placeHolder}
        onChange={handleChange}
        className={`${style.input} ${className}`}
        value={value}
      />
    </>
}