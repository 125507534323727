import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import { TicketInfo } from "./TicketInfo";
import style from "./ticketBasicDetails.module.scss";
import { useTicketBasicWrapper } from "../../../hooks/useTicketBasicWrapper/UseTicketBasicWrapper";
interface TicketBasicDetailsProps {
  title: string;
  leftSideListData: any[];
  rightSideListData: any[];
  handleEditPoc?: any;
  component?: any;
}
export const TicketBasicDetails: React.FC<TicketBasicDetailsProps> = ({
  title,
  leftSideListData,
  rightSideListData,
  handleEditPoc,
  component,
}) => {
  const { handleOpenTab, isOpenCallDetails } = useTicketBasicWrapper();
  return (
    <div>
      {title === "Call Details" ? (
        <Container>
          <div onClick={handleOpenTab} className={style.head_flex}>
            <Text className={`${style.title} ${style.color}`} text={title} />
            <div>
              <Icon
                src={
                  isOpenCallDetails
                    ? "/images/chevron-up.png"
                    : "/images/chevron-down.png"
                }
                alt="chevron_icon"
              />
            </div>
          </div>
          <div onClick={handleOpenTab} className={style.border}></div>
        </Container>
      ) : (
        <Container>
          <Text className={style.title} text={title} />
        </Container>
      )}
      {((title === "Call Details" && isOpenCallDetails) ||
        title !== "Call Details") && (
        <Container className={style.cardWrapper}>
          <Container className={style.card}>
            {leftSideListData?.map((data, index) => (
              <TicketInfo
                component={component}
                className={style.ticket_card}
                key={index}
                label={data.label}
                value={data.value}
              />
            ))}
          </Container>
          <Container className={style.card}>
            {rightSideListData?.map((data, index) => (
              <TicketInfo
                component={component}
                handleEditPoc={handleEditPoc}
                className={style.ticket_card2}
                key={index}
                label={data.label}
                value={data.value}
                name={data?.name}
              />
            ))}
          </Container>
        </Container>
      )}
    </div>
  );
};
