import { UseGetStoreData } from "../../../hooks/getStoreData/UseGetStoreData";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import { Flex } from "../flex/Flex";
import style from "./topcard.module.scss";

interface TopCardProps {
  className?: string;
  title: string;
  icon: string;
  onClick?: () => void;
  children?: React.ReactNode;
}
export const TopCard: React.FC<TopCardProps> = ({
  className,
  title,
  icon,
  onClick,
}) => {
  const {getTicketData}=UseGetStoreData()
  return (
    <Container className={style.container}>
      <Container className={style.wrapper}>
        <div className={style.flex_wrapper}>
          <Flex className={style.flex_size}>
            <div style={{ width: "3rem", height: "3rem" }} onClick={onClick}>
              <Icon className={style.icon} src={icon} alt="PocoLogo" />
            </div>
            <div>
              <Text className={style.title} text={title} />
              <p className={style.ticket_status_mini}>{getTicketData?.ticketStatus||"N/A"}</p>
            </div>
          </Flex>
          <div className={style.visible}>
            <p className={style.ticket_status}>{getTicketData?.ticketStatus||"N/A"}</p>
          </div>
        </div>
      </Container>
    </Container>
  );
};
