import { Operation } from "../../../pages/operation/Operation";

interface SwitchTabsProps {
  currentTab: string;
  children: any;
}
export const SwitchTabs: React.FC<SwitchTabsProps> = ({
  currentTab,
  children,
}) => {
  if (currentTab === "operation") {
    return <Operation />;
  } else {
    return children;
  }
};
