import { Container } from "../../components/atoms/container/Container";
import { Text } from "../../components/atoms/text/Text";
import { Flex } from "../../components/molecules/flex/Flex";
import { AssessmentResult } from "../../components/organisms/assessmentResult/AssessmentResult";
import { TicketBasicDetWrapper } from "../../components/organisms/ticketBasicDetWrapper/TicketBasicDetWrapper";
import style from "./ticketHandlingPart2.module.scss";
import { useTicketHandlingPart2 } from "../../hooks/useTicketHandlingPart2/UseTicketHandlingPart2";
import { SwitchTabs } from "../../components/organisms/switchTabs/SwitchTabs";
import { LogisticsAmbulanceDoctorTab } from "../../components/organisms/logisticsAmbulanceDoctorTab/LogisticsAmbulanceDoctorTab";

export const TicketHandlingPart2 = () => {
  const {
    handleCloseTicket,
    getPatientDropLocation,
    getPatientPickupAddress,
    getTicketStatus,
    handleTabSwitch,
    showOperationTab,
    pickupLocationLabel
  } = useTicketHandlingPart2();
  console.log(
    "showOperationTab:",
    showOperationTab === "logistics",
    showOperationTab === "operation"
  );
  return (
    <>
      <TicketBasicDetWrapper>
        <Container>
          <AssessmentResult />
        </Container>
        <Container className={style.logistics_wrapper}>
          <Flex className={style.flex}>
            <div    className={style.operation_wrapper}onClick={() => handleTabSwitch("logistics")}>
              <Text
                className={
                  showOperationTab === "logistics"
                    ? style.tab_heading
                    : style.heading_operation
                }
                text="Logistics"
              />
            </div>
            <div
              onClick={() => handleTabSwitch("operation")}
              className={style.operation_wrapper}
            >
              <Text
                className={
                  showOperationTab === "operation"
                    ? style.tab_heading
                    : style.heading_operation
                }
                text="Operations"
              />
            </div>
          </Flex>
          <div className={style.border}></div>
          <SwitchTabs currentTab={showOperationTab}>
          <LogisticsAmbulanceDoctorTab
          pickupLocationLabel={pickupLocationLabel}
            getTicketStatus={getTicketStatus}
            handleCloseTicket={handleCloseTicket}
            getPatientPickupAddress={getPatientPickupAddress}
            getPatientDropLocation={getPatientDropLocation}
          />
        </SwitchTabs>
        </Container>
        {/* ///step-2// */}
        {/* //doctor-consult && ambulance// */}
        
      </TicketBasicDetWrapper>
    </>
  );
};
