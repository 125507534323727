import { BackgroundWrapper } from "../../components/atoms/backgroundWrapper/BackgroundWrapper";
import { Button } from "../../components/atoms/button/Button";
import { Container } from "../../components/atoms/container/Container";
import { CallLogsCard } from "../../components/molecules/callLogsCard/CallLogsCard";
import { InsuranceCard } from "../../components/molecules/insuranceCard/InsuranceCard";
import style from "./operation.module.scss";
import { AgentNotes } from "../../components/molecules/agentNotes/AgentNotes";
import { useOperation } from "../../hooks/useOperation/UseOperation";

export const Operation = () => {
  const { data, callDetails, handleCallLogModal } = useOperation();
  return (
    <>
    
        <Container className={style.logistics_wrapper}>         
          <Container>
            <BackgroundWrapper className={style.background}>
              <div className={style.container}>
                <Container className={style.step}>
                  <Button text="Completed :3/3" className={style.step_button} />
                </Container>
                {/* <Container>InsuranceCard</Container> */}
              </div>
              <div className={style.box}>
                <div>
                  <InsuranceCard data={data?.insurancePolicies || []} />
                </div>
                <Container>
                  <CallLogsCard
                    data={callDetails || []}
                    icon={"/images/chevron-up.png"}
                    handleCallLogModal={handleCallLogModal}
                  />
                </Container>
                <div>
                  <AgentNotes />
                </div>
              </div>
            </BackgroundWrapper>
          </Container>
          {/* ////callLogs */}
        </Container>
        {/* /////operations */}
    
    </>
  );
};
