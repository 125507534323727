import { useDispatch } from "react-redux";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { ticketUpdateById } from "../../http";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { ShowAlert } from "../../utils/showAlert";
import { useNavigate, useParams } from "react-router-dom";

export const useCancelTicket=()=>{
    const {id}=useParams();
    const disPatch = useDispatch();
    const navigate=useNavigate();
  const { getTicketUpdateData, getTicketData } = UseGetStoreData();
  const handleCancelTicket = async () => {
    const payload = {
      ...getTicketUpdateData,
      ticketStatus: "Cancelled",
    };
    const response = await ticketUpdateById(getTicketData._id, payload);
    if (response) {
      disPatch(ticketDataSuccess(response.data));
      ShowAlert("success", "Ticket Cancelled");
      navigate(`/cancelled-ticket/${id}`)
    }
  };

  return {
    handleCancelTicket
  }

}