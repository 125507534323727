import { connectCall } from "../../pages/actions/actions";
import { ShowAlert } from "../../utils/showAlert";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData"

export const useAgentManualCall = () => {
    const { getTicketData } = UseGetStoreData();
    const handleConnectCall = async (phNum: string) => {
        const payload = {
        customerNumber: phNum,
        };
        const res = await connectCall(payload, getTicketData._id);
        return ShowAlert("success", res?.message);
    };
    return {
        handleConnectCall
    }
}