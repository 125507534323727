import { useEffect, useState } from "react";
import { handleEmergency, updateTicket } from "../../pages/actions/actions";
import { sseRegister } from "../../http";
import { useNavigate } from "react-router-dom";
import ambulanceSound from "../../Audio/ambulanceSound.mp3";

export const useHandleSSE = () => {
  const [newEmergencyData, setNewEmergencyData] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [audio] = useState(new Audio(ambulanceSound));

  const playAudio = () => {
    audio.play();
  };

  const stopAudio = () => {
    audio.pause();
    audio.currentTime = 0;
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
    stopAudio();
  };

  const handleNewEmergencyCase = (data: any) => {
    if (data.ring) {
      playAudio();
    }
    setIsModalVisible(true);
    setNewEmergencyData(data);
  };

  const handleSseMessage = (data: any) => {
    if (data.tag === "NEW_EMERGENCY_CASE") {
      handleNewEmergencyCase(data);
    }

    if (data.tag === "EMERGENCY_HANDLED") {
      handleModalClose();
    }
  };
  const playAudioWithPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      console.error("Failed to get audio permission:", error);
    }
  };

  const closeModalRequestFromAllAgents = (id: string) => {
    try {
      handleEmergency(id);
    } catch (e) {
      return alert("some technical issue");
    }
  };
  useEffect(() => {
    playAudioWithPermission();
    const eventSourceOptions = {
      withCredentials: true, // Include credentials (cookies) with the request
    };
    const source = new EventSource(sseRegister, eventSourceOptions);
    source.addEventListener("NewTicket", (event) => {});
    source.addEventListener(
      "open",
      () => {
        console.log("SSE opened!");
      },
      false
    );

    source.addEventListener(
      "message",
      (e) => {
        handleSseMessage(JSON.parse(e.data));
      },
      false
    );

    source.addEventListener(
      "error",
      (e: any) => {
        // console.error("Error: ");
      },
      false
    );
    return () => {
      source.close();
    };
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isModalVisible,
    handleModalClose,
    closeModalRequestFromAllAgents,
    newEmergencyData,
    navigate,
    updateTicket,
  };
};
