import style from "./radioButton.module.scss";
interface RadioButtonGroupProps {
  options: any;
  name: string;
  selectedValue: any;
  type: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: any;
  disable?: boolean;
  componentName?: any;
  column: number;
  gap: any;
}
export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  options,
  name,
  selectedValue,
  onChange,
  type,
  className,
  disable,
  column,
  gap,
}) => {
  return (
    <form
      className={`${style.group_Btn} ${className}`}
      style={{ "--columns": column, "--gap": gap } as React.CSSProperties}
    >
      {options.map((option: any) => (
        <label className={style.label} key={option.value}>
          <input
            type={type}
            name={name}
            value={option.value}
            checked={
              type === "radio"
                ? selectedValue === option.value
                : selectedValue[option.value]
            }
            onChange={onChange}
            className={style.radio}
            disabled={disable}
          />
          {option.icon && (
            <img src={option.icon} alt={option.label} className={style.icon} />
          )}
          {option.label}
        </label>
      ))}
    </form>
  );
};
