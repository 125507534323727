import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  getAllCompany,
} from "../../pages/actions/actions";

export const useBenSearchCompany = ({ handleSearchByCompany }: any) => {
  const [benName, setBenName] = useState("");
  //eslint-disable-next-line
  const [companyId, setCompanyId] = useState("");
  const handleSelectCompanyName = (selectedCompany: string) => {
    // console.log('selectedCompany:', selectedCompany);
    setCompanyId(selectedCompany);
  };

  const handleCatchBenName = (value: string) => {
    const updatedValue = value.trim();
    setBenName(updatedValue);
  };

  const handleSearchBenByCompany = async () => {
    handleSearchByCompany(benName, companyId);
  };

  const {
    data: getCompanyList,

  } = useQuery({
    queryKey: ["getAllOffices"],
    queryFn: () => getAllCompany(),
    select: (data) =>
      data.map((company: any) => ({
        value: company._id,
        label: company.companyName,
      })),
  });

  return {
    handleSelectCompanyName,
    handleCatchBenName,
    handleSearchBenByCompany,
    benName,
    companyId,
    getCompanyList,
  };
};
