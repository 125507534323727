
import { useAgentManualCall } from "../../../hooks/useAgentManualCall/UseAgentManualCall";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import style from "./phoneNumber.module.scss";

interface PhoneNumberCardProps {
  number: string;
  icon?: string;
  componentName: string;
  pocName?:string;
  name?:any;
  handleEditPoc?:any
}

export const PhoneNumberCard: React.FC<PhoneNumberCardProps> = ({
  number,
  icon,
  componentName,
  handleEditPoc,
  name
}) => {
  const {handleConnectCall}=useAgentManualCall();
  return (

    <>
      <div className={style.poc_Card} >
       {componentName ==="POC"&& 
       <Container>
          <Text text={name} className={style.value} />
        </Container>}
        <div className={style.flex} onClick={()=>handleConnectCall(number)}>
          <Container className={componentName==="doctorCard"?style.doctor_icon:style.icon}>
            <Icon
              className={componentName==="doctorCard"?style.doctor_img:style.img}
              src={"/images/phone.png"}
              alt="phoneIcon"
            />
          </Container>
          <Container>
            <Text text={number} className={componentName==="doctorCard"?style.doctorCard:style.value} />
          </Container>
        </div>
        {componentName ==="POC"&& <div onClick={handleEditPoc}>
          <Icon className={style.edit_img}  src={"/images/edit.png"} alt="EditIcon"/>
        </div>}
      </div>
    </>
  );
};
