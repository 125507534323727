import { Button } from "../../atoms/button/Button";
import { Container } from "../../atoms/container/Container";
import { IncreaseRadius } from "../increaseRadius/IncreaseRadius";
import { NearByHospital } from "../nearbyHospital/NearByHospital";
import style from "../../../pages/logistics/logistics.module.scss";
import { PickupLocation } from "../pickupLocation/PickupLocation";
import { SelectedAddressComponent } from "../../molecules/selectedAddress/SelectedAdddress";
import PreferredHospital from "../preferredHospital/PreferredHospital";

interface LogisticsAddressTabProps {
  isAddressSelected: boolean;
  selectedAddress: any;
  setSelectedAddress: any;
  handleAddressSelection: any;
  handleEditSelectedAddress: any;
  isHospitalSelected: boolean;
  selectedHospital: any;
  handleEditHospital: any;
  renderHospitalUi: boolean;
  value: any;
  handleIncreaseRadius: any;
  marks: any;
  patientAddress: any;
  hospitalLists: any;
  handleSelectHospital: any;
  handleStartEmergency: any;
  component?: string;
}

export const LogisticsAddressTab: React.FC<LogisticsAddressTabProps> = ({
  isAddressSelected,
  selectedAddress,
  setSelectedAddress,
  handleAddressSelection,
  handleEditSelectedAddress,
  isHospitalSelected,
  selectedHospital,
  handleEditHospital,
  renderHospitalUi,
  value,
  handleIncreaseRadius,
  marks,
  patientAddress,
  hospitalLists,
  handleSelectHospital,
  handleStartEmergency,
  component,
}) => {
  console.log(
    "selectedAddress######################################:",
    selectedHospital
    
  );
  return (
    <>
      <Container className={style.logistics_box}>
        {!isAddressSelected ? (
          <Container className={style.logistics_container}>
            <PickupLocation
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              handleAddressSelection={handleAddressSelection}
            />
          </Container>
        ) : (
          ""
        )}

        {isAddressSelected ? (
          <Container className={style.logistics_container}>
            <div >
              <SelectedAddressComponent
                addressIcon="/images/home.png"
                addressBadge="saved"
                title={"Selected Pickup Location"}
                addressSelected={selectedAddress}
                renderSelectedAddressUi={handleEditSelectedAddress}
              />
            </div>
            
          </Container>
        ) : (
          ""
        )}
        {(isAddressSelected&&renderHospitalUi)&&<div style={{ minWidth: "49%", height: "100%" }}>
              <PreferredHospital
                pickupLocation={patientAddress}
                nearByHospital={selectedAddress?.preferredHospital || []}
                handleAddressSelection={handleSelectHospital}
                selectedHospital={selectedHospital}
                component={component}
              />
            </div>}

        {isAddressSelected && isHospitalSelected ? (
          <Container className={style.logistics_container}>
            <SelectedAddressComponent
              addressIcon="/images/hospital.png"
              addressBadge="Preferred"
              title={"Selected Drop Location"}
              addressSelected={selectedHospital}
              renderSelectedAddressUi={handleEditHospital}
            />
          </Container>
        ) : (
          <></>
        )}
        {renderHospitalUi ? (
          <Container>
            <IncreaseRadius
              value={value}
              handleIncreaseRadius={handleIncreaseRadius}
              marks={marks}
            />
          </Container>
        ) : (
          ""
        )}
        {renderHospitalUi ? (
          <Container>
            <NearByHospital
              pickupLocation={patientAddress}
              nearByHospital={hospitalLists}
              handleAddressSelection={handleSelectHospital}
              selectedHospital={selectedHospital}
              component={component}
              km={value}
            />
          </Container>
        ) : (
          <></>
        )}
        {!component && isAddressSelected && isHospitalSelected && (
          <Container>
            <Button
              className={style.button}
              text="Start Emergency"
              onClick={handleStartEmergency}
            />
          </Container>
        )}
      </Container>
    </>
  );
};
