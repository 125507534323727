import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { PhoneNumberCard } from "../../molecules/phoneNumberCard/PhoneNumberCard";
import style from "./ticketBasicDetails.module.scss";
interface TicketInfoProps {
  label: string;
  value: string;
  className?: any;
  name?: string;
  handleEditPoc?: any;
  component?:string
}
export const TicketInfo: React.FC<TicketInfoProps> = ({
  label,
  value,
  className,
  name,
  handleEditPoc,
  component
}) => {
  const substringsToCheck = ["Call from Phone", "SubscriberMobile", "em","Patient’s Contact No","Ben Contact No"];
  const shouldRenderPhoneNumberCard = substringsToCheck.some(substring => label.includes(substring));
  return (
    <Container className={className}>
      <div className={style.card_key}>
        <Text text={label} className={style.label} />
      </div>

      {shouldRenderPhoneNumberCard&& (
        <div className={`${style.card_value}`}>
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: ".3rem",
            }}
          >
            :
          </span>
          <PhoneNumberCard
            icon={"/images/phone.png"}
            number={`${value}`}
            componentName={label}
          />
        </div>
      )}
      {label === "POC" && (
        <div className={style.card_value}>
          <span className={style.space}>:</span>
          <PhoneNumberCard
            icon={"/images/phone.png"}
            number={value}
            name={name}
            componentName={label}
            handleEditPoc={handleEditPoc}
          />
        </div>
      )}
      {label !== "Call from Phone" &&
        label !== "POC" &&
        label !== "address" &&!shouldRenderPhoneNumberCard&& (
          <div className={`${component?style.assessment_component:style.card_value}`}>
            <span className={style.space}>:</span>
            <Text text={value} className={style.value} />
          </div>
        )}
      {label === "address" && (
        <div className={style.card_value}>
          <span className={style.space}>:</span>
          <Text text={value} className={style.value} />
        </div>
      )}
    </Container>
  );
};
