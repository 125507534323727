import { Container } from "../../components/atoms/container/Container";
import style from "./ticketHandling.module.scss";
import { Text } from "../../components/atoms/text/Text";
import { Button } from "../../components/atoms/button/Button";
import { EmergencyCheck } from "../../components/organisms/emergencyCheck/EmergencyCheck";
import { SelectPatient } from "../../components/organisms/selectPatient/SelectPatient";
import { MedicalCondition } from "../../components/organisms/medicalCondition/MedicalCondition";
import { PatientCondition } from "../../components/organisms/patientCondition/PatientCondition";
import { SelectAssistance } from "../../components/organisms/selectAssistance/SelectAssistance";
import { TicketBasicDetWrapper } from "../../components/organisms/ticketBasicDetWrapper/TicketBasicDetWrapper";
import { useFetchTicket } from "../../hooks/useFetchTicket/UseFetchTicket";
import { SelectPoc } from "../../components/organisms/selectPoc/SelectPoc";
import { ConditionalWrapper } from "../../components/organisms/conditionalWrapper/ConditionalWrapper";
import { EmergencyCheckWrapper } from "../../components/organisms/emergencyCheckWrapper/EmergencyCheckWrapper";
import withNavigation from "../../components/molecules/ticketWrapper/TicketWrapper";
import useTicketNavigation from "../../hooks/useTicketNavigation/useTicketNavigation";
import { useEffect } from "react";
export const TicketHandlingPart = () => {
  //eslint-disable-next-line
  const { isLoading, isError, data, handleSaveAssessment, getTicketUpdateData } = useFetchTicket();

  const handleRedirect = useTicketNavigation();
  useEffect(() => {
    if (data) {
      handleRedirect(data);
    }
  }, [data, handleRedirect]);
  return (
    <div>
      <TicketBasicDetWrapper>
        <Container className={style.wrapper}>
          <Text className={style.heading} text="Assessment" />
          <div className={style.border}></div>
          <Container className={style.container}>
            <Container className={style.assessment_container}>
              <Container className={style.step}>
                <Button text="Completed :1/3" className={style.step_button} />
              </Container>
              <Container className={style.emHandle}>
                <EmergencyCheck ticketData={data} />
              </Container>
              <EmergencyCheckWrapper ticketData={getTicketUpdateData}>
                <Container className={style.emHandle}>
                  <SelectPatient />
                </Container>
                <Container className={style.emHandle}>
                  <SelectPoc />
                </Container>
                <Container className={style.emHandle}>
                  <MedicalCondition />
                </Container>
                <ConditionalWrapper ticketUpdateData={getTicketUpdateData}>
                  <Container className={style.emHandle}>
                    <PatientCondition />
                  </Container>
                  <Container className={style.emHandle}>
                    <SelectAssistance />
                  </Container>
                  <Container className={style.emHandle}>
                    <Button text={getTicketUpdateData?.currentMedicalStatusResponse.isAmbulanceRequired === "No" ? "Start Emergency" : "Save Assessment"} className={style.button} onClick={handleSaveAssessment} />
                  </Container>
                </ConditionalWrapper>
              </EmergencyCheckWrapper>
            </Container>
          </Container>
        </Container>
      </TicketBasicDetWrapper>
    </div>
  );
};

const TicketHandlingPart1 = withNavigation(TicketHandlingPart);
export default TicketHandlingPart1;