import React from "react";
import { Select } from "antd";
import StyledSelect from "../../../styles/styledComponent";

const { Option } = Select;
interface CustomSelectProps {
  placeHolder: string;
  options: any;
  onChange: any;
  width?: any;
  className?: any;
  height?: any;
  placeholderFontSize?: any;
  value:string;

}

const CustomSelect: React.FC<CustomSelectProps> = ({
  width,
  height,
  options,
  className,
  onChange,
  placeHolder,
  placeholderFontSize,
  value,
  ...rest
}) => {
  // console.log('child-3:customSelect',options);
  return (
    <StyledSelect
    //  placeholderFontSize={placeholderFontSize}
      style={{ width: width, height: height }}
      onChange={onChange}
      placeholder={placeHolder}
      {...rest}
      value={value  ? value : undefined}
    >
      {options.map((option: any,index:number) => (
        <Option key={option.value+""+index+1} value={option.value}>
          {option.label}
        </Option>
      ))}
    </StyledSelect>
  );
};

export default CustomSelect;
