import { useEffect, useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { calculateDistanceBetween, sendWhatsappMessage } from "../../pages/actions/actions";
import { ShowAlert } from "../../utils/showAlert";
import { useEmergencyContact } from "../useEmergencyContact/UseEmergencyContact";
import { createWhatsappMessagePayload, currentDateTime } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { ticketUpdateById } from "../../http";
import { useDispatch } from "react-redux";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";
import { PaymentLink } from "../../components/molecules/paymentLink/PaymentLink";
import { useModal } from "../../components/organisms/modalProvider/ModalProvider";
import { setAmbulanceReqInfo } from "../../redux/ambulanceReqSlice/ambulanceReqSlice";

export const useAmbulanceBooking = () => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const navigate = useNavigate();
  
  const [currentStep, setCurrentStep] = useState<number[]>([0, 0, 0, 0]);
  const { getTicketData, getTicketStatus } = UseGetStoreData();
  const { validContacts } = useEmergencyContact();
  const [ambulanceMessageSend, setAmbulanceMessageSend] = useState(false);
  const [ambulanceDistanceBetween, setAmbulanceDistanceBetween] = useState<string>('');

  useEffect(() => {
    // alert("check");
    const fetchDistanceBetween = async () => { 
      // console.log(getTicketData)
      const { patientLocation, hospitalInfo } = getTicketData;
      if (patientLocation?.geoLocation?.coordinates && hospitalInfo?.geoLocation?.coordinates) {
        const [patientLat, patientLon] = patientLocation.geoLocation.coordinates;
        const [hospitalLat, hospitalLon] = hospitalInfo.geoLocation.coordinates;

        if (patientLat && patientLon && hospitalLat && hospitalLon) {
          
          // Calculate distance between patient and hospital using Google Maps API, and dispatch to Redux store.
          const data = await calculateDistanceBetween({ patientLat, patientLon ,hospitalLat,hospitalLon })
          
          setAmbulanceDistanceBetween(data);
          
          dispatch(setAmbulanceReqInfo({
            pickupLocation: patientLocation,
            dropLocation: hospitalInfo,
            distanceBetween: data
          }))
          // console.log("ambulanceBookingReqINFO",{ patientLat, patientLon, hospitalLat, hospitalLon },patientLocation,hospitalInfo)
        }
      }
    }
    fetchDistanceBetween();
  },[getTicketData,dispatch]);

  const getSelectedAssistanceReq = () => {
    return Object.entries(getTicketData?.typeOfAssistanceRequired || {})
      .reduce((acc: any, [key, value]) => {
        if (value === true) {
          acc.push(key);
        }
        return acc;
      }, [])
      .join(", ");
  };
  const handleSendAmbulanceReq = async () => {
    setAmbulanceMessageSend(true);
    const whatsappMessagePayload = {
      template: "NOTIFY_AGENT_ABOUT_AMBULANCE",
      data: {
        to: [getTicketData?.agent?.mobile], //instead here can we take it from cookie???
        beneficiary: getTicketData?.patient?.fullName || getTicketData?.ticketRaisedBy?.fullName || "N/A",
        beneficiaryUId: getTicketData?.patient?.id || getTicketData?.ticketRaisedBy?.benId || "N/A",//from
        poc: getTicketData?.poc?.fullName || "N/A",
        pocNumber: getTicketData?.poc?.mobil || "N/A",
        isBeneficiaryConcious:
          getTicketData?.currentMedicalStatusResponse?.isPatientConscious ||
          "N/A",
        patientCondition:
          getTicketData?.currentMedicalStatusResponse?.currentCondition?.join(
            ","
          ) || "N/A", //fromData
        beneficiaryLocation: getTicketData?.patientLocation?.address,
        latlon:
          getTicketData.patientLocation?.geoLocation?.coordinates?.join(",") ||
          "N/A",
        isReachableByAmbulance:
          getTicketData?.patientLocation?.isReachableByAmbulance,
        isLiftAvailable: getTicketData?.patientLocation?.isLiftAvailable,
        additionalRequiurements: getSelectedAssistanceReq() || "N/A",
        hospital: getTicketData?.hospitalInfo?.address || "N/A",
      },
    };
    sendWhatsAppMessageToEm({
      payload: whatsappMessagePayload,
      notificationMsg: "Ambulance Request Successfully Sent",
    });
    const payload = {
      notes: [...getTicketData?.notes, {
        date: currentDateTime(),
        message: "Pickup and drop location shared to agent"
      }]
    }
    await updateTicket(getTicketData._id, payload, "")
  };
  const sendWhatsAppMessageToEm = async ({ payload, notificationMsg }: any) => {
    try {
      const response = await sendWhatsappMessage(payload);
      if (response) {
        ShowAlert("success", ` ${notificationMsg}`);
      }
    } catch (e: any) {
      alert(
        e?.response?.data?.message ||
        "Some Technical Issue in Sending Whatsapp Msg"
      );
    }
  };
  const handleStepChanges = async (step: any) => {
    console.log('step:##########', currentStep);
    const updatedSteps = [...currentStep];
    updatedSteps[step - 1] = 1;
    setCurrentStep(updatedSteps);
    try {
      switch (step) {
        case 1:
          await handleTicketUpdate("dispatch");
          break;
        case 2:
          await handleTicketUpdate("pickupLocation");
          break;
        case 3:
          await handleTicketUpdate("pickupPatient");
          break;
        case 4:
          await handleTicketUpdate("dropPatient");
          break;
        default:
          throw new Error("Unknown action");
      }
    } catch (e) {
      ShowAlert("warning", "Some Technical Issue in Tracking Ambulance")
    }
  };
  const handleTicketUpdate = async (action: string) => {
    if (getTicketData.ticketStatus === "New") {
      return alert("Please Select Start Emergency");
    }
    let commonData;
    if (action !== "resolve")
      commonData = {
        to: [...validContacts],
        beneficiary: getTicketData?.patient?.fullName || "N/A",
      };

    let waMessagePayload = {};
    let updateTicketPayload = {};

    switch (action) {
      case "dispatch":
        waMessagePayload = createWhatsappMessagePayload({
          templateName: "NOTIFY_EC_ON_AMBULANCE_BOOKING_WITHOUT_TRACKING",
          data: {
            ...commonData,
            trackingLink: `Please click on the link below to track the ambulance:`,
          },
        });
        updateTicketPayload = {
          ambulanceInfo: { status: "ambulanceDispatched" }, notes: [...getTicketData?.notes, {
            date: currentDateTime(),
            message: "ambulanceDispatched"
          }]
        };
        break;

      case "pickupLocation":
        waMessagePayload = createWhatsappMessagePayload({
          templateName: "NOTIFY_EC_ON_AMBULANCE_ARRIVAL",
          data: commonData,
        });
        updateTicketPayload = {
          ambulanceInfo: { status: "ambulanceArrivedPickupLocation" },
          notes: [...getTicketData?.notes, {
            date: currentDateTime(),
            message: "ambulanceArrivedPickupLocation"
          }]
        };
        break;

      case "pickupPatient":
        waMessagePayload = createWhatsappMessagePayload({
          templateName: "NOTIFY_EC_ON_PICKUP",
          data: {
            ...commonData,
            hospital: getTicketData?.hospitalInfo?.address,
          },
        });
        updateTicketPayload = {
          ambulanceInfo: { status: "patientPicked" }
          , notes: [...getTicketData?.notes, {
            date: currentDateTime(),
            message: "patientPicked"
          }]
        };
        break;
      case "dropPatient":
        waMessagePayload = createWhatsappMessagePayload({
          templateName: "NOTIFY_EC_ON_DROPOFF",
          data: {
            ...commonData,
            hospital: getTicketData?.hospitalInfo?.address,
          },
        });
        updateTicketPayload = {
          ambulanceInfo: { status: "patientDropped" },
          notes: [...getTicketData?.notes, {
            date: currentDateTime(),
            message: "patientDropped"
          }]
        };
        break;
      case "resolve":
        updateTicketPayload = {
          ticketStatus: "Completed", notes: [...getTicketData?.notes, {
            date: currentDateTime(),
            message: "Ticket Resolved"
          }]
        };
        await updateTicket(getTicketData._id, updateTicketPayload, "");
        ShowAlert("success", "Emergency Resolved Successfully")
        navigate("/");
        return;
      case "ambulanceSendStatusUpdate":
        updateTicketPayload = {
          notes: [...getTicketData?.notes, {
            date: currentDateTime,
            message: "Ambulance Details Shared"
          }]
        };
        await updateTicket(getTicketData._id, updateTicketPayload, "");
        ShowAlert("success", "Emergency Resolved Successfully")
        navigate("/");
        return;
      default:
        throw new Error("Unknown action");
    }
    // console.log('waMessagePayload:', waMessagePayload);
    await updateTicket(
      getTicketData._id,
      updateTicketPayload,
      waMessagePayload,
    );
  };
  const updateTicket = async (
    id: any,
    ticketPayload: any,
    waMessagePayload: any
  ) => {
    try {
      if (id) {
        const response = await ticketUpdateById(id, ticketPayload);
        if (response) {
          dispatch(ticketDataSuccess(response.data))
          if (waMessagePayload !== "") {
            sendWhatsAppMessageToEm({ payload: waMessagePayload, notificationMsg: "Success" });
          }
        }
      }
    } catch (e: any) {
      return alert(
        e?.response?.data?.message || "Some Technical Issue in Ticket Updating"
      );
    }
  };
  const sendAMbulancePaymentLink = () => {
    showModal(<PaymentLink component="Ambulance" />, "Ambulance")
  }
  return {
    currentStep,
    setCurrentStep,
    handleSendAmbulanceReq,
    ambulanceMessageSend,
    handleTicketUpdate,
    handleStepChanges,
    getTicketStatus,
    sendAMbulancePaymentLink,
    ambulanceDistanceBetween    
  };
};
