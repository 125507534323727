import { skipToken, useQuery } from "@tanstack/react-query";
import { getHospitalById } from "../../pages/actions/actions";
import { getDistanceFromLatLonInKm } from "../../utils/distanceCalculation";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useState } from "react";


export const useGetPreferredHospital = ({preferredHospital}:any) => {
  // console.log('preferredHospital####################:', preferredHospital);
  // const [preSelectHospital,setPreselectHospital]=useState<any[]>([]);
  const [getDistance,setGetDistance]=useState<string>();
  const {getTicketUpdateData}=UseGetStoreData();

  const {patientLocation}=getTicketUpdateData
  // console.log('getTicketUpdateData:', patientLocation);
  console.log('preferredHospital---------:', preferredHospital)
  const shouldFetch=preferredHospital[0]?.id?true:false;
  // console.log('shouldFetch%%%%%%%%%%%%:', shouldFetch);
  const { data:preSelectHospital } = useQuery({
    queryKey: ["ticket", preferredHospital[0]?.id],
    queryFn: shouldFetch?async()=>await getHospitalById(preferredHospital[0]?.id):skipToken
  });

  
  const getPreferredHospitalDist=async()=>{
    if(preSelectHospital?.length){
      console.log('preSelectHospital:', preSelectHospital);
      const res= await getDistanceFromLatLonInKm(patientLocation?.geoLocation?.coordinates
        ?.length
        ? patientLocation?.geoLocation
            ?.coordinates[0]
        : 0,
        patientLocation?.geoLocation?.coordinates
        ?.length
        ? patientLocation?.geoLocation
            ?.coordinates[1]
        : 1,preSelectHospital[0]?.location?.coordinates[1],preSelectHospital[0]?.location?.coordinates[0],5
      )
      setGetDistance(res);
    }
  };
  getPreferredHospitalDist();
  // useEffect(()=>{
  //   (async()=> {

  //     const res=await getHospitalById(preferredHospital[0]?.id);
  //     console.log('res:', res);
  //     setPreselectHospital(res.data)
  //    const distance= getPreferredHospitalDist(res.data)
  //    setGetDistance(distance)
  //   })()
    
  // },[preferredHospital])
  // const getDistance=getPreferredHospitalDist();
  console.log('getDistance:', getDistance);
  return {preSelectHospital:preSelectHospital||[],getDistance};
};
