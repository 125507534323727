import { Modal } from "antd";
import { createContext, ReactNode, useContext, useState } from "react";


interface ModalContextProps{
    showModal:(content:ReactNode,title?:string)=>void;
    hideModal:()=>void;
}
const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
      throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
  };

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<ReactNode>(null);
    const [modalTitle, setModalTitle] = useState<string>('');
  
    const showModal = (content: ReactNode, title: string = '') => {
      console.log(title,"check--------------");
      setModalContent(content);
      setModalTitle(title);
      setIsModalVisible(true);
    };
  
    const hideModal = () => {
      setIsModalVisible(false);
      setModalContent(null);
      setModalTitle('');
    };
  
    return (
      <ModalContext.Provider value={{ showModal, hideModal }}>
        {children}
        <Modal
          title={modalTitle}
          open={isModalVisible}
          onOk={hideModal}
          onCancel={hideModal}
          footer={null}
          maskClosable={false}
          
        >
          {modalContent}
        </Modal>
      </ModalContext.Provider>
    );
  };