import { useEffect, useState } from "react";
import { getDistanceFromLatLonInKm } from "../../utils/distanceCalculation";
export const useGetHospitalDist = ({pickupLocation,address,count}:any) => {
  // const  [count,setCount]=useState(0);
  const [km ,setKm]=useState<any>(null);
  useEffect(()=>{
    if (pickupLocation) {
      const fetchDistance = async () => {
    
        if (pickupLocation) {
          
          const distance = await getDistanceFromLatLonInKm(
            pickupLocation?.patientLocation?.geoLocation?.coordinates?.length
              ? pickupLocation.patientLocation.geoLocation.coordinates[0]
              : 0,
            pickupLocation?.patientLocation?.geoLocation?.coordinates?.length
              ? pickupLocation.patientLocation.geoLocation.coordinates[1]
              : 1,
            address?.location?.coordinates[1],
            address?.location?.coordinates[0], 
            count
                  
          );
          setKm(distance);
        }
      };
  
      fetchDistance();
  
    }
    //eslint-disable-next-line
  },[pickupLocation,address])
  return{
    km
  }
}

