import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  isLoading: Boolean;
  userData: any;
  isError: Boolean;
  isLoggedIn: Boolean;
} = {
  isLoading: false,
  userData: null,
  isError: false,
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      // console.log('action:', action);
      state.isLoading = false;
      state.isError = false;
      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    loginFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isLoggedIn = false;
      state.userData = null;
    },
    logout: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isLoggedIn = false;
      state.userData = null;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } =
  userSlice.actions;

export default userSlice.reducer;
