import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import style from "./selectPatient.module.scss";
import { BenSearchByNumber } from "../../molecules/benSearchByNumber/BenSearchByNumber";
import { BenSearchByCompany } from "../../molecules/benSearchByCompnay/BenSearchByCompany";
import { useBeneficiaryData } from "../../../hooks/useBeneficiaryData/useBeneficiaryData";
import { BeneficiaryGrp } from "../benficiaryGrp/BeneficiaryGrp";

export const SelectPatient = () => {
  const {
    benOptions,
    selectedOption,
    handleOptionChange,
    benFindOptions,
    selectedSearchOption,
    handleSearchOptionChange,
    handleSearchByMobile,
    searchBenRes,
    handleSearchResChange,
    selectedOptionSearchRes,
    searchByMobileRes,
    handleSearchByCompany,
  } = useBeneficiaryData();

  return (
    <>
      <BackgroundWrapper>
        <Container>
          <Container className={style.container}>
            <Text text="Select Patient" className={style.title} />
            <Text
              text={`What is the patient’s name ?`}
              className={style.question}
            />
            <BeneficiaryGrp
            column={3}
            gap={1}
              className={style.flex}
              options={benOptions}
              selectedValue={selectedOption}
              onChange={handleOptionChange}
            />
          </Container>
          <Container className={style.container_2}>
            <Text text="Find Beneficiary" className={style.title} />
            <BeneficiaryGrp
              column={2}
              gap={1}
              className={style.flex}
              options={benFindOptions}
              selectedValue={selectedSearchOption}
              onChange={handleSearchOptionChange}
            />
          </Container>
          <Container className={style.wrapper}>
            {selectedSearchOption &&
              selectedSearchOption === "By Mobile Number" && (
                <BenSearchByNumber
                  handleSearchByMobile={handleSearchByMobile}
                />
              )}
            {selectedSearchOption &&
              selectedSearchOption === "ByCompanyName" && (
                <BenSearchByCompany
                  handleSearchByCompany={handleSearchByCompany}
                />
              )}

            {searchBenRes.length ? (
              <div className={style.container_2}>
                <Text
                  text={`What is the patient’s name ?`}
                  className={style.question}
                />
                <BeneficiaryGrp
                  column={3}
                  gap={1}
                  className={style.flex}
                  options={searchByMobileRes}
                  selectedValue={selectedOptionSearchRes}
                  onChange={handleSearchResChange}
                />
              </div>
            ) : null}
          </Container>
        </Container>
      </BackgroundWrapper>
    </>
  );
};
