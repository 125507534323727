import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import style from "./ambulanceDistance.module.scss";
import { Flex } from "antd";

interface AmbulanceDistanceProps {
    label: string;
    distance: string;
}

export const AmbulanceDistance: React.FC<AmbulanceDistanceProps> = ({
    label,
    distance
}) => {
    return (
        <>
            <Container className={style.horizon_wrapper}>
                <Container className={style.address_card1}>
                    <Text text={label} className={style.title} />
                </Container>
                <Container className={style.address_card2}>
                    <Flex gap={10} align="center"> 
                        <FontAwesomeIcon icon={faRoad} style={{ verticalAlign: 'middle' }} />
                        <Text text={distance} className={style.title} />
                    </Flex>
                </Container>
            </Container>
        </>
    );
};


