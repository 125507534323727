import { CancelTicket } from "../../molecules/cancelTicket/CancelTicket";

interface ConditionalWrapperProps {
  ticketUpdateData: any;
  children: any;
}

export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  ticketUpdateData,
  children,
}) => {
  if (
    ticketUpdateData?.currentMedicalStatusResponse?.isAmbulanceRequired ===
      "No" &&
    ticketUpdateData?.currentMedicalStatusResponse
      ?.isDoctorConsultationRequired === "No"
  ) {
    return (
      <>
        <CancelTicket />
      </>
    );
  }

  return children;
};
