import { useLocation } from "react-router-dom";
import { Container } from "../../atoms/container/Container";
import { SideBar } from "../sidebar/SideBar";
import style from "./layout.module.scss";
import { Button } from "../../atoms/button/Button";
import useFloatingBtn from "../../../hooks/useFloatingButton/UseFloatingBtn";

interface LayoutProps {
  children: any;
}
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { handleOpenAgentNotes } = useFloatingBtn();
  const params = useLocation();
  const isLoginPath = params.pathname === "/login";
  const isTicket = params.pathname === "/";
  return (
    <>
      <Container className={style.wrapper}>
        {!isLoginPath && (
          <div className={style.left_side}>
            <SideBar />
          </div>
        )}
        <div
          className={
            isLoginPath ? style.main_content_width : style.main_content
          }
        >
          {children}
          {(!isTicket &&!isLoginPath )&&(
            <Button
              onClick={handleOpenAgentNotes}
              className={style.floating_button}
              text="Comment"
            />
          )}
        </div>
      </Container>
    </>
  );
};
