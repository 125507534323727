import { useEffect, useRef, useState } from "react";
import { getInternalPocoTeamDetails } from "../../pages/actions/actions";
import { useQuery } from "@tanstack/react-query";

export const UseTicketFilter = (filterDate: any) => {
  const btnRef: any = useRef(null);
 // default height
  // const [pocoTeamData, setPocoTeamData] = useState<any>([]);
  //eslint-disable-next-line
  const [filterCriteria, setFilterCriteria] = useState<{
    dateFrom: string;
    dateTo: string;
    agentName: string;
    ticketStatus: string;
  }>({
    dateFrom: "",
    dateTo: "",
    agentName: "",
    ticketStatus: "",
  });
  const handleSendDate = (data: any) => {
    setFilterCriteria((prev) => ({
      ...prev,
      dateFrom: data.from,
      dateTo: data.to,
    }));
  };

  const handleGetTicketStatus = (data: any) => {
    setFilterCriteria((prev) => ({
      ...prev,
      ticketStatus: data,
    }));
  };

  const handleSelectAgent = (data: any) => {
    console.log('data:', data);
    setFilterCriteria((prev) => ({
      ...prev,
      agentName: data,
    }));
  };

  const handleClearFilter = () => {
    setFilterCriteria({
      dateFrom: "",
      dateTo: "",
      agentName: "",
      ticketStatus: "",
    });
    handleClearDate();
  };

  const handleClearDate = () => {
    if (btnRef?.current) {
      let clear = btnRef?.current.querySelector(".ant-picker-clear");
      if (clear) {
        clear.click();
      }
    }
  };

  const { data } = useQuery({
    queryKey: ["agentData"],
    queryFn:  getInternalPocoTeamDetails,
    select: (data: any) =>
      data?.pocoHeartBeatTeam?.map((agentData: any) => {
        return {
          label: agentData.name,
          value: agentData.email,
        };
      }),
  });
  


  useEffect(()=>{
    filterDate(filterCriteria);
    
    //eslint-disable-next-line
  },[filterCriteria]);

 


  return {
    btnRef,
    handleSendDate,
    filterCriteria,
    handleGetTicketStatus,
    handleSelectAgent,
    handleClearFilter,
    data,
  };
};
