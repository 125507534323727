import style from "./flex.module.scss";

interface FlexProps {
  className?: string;
  children: React.ReactNode;
}

export const Flex: React.FC<FlexProps> = ({ className, children }) => {
  return <div className={`Flex ${style.wrapper} ${className}`}>{children}</div>;
};
