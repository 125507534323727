import { useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { currentDateTime } from "../../utils/helper";
import { updateTicket } from "../../pages/actions/actions";
import { useDispatch } from "react-redux";
import { ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";
import { ShowAlert } from "../../utils/showAlert";
import { ticketDataSuccess } from "../../redux/ticketSlice/ticketSlice";

export const useAgentNotes=()=>{
    const dispatch=useDispatch();
    const [agentNotes, setAgentNotes] = useState("");
    const {getTicketData}=UseGetStoreData();
    const oldAgentNotes=getTicketData?.notes;
  const storeAgentNotes=async()=>{
    if(!agentNotes) return ShowAlert("warning","Please Enter the Notes");
    const payload = {
        notes: [...getTicketData?.notes, {
          date: currentDateTime(),
          message: agentNotes
        }]
      };
    const res=await updateTicket(getTicketData._id,payload);
    if(res){
        dispatch(ticketUpdateStatusSuccess(res.data));
        dispatch(ticketDataSuccess(res.data));
        ShowAlert("success","notes Updated Successfully");
        setAgentNotes("");
    }

  }

  return{
    agentNotes,setAgentNotes,storeAgentNotes,oldAgentNotes
  }
}