import { useState } from "react";
import { useDispatch } from "react-redux";
import { ticketUpdateCurrentMedicalStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";

export const usePatientCondition = () => {
  const dispatch = useDispatch();
  const [otherDes, setOtherDes] = useState("");
  const getPatientCondition = (selectedCondition: any[]) => {
    dispatch(
      ticketUpdateCurrentMedicalStatusSuccess({
        currentCondition: selectedCondition,
      })
    );
  };

  const handleCatchOtherDescription = (value: string) => {
    setOtherDes(value);
    dispatch(
      ticketUpdateCurrentMedicalStatusSuccess({ otherDescription: value })
    );
  };

  return {
    otherDes,
    getPatientCondition,
    handleCatchOtherDescription,
  };
};
