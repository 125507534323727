import style from "./input.module.scss"

interface InputProps {
  placeholder: string;
  value: string;
  searchValue: any;
  className?: any;
}

export const Input: React.FC<InputProps> = ({
  placeholder,
  value,
  className,
  searchValue,
}) => {
  const handleChange = (e: any) => {
    searchValue(e.target.value);
  };
  return (
    <div>
      <input
        placeholder={placeholder}
        onChange={handleChange}
        className={`${style.input} ${className}`}
        value={value}
      />
    </div>
  );
};
