import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  isLoading: Boolean;
  benData: any[];
  isError: Boolean;
} = {
  isLoading: false,
  benData: [],
  isError: false,
};

const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    benDataStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    benDataSuccess: (state, action: PayloadAction<any>) => {
      // console.log('action:', action);
      state.isLoading = false;
      state.isError = false;
      state.benData = action.payload;
    },

    benDataUpdate: (state, action: PayloadAction<any>) => {
      // console.log('action:', action);
      state.isLoading = false;
      state.isError = false;
      state.benData =[...state.benData,...action.payload];
      // console.log("dddf",  state.benData);
    },
    benDataFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.benData = []
    },
    
  },
});

export const { benDataStart, benDataSuccess,benDataUpdate,  benDataFailure } = beneficiarySlice.actions;


export default beneficiarySlice.reducer;
