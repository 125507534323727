import { AgentNotes } from "../../components/molecules/agentNotes/AgentNotes";
import { useModal } from "../../components/organisms/modalProvider/ModalProvider";

const useFloatingBtn = () => {
    const { showModal } = useModal();
    const handleOpenAgentNotes=()=>{
        showModal(<AgentNotes/>,"Agent Notes")
    };
  return{handleOpenAgentNotes}
}

export default useFloatingBtn