import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { DynamicCard } from "../dynamicCard/DynamicCard";
import style from "./callDetails.module.scss";

interface CallDetailsProps {
  data: any;
}

export const CallDetails: React.FC<CallDetailsProps> = ({ data }) => {
  console.log("data:", data);
  const filterObjectByKeys = (obj: any, keys: any) => {
    return keys.reduce((acc: any, key: any) => {
      if (key in obj) {
        acc[key] = obj[key]||"N/A";
      }
      return acc;
    }, {});
  };

  const keysToExtract = ["callerId","callResponse", "duration", "startTime", "endTime"];
  const keysToExtract2 = [
    "timeToAnswer",
    "status",
    "hangupBy",
    "agentId",
    "dialStatus",
  ];

  const filteredData1 = filterObjectByKeys(data, keysToExtract);
  const filteredData2 = filterObjectByKeys(data, keysToExtract2);
//   console.log("filteredData1:", filteredData1);

  return (
    <>
      <BackgroundWrapper>
        <p className={style.heading}>Call Details</p>
        <div className={style.card_align}>
          <DynamicCard
            className={style.fontStyles}
            key={data._id}
            data={filteredData1}
            excludeFields={[]}
          />
          <DynamicCard
            className={style.fontStyles}
            key={data._id}
            data={filteredData2}
            excludeFields={[]}
          />
        </div>
      </BackgroundWrapper>
    </>
  );
};
