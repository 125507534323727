import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import style from "./shareProfileBtn.module.scss"
interface ShareProfileButtonProps {
    onClick?: () => void;
    icon?:string;
    btnText:string;
}
export const ShareProfileButton:React.FC<ShareProfileButtonProps> = ({onClick,icon,btnText}) => {
    return (
        <>
            <Container onClick={onClick} className={style.btn_wrap}>
                <Container className={style.button}>
                    <Icon className={style.icon} alt="whatsapp" src="/images/wa.png" />
                    <Text  text={btnText} />
                </Container>
            </Container>
        </>
    );
}