 import { useState } from "react";
import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import { CallDetails } from "../callDetailsCard/CallDetailsCard";
import style from "./callLog.module.scss";

interface CallLogsCardProps {
  data: any;
  handleCallLogModal: () => void;
  icon: string;
}

export const CallLogsCard: React.FC<CallLogsCardProps> = ({
  data,
  handleCallLogModal,
  icon,
}) => {
  const [expandId, setExpandId] = useState(null);
  const handleClick = (id: any) => {
    setExpandId(id === expandId ? null : id);
    handleCallLogModal();
  };
  return (
    <BackgroundWrapper className={style.background}>
      <Container className={style.callLog_wrapper}>
        <Text className={style.heading} text="Call Logs" />
        {data.map((el: any, index: number) => (
          <>
          <Container  
          key={el._id}
        //   className={style.card_item}
          >

            <Container key={el._id} className={style.flex}>
              <Container className={style.card_left}>
                <Text text="Beneficiary " className={style.title}/>
                <Text className={style.title} text={el.callerId} />
              </Container>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(index)}
              >
                <Icon
                  src={expandId === index ? icon : "/images/chevron-down.png"}
                  className={style.icon}
                  alt="Chevron Icon"
                />
              </div>
            </Container>
           {expandId === index && <div className={style.card_item}> <CallDetails data={el} /></div>}
          </Container>
          </>
        ))}
      </Container>
    </BackgroundWrapper>
  );
};
