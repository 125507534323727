import React from "react";
import { Modal, Input, Select, Button, Typography, List } from "antd";

const { Option } = Select;
const { Text } = Typography;

type AddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  address1: string;
  setAddress1: (value: string) => void;
  address2: string;
  setAddress2: (value: string) => void;
  landmark: string;
  setLandmark: (value: string) => void;
  ambulanceAvailable: boolean;
  setAmbulanceAvailable: (value: boolean) => void;
  liftAvailable: boolean;
  setLiftAvailable: (value: boolean) => void;
  autocompleteSuggestions: any[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSuggestionClick: (suggestion: any) => void;
  saveAddress: () => void;
};

export const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  onClose,
  address1,
  setAddress1,
  address2,
  setAddress2,
  landmark,
  setLandmark,
  ambulanceAvailable,
  setAmbulanceAvailable,
  liftAvailable,
  setLiftAvailable,
  autocompleteSuggestions,
  handleInputChange,
  handleSuggestionClick,
  saveAddress,
}) => {
  return (
    <Modal
      title="Just to confirm, the patient is at <Summarize the address>, we will be dispatching the ambulance to this location."
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={saveAddress}>
          Save Changes
        </Button>,
      ]}
    >
      <Input
        value={address1}
        onChange={handleInputChange}
        placeholder="Enter Location"
        size="large"
        style={{ marginBottom: "10px" }}
      />
      {autocompleteSuggestions.length > 0 && (
        <List
          bordered
          dataSource={autocompleteSuggestions}
          renderItem={(suggestion) => (
            <List.Item
              onClick={() => handleSuggestionClick(suggestion)}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </List.Item>
          )}
          style={{ marginBottom: "10px" }}
        />
      )}
      <Input
        value={address2}
        onChange={(e) => setAddress2(e.target.value)}
        placeholder="Flat No/Door No"
        size="large"
        style={{ marginBottom: "10px" }}
      />
      <Input
        value={landmark}
        onChange={(e) => setLandmark(e.target.value)}
        placeholder="Landmark (Optional)"
        size="large"
        style={{ marginBottom: "10px" }}
      />
      <Text>Is Ambulance Reachable</Text>
      <Select
        value={ambulanceAvailable ? "Yes" : "No"}
        onChange={(value) => setAmbulanceAvailable(value === "Yes")}
        size="large"
        style={{ marginBottom: "10px", width: "100%" }}
      >
        <Option value="Yes">Yes</Option>
        <Option value="No">No</Option>
      </Select>
      <Text>Is Lift Available</Text>
      <Select
        value={liftAvailable ? "Yes" : "No"}
        onChange={(value) => setLiftAvailable(value === "Yes")}
        size="large"
        style={{ marginBottom: "10px", width: "100%" }}
      >
        <Option value="Yes">Yes</Option>
        <Option value="No">No</Option>
      </Select>
    </Modal>
  );
};

