import { useState } from "react";
import { UseGetStoreData } from "../../../hooks/getStoreData/UseGetStoreData";
import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { AddressCard } from "../../molecules/addressCard/AddressCard";
import { ShareProfileButton } from "../../molecules/shareProfileButton/ShareProfileButton";
import style from "./nearByHospital.module.scss";
import { sendWhatsappMessage } from "../../../pages/actions/actions";
import { ShowAlert } from "../../../utils/showAlert";
import { useEmergencyContact } from "../../../hooks/useEmergencyContact/UseEmergencyContact";
interface NearByHospitalProps {
  handleAddressSelection: any;
  selectedHospital: any;
  nearByHospital: any[];
  pickupLocation: any;
  component?: string;
  km:number
}
export const NearByHospital: React.FC<NearByHospitalProps> = ({
  handleAddressSelection,
  selectedHospital,
  nearByHospital,
  component,
  pickupLocation,
  km
}) => {
  const {validContacts}=useEmergencyContact();
  const [isBtnLoading,setIsBtnLoading]=useState(false);
  const {getTicketUpdateData}=UseGetStoreData();
  const formattedHospitals = nearByHospital.slice(0, 3).map(el => `{"name":"${el.name}","address":"${el.address}"}`).join(',')
  // console.log('formattedHospitals:', formattedHospitals);
  // console.log('nearByHospital:', nearByHospital);
  const handleClick = async() => {
    if(!getTicketUpdateData?.patientLocation?.address||!formattedHospitals){
      ShowAlert("warning","patient address Not found or hospital not found")
    }
    setIsBtnLoading(true);

    const payload={
      template:"NEAREST_HOSPITAL_DETAILS",
      data:{
        currentAddress:getTicketUpdateData?.patientLocation?.address,
        nearbyHospital:`${formattedHospitals}`,
        to:[...validContacts]
      }
    };
    try{
         await sendWhatsappMessage(payload);
         setIsBtnLoading(false);
         ShowAlert("success","Hospital data shared")
    }catch(e:any){
      ShowAlert("warning",e?.response?.data?.message||"Some technical issue in sending Wa message")
      setIsBtnLoading(false);
    }
  };
  return (
    <>
      <BackgroundWrapper className={style.background}>
        <Container>
          <Container className={component?style.heading_flex:""} >
            <Text className={style.title} text="Select Drop Location" />
            <Text className={`${style.question} ${style.space}`} text={`We can see ${nearByHospital?.length} hospitals at ${km} km`} />
            {component && (
              <Container>
                <ShareProfileButton
                  onClick={handleClick}
                  btnText={isBtnLoading?"Loading":"Send Copy"}
                />
              </Container>
            )}
          </Container>
          {nearByHospital.length ? (
            <Container className={component?`${style.addressCard} ${style.general_enq}`:style.addressCard}>
              {nearByHospital?.length &&
                nearByHospital.map((address: any, index: number) => {
                  return (
                    <div style={{height:"100%", flexGrow:1,display:"flex"}}>
                      <AddressCard
                        count={index+1}
                        leftSideIcon="/images/hospital.png"
                        rightSideBadgeText="Partnered"
                        address={address}
                        handleAddressSelection={handleAddressSelection}
                        selectedAddress={selectedHospital}
                        pickupLocation={pickupLocation}
                      />
                    </div>
                  );
                })}
            </Container>
          ) : (
            <>loading</>
          )}
        </Container>
      </BackgroundWrapper>
    </>
  );
};
