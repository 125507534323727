import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { EmCheckOptions, EmMessages } from "../../utils/helper";
import { useDispatch } from "react-redux";
import {  ticketUpdateCurrentMedicalStatusSuccess, ticketUpdateDefaultValue } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";


export const UseEmergencyCheck = () => {
  //if suppose someone who update only first step//
  const{id}=useParams();
  
  const { getUserData, getTicketUpdateData } = UseGetStoreData();
  const dispatch=useDispatch(); 
  const navigate=useNavigate()
  const [selectedOption, setSelectedOption] = useState("yes");
  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
    let payload={
      isItEmergency:event.target.value
    }
    dispatch(ticketUpdateCurrentMedicalStatusSuccess(payload));  
   
  };
  const agentName =getUserData?.name||"";
  useEffect(()=>{
    if(id&&getTicketUpdateData?.ticketId!==id){
      dispatch(ticketUpdateDefaultValue(id))
    }
 //eslint-disable-next-line
  },[getTicketUpdateData,navigate,id])

  return {
    agentName,
    handleOptionChange,
    messages:EmMessages,
    options:EmCheckOptions,
    selectedOption,
  };
};
