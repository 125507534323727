import { Container } from "../../atoms/container/Container";
import { RadioButtonGroup } from "../../atoms/radioButtonGroup/RadioButtonGroup";
interface BeneficiaryProps {
  options: any;
  selectedValue: any;
  onChange: any;
  className: any;
  componentName?:any;
  column:number;
  gap:number;
}
export const BeneficiaryGrp: React.FC<BeneficiaryProps> = ({
  options,
  selectedValue,
  onChange,
  className,
  column,
  gap
}) => {
  return (
    <>
      <Container className={className}>
        <RadioButtonGroup
           column={column}
           gap={gap}          
          type="radio"
          options={options}
          name={"radio"}
          selectedValue={selectedValue}
          onChange={onChange}
        />
      </Container>
    </>
  );
};
