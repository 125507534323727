import React from "react";
import {  TicketStatus } from "../../../utils/helper";
import { Button } from "../../atoms/button/Button";
import { Container } from "../../atoms/container/Container";
import { DatePickerComponent } from "../../atoms/datePicker/DatePickerComponent";
import CustomSelect from "../../atoms/select/CustomSelect";
import style from "./ticketFilter.module.scss";
import { UseTicketFilter } from "../../../hooks/useTicketFilter/UseTicketFilter";
interface TicketFilterProps {
  filterDate?: any;
}
 const TicketFilter: React.FC<TicketFilterProps> = ({ filterDate }) => {
  const {btnRef,handleSendDate,filterCriteria,handleGetTicketStatus,handleSelectAgent,handleClearFilter,data}=UseTicketFilter(filterDate);
  console.log("child-1",);
  
  return (
    <div>
      <div className={style.wrapper}>
        <Container className={style.container}>
          <div ref={btnRef}>
            <DatePickerComponent
              placeholderFontSize="1rem"
              height={"3.563rem"}
              handleGetDate={handleSendDate}
            />
          </div>
        </Container>
        <Container className={style.container}>
          <CustomSelect
            value={filterCriteria.ticketStatus}
            width={"100%"}
            height={"3.563rem"}
            placeholderFontSize="1rem"
            placeHolder="Select Ticket Status"
            options={TicketStatus}
            onChange={handleGetTicketStatus}
          />
        </Container>
        <Container className={style.container}>
          <CustomSelect
            value={filterCriteria.agentName}
            width={"100%"}
            height={"3.563rem"}
            placeholderFontSize="1rem"
            placeHolder="Select Agent"
            options={data||[]}
            onChange={handleSelectAgent}
          />
        </Container>
        <Container className={style.container}>
          <Button
            text="Clear Filter"
            onClick={handleClearFilter}
            className={style.button}
          />
        </Container>
      </div>
     
      
    </div>
  );
};
export default React.memo(TicketFilter) ;