import { Flex } from "antd";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import { PhoneNumberCard } from "../phoneNumberCard/PhoneNumberCard";
import style from "./doctorCard.module.scss";

interface DoctorCardProps {
  doctorName: string;
  specialty: string;
  number: string;
  startTime: string;
  endTime: string;
}
export const DoctorCard: React.FC<DoctorCardProps> = ({ doctorName, specialty, number, startTime, endTime }) => {
  return (
    <>
      <Container className={style.card_wrapper}>
        <Container className={style.flex}>
          <Container>
            <Icon alt="check" src="/images/doctorPic.png" />
          </Container>
          <Container className={style.doctor_det}>
            <Text text={doctorName} className={style.name} />
            <Flex className={style.card_divider}>
              <Text text={specialty} className={style.specialty} />
              <Flex className={style.timing_divider}>
                <Text text={"Timing"} className={style.timing} />
                <Text text={`${startTime} - ${endTime}`} className={style.timing} />
              </Flex>
            </Flex>
            <PhoneNumberCard componentName="doctorCard" number={number} />
          </Container>
          
        </Container>
        
      </Container>
      
    </>
  );
};
