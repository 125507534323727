import { getHospitalRoadDistanceAndTime } from "../pages/actions/actions";

function deg2rad(deg:any) {
    return deg * (Math.PI / 180);
  }
  let payload:any={
    targetLat:null,
    targetLng:null,
    address:[]
  }
  export async function getDistanceFromLatLonInKm(lat1:number, lon1:number, lat2:number, lon2:number,count:number) {
    console.log('lat1:', lat1,lon1,lat2,lon2)
    if(count>3){ 
    //  console.log("c",count);  
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
 console.log('   d.toFixed(1):',    d.toFixed(1))
    return d.toFixed(1);
    }else{
      // console.log('count:', count);
      payload={
        targetLat:lat1,
        targetLng:lon1,
        address:[
           {coordinates:[lon2,lat2]}
        ]
       
      }
      const res=await getHospitalRoadDistanceAndTime(payload);
        const getDistanceAndTime=res.data.map((el:any)=>`${el.distance.text}-${el.duration_in_traffic.text}`)
       return getDistanceAndTime.join(",")
     
    }
    
  }
  