import { BackgroundWrapper } from "../../atoms/backgroundWrapper/BackgroundWrapper";
import { Container } from "../../atoms/container/Container";
import { RadioButtonGroup } from "../../atoms/radioButtonGroup/RadioButtonGroup";
import { Text } from "../../atoms/text/Text";
import style from "./emergency.module.scss";
import { ResponseCheck } from "../responseCard-Em/ResponseCard";
import { UseEmergencyCheck } from "../../../hooks/useEmergencyCheck/UseEmergencyCheck";
interface EmergencyCheckProps{
  ticketData:any
}
export const EmergencyCheck:React.FC<EmergencyCheckProps> = ({ticketData}) => {
const {agentName,options,selectedOption,handleOptionChange,messages}=UseEmergencyCheck()
  return (
    <div>
      <BackgroundWrapper>
        <Container className={style.container}>
          <Text text="Emergency Check" className={`${style.title}`} />
          <Text
            text={`Hi,this is ${agentName} from Pococare. Is there a medical emergency?`}
            className={style.question}
          />
        </Container>
        <Container className={`${style.flex}`}>
          <RadioButtonGroup
          column={4}
          gap={1}
            type="radio"
            options={options}
            name={"radio"}
            selectedValue={selectedOption}
            onChange={handleOptionChange}
          />
        </Container>
      </BackgroundWrapper>
      <Container className={style.question_space}>
        <BackgroundWrapper className={style.background}>
          <ResponseCheck
            message={messages[selectedOption]}
            icon="/images/heartBeat.png"
          />
        </BackgroundWrapper>
      </Container>
    </div>
  );
};
