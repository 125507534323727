import React from "react";
import { Pagination, Select, Row, Col } from "antd";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const { Option } = Select;
const styles = {
  select: {
    width: '100%',
    fontSize: '1rem', // default font size
  },
  pagination: {
    textAlign: 'right',
    fontSize: '1rem', // default font size
  },
  
  '@media (max-width: 768px)': {
    select: {
      fontSize: '0.575rem', // smaller font size for smaller screens
    },
    pagination: {
      fontSize: '0.575rem', // smaller font size for smaller screens
    },
  },
  '@media (min-width: 1200px)': {
    select: {
      fontSize: '1.125rem', // larger font size for larger screens
    },
    pagination: {
      fontSize: '1.125rem', // larger font size for larger screens
    },
  },
};
interface PaginationControlsProps {
  pageNumber: number;
  pageSize: number;
  totalDataCount: number;
  handlePageSizeChange: (value: number) => void;
  handleDataChange: (page: number) => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  pageNumber,
  pageSize,
  totalDataCount,
  handlePageSizeChange,
  handleDataChange,
}) => {
  console.log("child-2",);
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ marginTop: "16px" }}
      gutter={[16, 16]}
    >
      <Col>
        <Select
          value={pageSize}
          onChange={(value) => handlePageSizeChange(value)}
          // style={{ width: 120 }}
          style={styles.select}
        >
          <Option value={10}>10</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
        </Select>
      </Col>
      <Col>
        <Pagination
          current={pageNumber}
          pageSize={pageSize}
          total={totalDataCount}
          onChange={handleDataChange}
          showSizeChanger={false}
          itemRender={(page, type) => {
            if (type === "prev") {
              return <GrFormPrevious />;
            }
            if (type === "next") {
              return <GrFormNext />;
            }
            return page;
          }}
        />
      </Col>
    </Row>
  );
};

export default PaginationControls;
