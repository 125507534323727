import styled from 'styled-components';
import { Select } from 'antd';

interface StyledSelectProps {
  placeholderFontSize?: string;
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  .ant-select-selection-placeholder {
    font-size: ${(props) => props.placeholderFontSize || '14px'};
  }
  
  @media (max-width: 768px) {
    .ant-select-selection-placeholder {
      font-size: 14px; // Placeholder font size for mobile
    }

    .ant-select-dropdown-menu-item {
      font-size: 14px; // Dropdown item font size for mobile
    }
  
    .ant-select-selector {
      height: 45px !important; // Height of the select component for mobile
      width: 100% !important; // Width of the select component for mobile

    }
  }
     @media (max-width: 550px) {
   .ant-select-arrow{
   margin-top:-8px !important
   }
    .ant-select-selection-placeholder {
      font-size: 10px; // Placeholder font size for mobile
    }

    .ant-select-dropdown-menu-item {
      font-size: 12px !important; // Dropdown item font size for mobile
    }
 
    .ant-select-selector {
      font-size:12px;
      height: 35px !important; // Height of the select component for mobile
      width: 100% !important; // Width of the select component for mobile
    }
  }
`;

export default StyledSelect;
