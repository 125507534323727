import { Container } from "../../components/atoms/container/Container";
import { Text } from "../../components/atoms/text/Text";
import { Flex } from "../../components/molecules/flex/Flex";
import { TicketBasicDetWrapper } from "../../components/organisms/ticketBasicDetWrapper/TicketBasicDetWrapper";
import style from "./logistics.module.scss";
import { AssessmentResult } from "../../components/organisms/assessmentResult/AssessmentResult";
import { useLogisticsData } from "../../hooks/uselogisitcData/UseLogisticsData";
import { LogisticsAddressTab } from "../../components/organisms/logisticsAddressTab/LogisticsAddressTab";
import { SwitchTabs } from "../../components/organisms/switchTabs/SwitchTabs";


export const Logistics = () => {
  const {
    isAddressSelected,
    selectedAddress,
    setSelectedAddress,
    handleAddressSelection,
    handleEditSelectedAddress,
    isHospitalSelected,
    selectedHospital,
    handleEditHospital,
    renderHospitalUi,
    value,
    handleIncreaseRadius,
    marks,
    patientAddress,
    hospitalLists,
    handleSelectHospital,
    handleStartEmergency,
    showOperationTab,
    handleTabSwitch,

  } = useLogisticsData();
 
  // console.log('showOperationTab:', showOperationTab,  showOperationTab === "logistics");
  return (
    <>
      <TicketBasicDetWrapper>
        {/* ///assessmentRes */}
        <Container>
          <AssessmentResult />
        </Container>
        {/* ///Logistics// */}
        <Container className={style.logistics_wrapper}>
          <Flex className={style.flex}>
            <div className={style.operation_wrapper} onClick={() => handleTabSwitch("logistics")}>
              <Text
                className={
                  showOperationTab === "logistics"
                    ? style.heading
                    : style.heading_operation
                }
                text="Logistics"
              />
            </div>
            <div
              onClick={() => handleTabSwitch("operation")}
              className={style.operation_wrapper}
            >
              <Text
                className={
                  showOperationTab === "operation"
                    ? style.heading
                    : style.heading_operation
                }
                text="Operations"
              />
            </div>
          </Flex>
          <div className={style.border}></div>
        </Container>
        <SwitchTabs currentTab={showOperationTab}>
          <LogisticsAddressTab
            isAddressSelected={isAddressSelected}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            handleAddressSelection={handleAddressSelection}
            handleEditSelectedAddress={handleEditSelectedAddress}
            isHospitalSelected={isHospitalSelected}
            selectedHospital={selectedHospital}
            handleEditHospital={handleEditHospital}
            renderHospitalUi={renderHospitalUi}
            value={value}
            handleIncreaseRadius={handleIncreaseRadius}
            marks={marks}
            patientAddress={patientAddress}
            hospitalLists={hospitalLists}
            handleSelectHospital={handleSelectHospital}
            handleStartEmergency={handleStartEmergency}
          />
        </SwitchTabs>
      </TicketBasicDetWrapper>
    </>
  );
};
