import { Container } from "../../atoms/container/Container";
import { Input } from "../../atoms/input/Input";
import style from "./benSearchByNumber.module.scss";
import { Button } from "../../atoms/button/Button";
import { useBenSearchNumber } from "../../../hooks/useBenSearchNumber/UseBenSearchNumber";
interface BenSearchByNumberProps{
  handleSearchByMobile?:any
}
export const BenSearchByNumber:React.FC<BenSearchByNumberProps> = ({handleSearchByMobile}:any) => {
const {mobileNo,handleCatchValue,handleClick}=useBenSearchNumber({handleSearchByMobile})
  return (
    <>
      <Container className={style.wrapper}>
        <Container className={style.input_wrapper}>
          <Input
            placeholder="Enter Mobile No" 
            value={mobileNo}
            searchValue={handleCatchValue}
            className={style.input_style}
          />
        </Container>
        <Container>
          <Button
            text={"Search"}
            className={style.button}
            onClick={handleClick}
          />
        </Container>
      </Container>
    </>
  );
};
