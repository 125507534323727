import { cancelledTicketText } from "../../../utils/helper";
import CustomAlert from "../../atoms/alert/CustomAlert";
import { Button } from "../../atoms/button/Button";
import style from "./canlceltickets.module.scss";
import { useCancelTicket } from "../../../hooks/useCancelTicket/UseCalcelTicket";

export const CancelTicket = () => {
  const {handleCancelTicket}=useCancelTicket();
  return (
    <>
      <div className={style.verticalDivide}>
        <CustomAlert
          className={style.font_Style}
          message={cancelledTicketText}
        />
        <Button
          text="Cancel Ticket"
          className={style.cancelButton}
          onClick={handleCancelTicket}
        />
      </div>
    </>
  );
};
