import { useDispatch } from "react-redux";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useEffect, useState } from "react";
import { ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";

export const useSelectAssistance=()=>{
    const disPatch = useDispatch();
    const {getTicketUpdateData}=UseGetStoreData();
    const initialSelectedValues = {
        wheelChair: false,
        stretcher: false,
        paramedic: getTicketUpdateData?.currentMedicalStatusResponse?.isPatientConscious?.toLowerCase() === "no" ? true : false || false,
        additionalHelp: false,
      };
    const [selectedValues, setSelectedValues] = useState<{
      [key: string]: boolean;
    }>(initialSelectedValues);
    const handleOptionChange = (event: any) => {
      let value = event.target.value;
      const isSelected = !selectedValues[value];
  
      setSelectedValues((prevState) => ({
        ...prevState,
        [value]: isSelected,
      }));
      const payload={
        typeOfAssistanceRequired:{...selectedValues,[value]:isSelected}
      }
      disPatch(ticketUpdateStatusSuccess(payload));
      
    };
    useEffect(()=>{
        setSelectedValues((prevState)=>({...prevState,paramedic: getTicketUpdateData?.currentMedicalStatusResponse?.isPatientConscious?.toLowerCase() === "no" ? true : false || false,}))
    },[getTicketUpdateData?.currentMedicalStatusResponse])

    return{
        selectedValues,
        handleOptionChange
      }
}