import { useDispatch } from "react-redux";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { useEffect, useState } from "react";
import { ticketUpdateStatusSuccess } from "../../redux/ticketUpdateSlice/ticketUpdateSlice";

export const useSelectPoc=()=>{
    const dispatch = useDispatch();
    //eslint-disable-next-line
    const { getTicketUpdateData, getBenList } = UseGetStoreData();
    const [emContacts, setEmContacts] = useState<any[]>([]);
    const [selectedPoc, setSelectedPoc] = useState(null);
    const [openPocForm,setOpenPocForm]=useState(false);

    const handlePocForm=()=>{
      setSelectedPoc(null);
      setOpenPocForm(!openPocForm);
    }


  const handleSelectPoc = (event: any) => {
    const pocDet = event.target.value;
    setSelectedPoc(pocDet);
    const getPocDetails = pocDet.split(",");
    // const { name, index, mobile } = getPocDetails;
    createPocPayload(getPocDetails[0], getPocDetails[2]);
    openPocForm&&setOpenPocForm(!openPocForm);
  };
  const createPocPayload = (fullName: string, mobile: string) => {
    const payload = {
      poc: { fullName, mobile },
    };
    dispatch(ticketUpdateStatusSuccess(payload));
  };

  useEffect(() => {
    if (getTicketUpdateData?.patient?.emergencyContacts) {
      const emContactData = getTicketUpdateData?.patient?.emergencyContacts
        ?.map((ben: any, index: number) => ({
          value: ben?.fullName + "," + index + "," + ben?.mobile,
          label: ben?.fullName,
        }))
        .filter((el: any) => el.value !== undefined || el.label !== undefined);
      setEmContacts(emContactData);
    }
  }, [getTicketUpdateData]);
  return{
    emContacts,
    selectedPoc,
    handleSelectPoc,
    openPocForm,
  
    handlePocForm
  }
}