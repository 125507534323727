import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import style from "./address.module.scss";
interface AddressProps {
  line1: string;
  line2?: string;
  line3?: string;
  line4?: string;
  selectedAddress?: any;
  className?: any;
  component?: string;
  leftSideIcon: string;
  rightSideBadgeText: string;
  onChange?: any;
}
export const Address: React.FC<AddressProps> = ({
  line1,
  line2,
  line3,
  line4,
  selectedAddress,
  className,
  component,
  leftSideIcon,
  rightSideBadgeText,
  onChange
}) => {
  console.log('line4:', line4);
  return (
    <>
      <div onClick={onChange} className={className}>
        <Container className={style.line1_wrapper}>
          {leftSideIcon&&<Icon className={style.icon} alt="left icon" src={leftSideIcon} />}
          <Text className={style.address_label} text={line1 || "No Label"} />
          {rightSideBadgeText&&rightSideBadgeText!=="Partnered" && (
            <Container className={style.circle_background}>
              <Text
                text={rightSideBadgeText}
                className={style.preferred_text}
              />
            </Container>
          )}
        </Container>
        {line2 && (
          <div className={`${style.text_prop}`}>
            <Text className={style.word_break} text={line2} />
          </div>
        )}
        {line3 && (
          <div  className={`${style.text_prop},${style.word_break}`} >
            <Text className={style.word_break} text={line3} />
          </div>
        )}
        {line4 && <div className={line4.includes("kilometers")?`${style.text_prop},${style.word_break} ${style.bold}`:`${style.text_prop},${style.word_break}`}><Text className={style.word_break} text={line4} /></div>}
      </div>
    </>
  );
};
