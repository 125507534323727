import React from "react";
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Icon } from "../../icon/Icon";
import { AssistanceCard } from "../../molecules/assistanceCard/AssistanceCard";
import { Flex } from "../../molecules/flex/Flex";
import { HorizontalList } from "../../molecules/horizontalList/HorizontalList";
import { TicketBasicDetails } from "../ticketBasicDetails/TicketBasicDetails";
import style from "./assessmentResult.module.scss";
import { useAssessmentRes } from "../../../hooks/useAssessmentRes.tsx/UseAssessmentRes";

export const AssessmentResult = () => {
  const { handleAssessmentResult, isOpenAssessmentResult, transformedData } =
    useAssessmentRes();
  if (!transformedData) return null;
  const {
    ticketData,
    ticketData2,
    medicalData,
    currentCondition,
    selectedAssistance,
    others,
  } = transformedData;
  console.log('medicalData:', medicalData,selectedAssistance);
  return (
    <>
      <Container className={style.wrapper}>
        <Flex className={style.flex}>
          <Container>
            <Text className={style.heading} text="Assessment Result" />
          </Container>
          <Container
            onClick={handleAssessmentResult}
            className={style.icon_wrapper}
          >
            <Icon
              src={
                isOpenAssessmentResult
                  ? "/images/chevron-up.png"
                  : "/images/chevron-down.png"
              }
              alt="chevron_icon"
            />
          </Container>
        </Flex>

        <div className={style.border}></div>
      </Container>
      {isOpenAssessmentResult && (
        <Container className={style.container}>
          <Container className={style.assessment_container}>
            <TicketBasicDetails
              title={"Emergency Check"}
              leftSideListData={ticketData}
              rightSideListData={ticketData2}
              component="assessmentRes"
            />
            <div className={style.border_inner}></div>
            <Container className={style.medical_wrapper}>
              <Text className={style.title} text={"Medical Condition"} />
              <Flex className={style.medical_list}>
                {Object.entries(medicalData).map(([question, ans]: any) => {
                  return (
                    <React.Fragment key={question}>
                      <HorizontalList
                        question={question}
                        ans={ans}
                      />
                    </React.Fragment>
                  );
                })}
              </Flex>
            </Container>
            <div className={style.border_inner}></div>
            <Container className={style.patient_condition}>
              <Container>
                <Container>
                  <Text
                    className={style.title}
                    text={"Current Condition of the Patient"}
                  />
                </Container>
                <Container>
                  <Text
                    className={style.ans}
                    text={ currentCondition?currentCondition?.join(","):""}
                  />
                </Container>
              </Container>
              <Container>
                <Container>
                  <Text className={style.title} text={"Others"} />
                </Container>
                <Container>
                  <Text className={style.ans} text={others} />
                </Container>
              </Container>
            </Container>
            <div className={style.border_inner}></div>
            <Container className={style.selected_assistance}>
              <Container>
                <Container>
                  <Text className={style.title} text={"Selected Assistance"} />
                </Container>
                <div className={style.assistance_res}>
                  {selectedAssistance.map((el, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <AssistanceCard className={style.ans} iconPath={el} label={el} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </Container>
            </Container>
            <div className={style.border_inner}></div>
          </Container>
        </Container>
      )}
    </>
  );
};
