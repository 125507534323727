import { Table } from "antd";
import styled from "styled-components";
import withNavigation from "../molecules/ticketWrapper/TicketWrapper";
interface CustomTableProps {
  onClick?: (data: any) => void;
  columnData: any;
  rowData: any;
  totalRows?: number;
}

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    font-size: 1.25rem; /* Header font size */
    // background-color: #f0f0f0; /* Header background color */
    font-family: 'Poppins', sans-serif; 
    font-weight:500 !important;
    line-height: 24px; /* 109.091% */
     height: 4.5rem;
    letter-spacing: 1.32px;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
  }
  .ant-table-tbody > tr > td {
      font-size: 1.125rem; /* Header font size */
    // background-color: #f0f0f0; /* Header background color */
    font-family: 'Poppins', sans-serif; 
    font-weight:400 !important;
    line-height: 24px; /* 109.091% */
    letter-spacing: 1.32px;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    height: 4.5rem;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    cursor: pointer;
    
  }

   @media(max-width:768px){
  .ant-table-tbody > tr > td {
   font-size: 14px; /* Row font size */
    color: #333; /
  }
    .ant-table-thead > tr > th {
    //  width:100%;
     font-size: 18px; /* Header font size */
   
  }
  .ant-table-cell{  
    padding:5px !important;  
  }
  }
  
  @media(max-width:768px){
  .ant-table-tbody > tr > td {
   font-size: 1rem; /* Row font size */
    color: #333; /
    height: 3.5rem;
  }
    .ant-table-thead > tr > th {
    //  width:100%;
     font-size: 1rem; /* Header font size */
    height: 3.5rem;
  }
  .ant-table-cell{  
    padding:5px !important;  
  }
  }

  @media(max-width:550px){
  .ant-table-tbody > tr > td {
   font-size: 10px; 
    color: #333;
    height: 1.5rem;
    width:auto;
  }
    .ant-table-thead > tr > th {
    //  width:100%;
    font-size: 12px; /* Header font size */
    height: 1.5rem;
   
  }
  .ant-table-cell{  
    padding:5px !important;  
  }
  }
    
`;
const CustomTable: React.FC<CustomTableProps> = ({
  onClick,
  columnData,
  rowData,
  totalRows,
}) => {
  const handleRowClick = (record: any) => {
    // console.log('record:', record);
    if (onClick) {
      onClick(record);
    }
  };

  const rowProps = (record: any) => {
    return {
      onClick: () => handleRowClick(record),
    };
  };

  return (
    <div>
      <StyledTable
        onRow={rowProps}
        dataSource={rowData}
        columns={columnData}
        pagination={false}
        rowKey="_id"
      />
    </div>
  );
};
const CustomTableWithNavigation = withNavigation(CustomTable);
export default CustomTableWithNavigation;
