import { useState } from "react";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";
import { getSingleBenData } from "../../http";
import { useQuery } from "@tanstack/react-query";
import { getCallLogsByTicketId } from "../../pages/actions/actions";

export const useOperation = () => {
  const { getTicketData, getTicketUpdateData } = UseGetStoreData();
  const [callModalIsOpen, setCallModalIsOpen] = useState(false);

  const handleCallLogModal = () => {
    setCallModalIsOpen(!callModalIsOpen);
  };

  const getSelectedBenData = async ({ benId }: { benId: string }) => {
    try {
      const res = await getSingleBenData(benId);
      if (res) {
        return res.data.data;
      } else {
        return [];
      }
    } catch (e) {
      throw e;
    }
  };
  const benId = getTicketData?.patient?.id || getTicketUpdateData?.patient?.id;
   //eslint-disable-next-line
  const { data, isLoading: benDataLoading } = useQuery({
    queryKey: ["benData", benId],
    queryFn: () => getSelectedBenData({ benId }),
    select: (data: any) => data,
    enabled: !!benId,
  });
  const ticketId = getTicketData._id;
   //eslint-disable-next-line
  const { data: callDetails, isLoading: callDataLoading } = useQuery({
    queryKey: ["callData", ticketId],
    queryFn: () => getCallLogsByTicketId(ticketId),
    select: (data: any) => data,
    enabled: !!ticketId,
  });

  return{
    callDetails,
    data,
    handleCallLogModal,
  }
};
