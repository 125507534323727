import { CustomModal } from "../../molecules/cutomModal/CustomModal";
import POCForm from "../getPocData/POCForm";
import { usePocForm } from "../../../hooks/usePocForm/UsePocForm";
import { Button } from "../../atoms/button/Button";
interface POCEditFormInterface {
  handleEditPoc: any;
}
export const POCEditForm: React.FC<POCEditFormInterface> = ({
  handleEditPoc,
}: any) => {
  const {
    onFinish,
    onFinishFailed,
    validateMessages,
    pocName,
    pocNumber,
    handlePocInputChange,
    handUpdateTicket,
    handleModalClose,
    isModalOpen,
  } = usePocForm(handleEditPoc ? handleEditPoc : "");

  return (
    <>
      <CustomModal
        modalTitle={"Update POC Details"}
        isModalOpen={isModalOpen}
        isCloseModal={handleModalClose}
      >
        <POCForm
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          pocName={pocName}
          pocNumber={pocNumber}
          handlePocInputChange={handlePocInputChange}
        />
        <Button text="Update Poc details"  onClick={handUpdateTicket} />
      </CustomModal>
    </>
  );
};
